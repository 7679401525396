import ReactQuill from 'react-quill';
import styled from 'styled-components';

import { RichTextEditorProps } from './index';

export const RichTextEditorStyled = styled(ReactQuill)<RichTextEditorProps>`
  .ql-snow .ql-editor img {
    max-width: 300px;
  }

  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border-color: ${props => (props?.isErrorVisible ? '#ff4d4f' : '#ccc')};
  }
  .ql-toolbar.ql-snow {
    border-radius: 8px 8px 0 0;
  }

  .ql-container.ql-snow {
    border-radius: 0 0 8px 8px;
  }
`;

export const RichTextEditorWrapper = styled.div`
  .error {
    position: absolute;
    top: calc(100% + 32px);
    color: #ff4d4f;
  }
  .label {
    margin-bottom: 8px;
  }
`;
