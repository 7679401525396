import { Checkbox, Row } from 'antd';
import styled from 'styled-components';

export const FormContainer = styled(Row)`
  border: 2px solid #d9d9d9;
  border-radius: 11px;
  padding: 0.5em;
  width: 100%;
`;

export const RadioCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    &::after {
      border-radius: 50%;
      width: 7px;
      height: 7px;
      background-color: #fff;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
