import { message } from 'antd';
import { BASE_URL } from 'api';

import { parseInputValue } from './NumericInput';

export const handleCopy = (
  src: string,
  event?: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  event?.preventDefault();
  navigator.clipboard.writeText(src);
  message.success(`${src} Copied to clipboard`);
};

export const getFilename = (filePath: string) => {
  return filePath.substring(filePath.lastIndexOf('/') + 1);
};

export const truncateString = (
  inputString: string,
  maxLength: number
): string => {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength) + '...';
  }

  return inputString;
};

export const _debounce = (delay = 400) => {
  let timer: NodeJS.Timeout | null = null;

  return (cb: () => void) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(cb, delay);
  };
};

export const getFileUrl = (path: string) => {
  if (path) {
    return `${String(BASE_URL).slice(0, -7)}/public/${path}`;
  } else return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RemoveDuplicates = (data: any[]) => {
  const uniqueEntries = new Set();

  const filteredData = data.filter(entry => {
    if (!uniqueEntries.has(entry.id)) {
      uniqueEntries.add(entry.id);

      return true;
    }

    return false;
  });

  return filteredData;
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'INACTIVE':
      return 'red';
    case 'ACTIVE':
      return 'green';
    case 'ARCHIVED':
      return 'blue';
    case 'SUSPENDED':
      return 'orange';
    default:
      return 'red';
  }
};

export const mapInputValue = (
  value: number | undefined
): string | undefined => {
  return parseInputValue(String(value)) || undefined;
};

export const trimExcessZeros = (input: string) => {
  return input.replace(/(\.[0-9]*[1-9])0*|(\.0+)$/, '$1');
};

export const hasDuplicates = (arr: string[]) =>
  new Set(arr).size !== arr.length;

export const getQueryString = (obj: { [key: string]: any }) => {
  const filteredObj = Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value)
  );

  const params = new URLSearchParams(filteredObj).toString();

  return params ? `?${params}` : '';
};

export function convertToAbsoluteUrl(baseUrl: string, url: string) {
  const base = new URL(baseUrl).origin;
  const convertedUrl = new URL(url, base);

  return convertedUrl.href;
}
