import { Divider, Image, Popover, Table, TableProps, Tag, Tooltip } from 'antd';
import {
  productApi,
  resetPrevProduct,
  resetProductData,
  setLimit,
  setPage,
  setSearch
} from 'app/features/products/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectProductCount,
  selectProductData,
  selectProductLimit,
  selectProductLoading,
  selectProductSearch
} from 'app/features/products/selectors';
import { useEffect, useState } from 'react';
import { _debounce, getFileUrl, getQueryString } from 'helpers/utils';
import { ColumnsType } from 'antd/lib/table';
import { IProduct } from 'app/features/products/types';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { selectUserRole } from 'app/features/users/selectors';
import { getBackgroundColor } from 'helpers/product';
import { parseInputValue } from 'helpers/NumericInput';
import COUNTRIES from 'constants/countries';

import youtubeImg from '../../assets/img/youtube-logo-png-46031.png';
import SpecialistIcon from '../../assets/img/specialist.svg';
import EditIcon from '../../assets/img/edit icon.svg';
import ContentTop from 'components/ContentTop';
import {
  FalseIcon,
  IconWrapper,
  ImgTableWrraper,
  StyledInfoCircle,
  TrueIcon
} from './styled';
import noImg from '../../assets/img/noimage.png';
import Messages from 'components/Messages';
import ProductSpecialists from 'components/ProductSpecialists';
import { EditStates } from 'pages/ProductDetails/components/Logistics/types';
import ArmeniaFlag from '../../assets/img/flags/armenia.png';
import GeorgiaFlag from '../../assets/img/flags/georgia.png';
import EgyptFlag from '../../assets/img/flags/egypt.png';

const debouncedFetch = _debounce(500);

const Products = () => {
  const [productId, setProductId] = useState<number>(0);
  const [filterValue, setFilterValue] = useState('');
  const [isToDoListVisible, setIsToDoListVisible] = useState(false);
  const [isSpecModalVisible, setIsSpecModalVisible] = useState(false);
  const [open, setOpen] = useState<EditStates>({});
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const productsData = useAppSelector(selectProductData());
  const count = useAppSelector(selectProductCount());
  const loading = useAppSelector(selectProductLoading());
  const search = useAppSelector(selectProductSearch());
  const limit = useAppSelector(selectProductLimit());
  const navigate = useNavigate();
  const countriesData = COUNTRIES;
  const page = searchParams.get('page');

  useEffect(() => {
    dispatch(setSearch(''));
  }, []);

  useEffect(() => {
    const validPage = page || 1;
    dispatch(setPage(validPage));
    setSearchParams(getQueryString({ page: validPage }));
  }, [dispatch, page, setSearchParams]);

  const onChangeTable: TableProps<IProduct>['onChange'] = (_, filters) => {
    const newValue = filters?.Action?.[0] || '';
    setFilterValue(String(newValue));
  };

  const userRole = useAppSelector(selectUserRole);

  const hideToDoList = () => {
    setIsToDoListVisible(false);
    setProductId(0);
  };

  const hideSpecModal = () => {
    setIsSpecModalVisible(false);
    setProductId(0);
  };

  const columns: ColumnsType<IProduct> = [
    {
      title: 'Image',
      dataIndex: 'files',
      align: 'center',
      key: 'image',
      width: 100,
      render: files => {
        const thumbnailImageFile =
          files.length > 0
            ? { ...files[0], path: getFileUrl(files[0].path) }
            : null;

        return (
          <ImgTableWrraper>
            {thumbnailImageFile ? (
              <Image
                height={55}
                width={70}
                alt={thumbnailImageFile.name}
                src={thumbnailImageFile.path}
                preview={{
                  src: thumbnailImageFile.path.replace('108x88', '574x438')
                }}
              />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },
    {
      title: 'Title',
      dataIndex: 'title_en',
      key: 'title_en',
      onCell: (record: IProduct) => {
        return {
          children: <div>{record.title_en}</div>
        };
      }
    },

    {
      title: 'CRM Code',
      dataIndex: 'code',
      key: 'code',
      onCell: (record: IProduct) => {
        return {
          children: <div>{record.code}</div>
        };
      }
    },
    {
      title: 'Presales Price',
      align: 'center',
      dataIndex: 'pre_sales_price',
      key: 'pre_sales_price',
      render: (value, record) => {
        const countryFlags: { [key: string]: string } = {
          Armenia: ArmeniaFlag,
          Georgia: GeorgiaFlag,
          Egypt: EgyptFlag
        };

        const title = record.product_country.map(countryPrice => {
          const countryName =
            countriesData.find(
              country => country.id === countryPrice.country_id
            )?.name || 'Unknown Country';

          const standardPrice =
            countryPrice.total_navy_price !== null
              ? `Standard Price: ${countryPrice.total_navy_price}`
              : '';

          const expressPrice =
            countryPrice.total_plane_price !== null
              ? `Express Price: ${countryPrice.total_plane_price}`
              : '';

          return (
            <div key={countryPrice.country_id}>
              <p>
                Country: {countryName}{' '}
                {countryFlags[countryName] && (
                  <img
                    src={countryFlags[countryName]}
                    width={20}
                    height={20}
                    alt={`${countryName} Flag`}
                  />
                )}
              </p>
              {standardPrice && <p>{standardPrice}$</p>}
              {expressPrice && <p>{expressPrice}$</p>}
              {record.product_country.length > 1 && (
                <Divider orientation="left" style={{ margin: 5 }} />
              )}{' '}
            </div>
          );
        });

        return (
          <div>
            {parseInputValue(value)}$
            <Popover
              title={title}
              trigger="click"
              open={open[record.id]}
              onOpenChange={newOpen => {
                setOpen(prevOpen => ({ ...prevOpen, [record.id]: newOpen }));
              }}
            >
              {record.product_country.length > 0 && (
                <Tooltip placement="bottomLeft" title="Country price available">
                  <StyledInfoCircle />
                </Tooltip>
              )}
            </Popover>
          </div>
        );
      }
    },
    {
      title: 'Process',
      dataIndex: 'process',
      align: 'center',
      key: 'process',
      render: value => (
        <Tag color={getBackgroundColor(value)}>
          {value
            .replaceAll('_', ' ')
            .replace('PROCESS', '')
            .replace('ADMIN', 'CONTENT WRITER')}
        </Tag>
      )
    },
    {
      title: 'Attributes',
      dataIndex: 'product_attributes',
      key: 'attributes',
      render: attributes => {
        const content = (
          <div>
            {attributes.map((attribute: any, index: number) => (
              <div key={index}>
                <p>
                  {attribute.attribute.description_en} :{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {attribute.enum_value != null
                      ? attribute.enum_value
                      : attribute.attribute.values_en.min +
                        '  -  ' +
                        attribute.attribute.values_en.max}
                  </span>
                </p>
              </div>
            ))}
          </div>
        );

        return attributes.length > 2 ? (
          <Popover content={content} trigger="click">
            <div style={{ cursor: 'pointer' }}>
              {attributes.slice(0, 2).map((attribute: any, index: number) => (
                <div key={index}>
                  <p>
                    {attribute.attribute.description_en} :{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {attribute.enum_value != null
                        ? attribute.enum_value
                        : attribute.attribute.values_en.min +
                          '  -  ' +
                          attribute.attribute.values_en.max}
                    </span>
                  </p>
                </div>
              ))}
              <p>...</p>
            </div>
          </Popover>
        ) : (
          <div>
            {attributes.map((attribute: any, index: number) => (
              <div key={index}>
                <p>
                  {attribute.attribute.description_en} :{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {attribute.enum_value != null
                      ? attribute.enum_value
                      : attribute.attribute.values_en.min +
                        '  -  ' +
                        attribute.attribute.values_en.max}
                  </span>
                </p>
              </div>
            ))}
          </div>
        );
      }
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      width: 40,
      align: 'center',
      key: 'availability',
      render: value => {
        const color = value ? 'green' : 'red';
        const icon = value ? (
          <TrueIcon style={{ fontSize: 20 }} />
        ) : (
          <FalseIcon style={{ fontSize: 20 }} />
        );

        return <IconWrapper icon={icon} key={value} color={color} />;
      }
    },
    {
      title: 'CBM',
      dataIndex: 'cbm',
      key: 'cbm',
      align: 'center',
      render: (value: string) => {
        return <div>{Number(value).toFixed(2)}</div>;
      }
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      align: 'center',
      onCell: (record: IProduct) => {
        return {
          children: <div>{record.unit}</div>
        };
      }
    },
    {
      title: 'Youtube',
      dataIndex: 'youtube_urls',
      key: 'youtube_urls',
      render: youtube_urls => {
        return (
          <div
            style={{
              display: 'grid',
              placeItems: 'center'
            }}
          >
            {Boolean(youtube_urls?.length) && (
              <img src={youtubeImg} alt="YouTube" style={{ width: '30px' }} />
            )}
          </div>
        );
      }
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      align: 'center',
      filterMultiple: false,
      filters: [
        // {
        //   text: 'All Products',
        //   value: 'allProducts'
        // },
        {
          text: 'Rejected Products',
          value: 'rejected'
        },
        {
          text: 'Back Stepped Products',
          value: 'previous'
        },
        {
          text: 'Presales products',
          value: 'presales'
        },
        {
          text: 'Content Writer products',
          value: 'admin'
        },
        {
          text: 'Logistics products',
          value: 'logistics'
        },
        {
          text: 'Sales/Published products',
          value: 'sales'
        },
        {
          text: 'Discontinued Products',
          value: 'discontinued'
        }
      ],
      render: (_, record) => {
        return (
          <>
            <Link to={`details/${record.id}`}>
              <Tooltip title="Edit">
                <Tag
                  onClick={() => dispatch(resetPrevProduct())}
                  icon={<img src={EditIcon} alt="Edit" />}
                  style={{
                    padding: 0,
                    margin: 0,
                    cursor: 'pointer',
                    border: 'none',
                    backgroundColor: 'transparent'
                  }}
                ></Tag>
              </Tooltip>
            </Link>

            <Tooltip title="Specialists">
              <Tag
                icon={<img src={SpecialistIcon} alt="Specialists" />}
                onClick={() => {
                  setProductId(record.id);
                  setIsSpecModalVisible(true);
                }}
                style={{
                  cursor: 'pointer',
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  backgroundColor: 'transparent'
                }}
              ></Tag>
            </Tooltip>
            {/* <Tag
              icon={<img src={MessageIcon} alt="Messages" />}
              onClick={() => {
                setProductId(record.id);
                setIsToDoListVisible(true);
              }}
              style={{
                cursor: 'pointer',
                border: 'none',
                padding: 0,
                margin: 0,
                backgroundColor: 'transparent'
              }}
            ></Tag> */}
            {/* </Popover> */}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    const processMapping: Record<string, string> = {
      rejected: 'rejected_process',
      presales: 'pre_sales_process',
      admin: 'admin_process',
      logistics: 'logistics_process',
      sales: 'sales_process',
      discontinued: 'discontinued_process',
      default: 'super_process'
    };

    const fetchData = async () => {
      const process = processMapping[filterValue] || processMapping.default;
      const body: Record<string, string | undefined> = {};

      if (filterValue === 'previous') {
        body.last_change = 'previous';
      } else {
        body.process = process;
      }

      await dispatch(productApi({ body }));
    };

    debouncedFetch(fetchData);
  }, [dispatch, search, page, limit, filterValue, userRole]);

  useEffect(() => {
    return () => {
      dispatch(setPage(page));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  return (
    <>
      <ContentTop
        title="Products"
        placeholder="Search Product"
        buttonText="Add Product"
        upload
        onClickAdd={() => {
          navigate('details');
          dispatch(resetProductData());
        }}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />

      <Table
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          current: Number(page),
          onChange: (page, pageSize) => {
            setSearchParams(getQueryString({ page }));
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
        bordered
        columns={columns}
        dataSource={productsData}
        rowKey="id"
      />
      <Messages
        userRole={userRole}
        isVisible={isToDoListVisible}
        onClose={hideToDoList}
        productId={productId}
      />

      <ProductSpecialists
        productId={productId}
        isVisible={isSpecModalVisible}
        onClose={hideSpecModal}
      />
    </>
  );
};

export default Products;
