import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import {
  ErrorType,
  showErrorMessage,
  showErrorUploadMessage
} from 'helpers/errors';
import { RootState } from 'app/store';
import { Modal, message } from 'antd';

import { FileUploadParams, FilesState } from './types';
import {
  selectFilesLimit,
  selectFilesPage,
  selectFilesSearch
} from './selectors';

const initialState: FilesState = {
  loading: false,
  loadingAction: false,
  fileById: null,
  loadingById: false,
  picturesFiles: [],
  invoiceFiles: [],
  search: '',
  page: 1,
  limit: 12,
  count: 0
};

export const fetchPicturesFilesApi = createAsyncThunk(
  'files/fetchPicturesFilesApi',
  async ({ size }: { size?: string }, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const search = selectFilesSearch()(state);
    const page = selectFilesPage()(state);
    const limit = selectFilesLimit()(state);
    const body = { file_type: 'pictures' };

    const params = {
      page,
      search,
      limit,
      size
    };

    try {
      const response = await client.post(`/file/explore`, body, { params });

      return response.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const fetchInvoiceFilesApi = createAsyncThunk(
  'files/fetchInvoiceFilesApi',
  async (
    payload: { hide?: boolean; manualLimit?: number } | undefined,
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const search = selectFilesSearch()(state);
    const page = selectFilesPage()(state);
    const limit = selectFilesLimit()(state);
    const body = {
      file_type: 'invoice',
      hide: payload ? payload.hide : false
    };

    const params = {
      page,
      search,
      limit: payload?.manualLimit ? payload.manualLimit : limit
    };

    try {
      const response = await client.post(`/file/explore`, body, { params });

      return response.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const postFile = createAsyncThunk(
  'files/postFile',
  async (
    { file, ...data }: FileUploadParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('product_id', data.product_id.toString());
      formData.append('file_type', data.file_type);
      await client.post('/file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      Modal.success({
        content: 'File uploaded successfully!'
      });

      if (data.file_type === 'invoice') {
        await dispatch(fetchInvoiceFilesApi());
      } else {
        dispatch(fetchPicturesFilesApi({}));
      }
    } catch (err) {
      return rejectWithValue(
        showErrorUploadMessage(err as AxiosError<ErrorType>)
      );
    }
  }
);

export const getFileById = createAsyncThunk(
  'files/fileById',
  async (productId: string, { rejectWithValue }) => {
    try {
      const response = await client.get(`/file/${productId}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteFile = createAsyncThunk(
  'files/deleteFile',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.delete(`/file/${id}`);

      await Promise.all([
        dispatch(fetchPicturesFilesApi({})),
        dispatch(fetchInvoiceFilesApi())
      ]);

      if (response.status === 200) {
        message.success({
          content: `file with Id ${id} deleted successfully`
        });
      }
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInvoiceFilesApi.pending, state => {
        state.loading = true;
      })
      .addCase(fetchInvoiceFilesApi.fulfilled, (state, action) => {
        state.loading = false;
        state.invoiceFiles = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(fetchInvoiceFilesApi.rejected, state => {
        state.loading = false;
      })
      .addCase(fetchPicturesFilesApi.pending, state => {
        state.loading = true;
      })
      .addCase(fetchPicturesFilesApi.fulfilled, (state, action) => {
        state.loading = false;
        state.picturesFiles = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(fetchPicturesFilesApi.rejected, state => {
        state.loading = false;
      })
      .addCase(deleteFile.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteFile.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteFile.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postFile.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postFile.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postFile.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getFileById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getFileById.fulfilled, (state, action) => {
        state.fileById = action.payload.rows;
        state.loadingById = false;
      })
      .addCase(getFileById.rejected, state => {
        state.loadingById = false;
      });
  }
});

export const { setPage, setSearch, setLimit } = filesSlice.actions;
export default filesSlice.reducer;
