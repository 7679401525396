import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import {
  deleteProductAccessory,
  getAccessoriesByCategoryId,
  postProductAccessories,
  setLimit,
  setPage,
  setSearch
} from 'app/features/accessories/slice';
import {
  selectAccessoriesByCategoryId,
  selectAccessoryCount,
  selectAccessoryLimit,
  selectAccessoryLoadingAction,
  selectAccessoryLoadingById,
  selectAccessoryPage,
  selectAccessorySearch
} from 'app/features/accessories/selectors';
import { AccessoriesByCategoryId } from 'app/features/accessories/types';
import Table, { ColumnsType } from 'antd/es/table';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { parseInputValue } from 'helpers/NumericInput';

import AccessoryModal from 'pages/AccessoriesDetails/components/AccessoryModal';
// import DeleteModal from 'components/DeleteModal';
import { LeftPage } from 'components/DetailsHead/styled';
import { ButtonsWrapper } from './styled';

// const validateMessages = {
//   required: "'${label}' is Required!"
// };

const AccessoriesDetails: React.FC<{
  id: number | null;
  setShowAccessoriesDetails: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ id, setShowAccessoriesDetails }) => {
  const productId = Number(useParams().id);

  // console.log(id, 'importesId');
  // const { id } = useParams();
  const accessoryCategoryId = Number(id);
  // state
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showAccessoryModal, setShowAccessoryModal] = useState(false);
  const [accessoryId, setAccessoryId] = useState<number>(Number(id));
  // const [accessoryCategoryId, setAccessoryCategoryId] = useState<number>(
  //   Number(id)
  // );

  // const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const accessoriesByCategoryId = useAppSelector(
    selectAccessoriesByCategoryId()
  );

  // const loading = useAppSelector(selectAccessoryLoading());
  const page = useAppSelector(selectAccessoryPage());
  const limit = useAppSelector(selectAccessoryLimit());
  const count = useAppSelector(selectAccessoryCount());
  const search = useAppSelector(selectAccessorySearch());
  const loadingAction = useAppSelector(selectAccessoryLoadingAction());
  const loadingById = useAppSelector(selectAccessoryLoadingById());

  // form
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    setShowAccessoryModal(false);
    setEditMode(false);
    setAccessoryId(0);
  };

  useEffect(() => {
    if (id) {
      dispatch(getAccessoriesByCategoryId(Number(id)));
    }
  }, [dispatch, id, search, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  // const onAddAccessory = () => {
  //   setShowAccessoryModal(true);
  //   setEditMode(false);
  // };

  const handleGoBack = () => {
    setShowAccessoriesDetails(false);
  };

  const handleAddAccessory = async (id: number) => {
    const postData = {
      product_id: productId,
      accessory_id: id
      // price?:
    };

    await dispatch(
      postProductAccessories({ accessoryCategoryId, data: postData })
    );
    // dispatch(getAccessoryById(id));
    // setAccessoryId(id);
    // setShowAccessoryModal(true);
    setEditMode(true);
  };

  const handleDeleteAccessory = async (accessoryId: number) => {
    const postData = {
      product_id: productId,
      accessory_id: accessoryId
    };

    await dispatch(
      deleteProductAccessory({ accessoryCategoryId, data: postData })
    );
    // await dispatch(deleteProductAccessory({ accessoryId, productId }));
    // setOpenDeleteModal(false);
    setAccessoryId(0);
  };

  const columns: ColumnsType<AccessoriesByCategoryId> = [
    {
      title: 'Accessory Category name',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (record: { price?: string }) => parseInputValue(String(record))
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      // align: 'center',
      width: 220,
      key: 'operation',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Button
              loading={loadingAction}
              disabled={
                !!record.products?.find(product => product.id === productId)
              }
              size="large"
              icon={<PlusOutlined />}
              onClick={() => {
                handleAddAccessory(record.id);
              }}
            ></Button>
            <Button
              loading={loadingAction}
              size="large"
              disabled={
                !record.products?.find(product => product.id === productId)
              }
              style={{ marginLeft: 10 }}
              danger
              icon={<MinusOutlined />}
              onClick={() => {
                setAccessoryId(record.id);
                // setOpenDeleteModal(true);
                handleDeleteAccessory(record.id);
              }}
            ></Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <LeftPage style={{ margin: 15 }} onClick={handleGoBack} />

      <Table
        bordered
        columns={columns}
        dataSource={accessoriesByCategoryId}
        loading={loadingById}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
      <AccessoryModal
        isOpen={showAccessoryModal}
        onClose={onClose}
        params={{
          accessoryCategoryId,
          accessoryId,
          editMode
        }}
      />
      {/* <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={handleDeleteAccessory}
        onCancel={() => {
          setOpenDeleteModal(false);
          setAccessoryId(0);
        }}
      /> */}
    </>
  );
};

export default AccessoriesDetails;
