import {
  Button,
  Drawer,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  message
} from 'antd';
import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectFilesSearch } from 'app/features/files/selectors';
import { setPage, setSearch } from 'app/features/files/slice';
import { useCustomRequest } from 'Hook/useCustomRequest';
import { UploadOutlined } from '@ant-design/icons';
import { getFileUrl } from 'helpers/utils';

import { IFilePageProps } from './types';
import {
  AddButtonWrraper,
  ContentTopTitle,
  ContentTopWrapper,
  DrawerStyled,
  SearchStyled,
  UploadWrraper
} from './styled';
import Files from './components/files';

const { Dragger } = Upload;
const { Title } = Typography;

const FilePage: FC<IFilePageProps> = ({ open, setOpen }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [contractFileList, setContractFileList] = useState<UploadFile[]>([]);
  const [openAddImage, setOpenAddImage] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string>('1');

  //selectors
  const search = useAppSelector(selectFilesSearch());

  //dispatch
  const dispatch = useAppDispatch();

  const onChangeImageUpload: UploadProps['onChange'] = async ({
    fileList: newFileList
  }) => {
    setFileList(newFileList);

    const isAllUploaded = newFileList.every(file => file.status === 'done');

    if (isAllUploaded) {
      // setFileList([]);
      await message.success('Images uploaded successfully!', 2);
      // setOpenAddFile(false);
      dispatch(setPage(1));
      dispatch(setSearch(''));
    }
  };

  const onChangeFileUpload: UploadProps['onChange'] = async info => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map(file => {
      if (file.response) {
        file.url = getFileUrl(
          file.response.data.payload.map((item: { path: string }) => item.path)
        );
      }

      return file;
    });
    setContractFileList(newFileList);

    const isAllUploaded = newFileList.every(file => file.status === 'done');

    if (isAllUploaded) {
      // setContractFileList([]);
      await message.success('Contracts uploaded successfully!', 2);
      // setOpenAddFile(false);
      dispatch(setPage(1));
      dispatch(setSearch(''));
      // await dispatch(filesApi());
    }
  };

  const pictureUpload = useCustomRequest({
    url: 'file/createGlobal',
    file_type: 'pictures'
    // folder: 'folderName'
  });

  const fileUpload = useCustomRequest({
    url: 'file/createInvoiceFile'
    // file_type: 'invoice'
    // folder: 'folderName'
  });

  // const onFileRemove = async (file: any) => {
  //   Modal.confirm({
  //     title: 'Confirm',
  //     content: `Are you sure you want to remove ${file.name}?`,
  //     onOk: async () => {
  //       await dispatch(deleteFile(file.uid));
  //     },
  //     onCancel: () => {
  //       setContractFileList(contractFileList); // Restore the original fileList
  //     }
  //   });
  // };

  const handleClose = () => {
    setOpen(false);
    setActiveTabKey('1');
    // dispatch(fetchInvoiceFilesApi());
    // dispatch(fetchInvoiceFilesApi({ manualLimit: 200 }));
  };

  return (
    <>
      <Drawer
        title="Add Contracts"
        placement="right"
        onClose={() => setOpenAddFile(false)}
        open={openAddFile}
        width={700}
      >
        <UploadWrraper>
          <Dragger
            onChange={onChangeFileUpload}
            fileList={contractFileList}
            accept=".pdf"
            customRequest={fileUpload}
            listType="text"
            multiple
            // onRemove={onFileRemove}
          >
            <Button icon={<UploadOutlined />}>
              Attach Files (Contacts, Contracts, Invoice)
            </Button>
          </Dragger>
        </UploadWrraper>
      </Drawer>
      <Drawer
        title="Add Images"
        placement="right"
        onClose={() => setOpenAddImage(false)}
        open={openAddImage}
        width={700}
      >
        <UploadWrraper>
          <Dragger
            onChange={onChangeImageUpload}
            fileList={fileList}
            accept="image/*"
            customRequest={pictureUpload}
            listType="picture"
            multiple
          >
            <Button icon={<UploadOutlined />}>Upload Images</Button>
          </Dragger>
        </UploadWrraper>
      </Drawer>
      <DrawerStyled
        title={
          <ContentTopWrapper>
            <ContentTopTitle>
              <Title level={3}>Contracts And Images</Title>
              <SearchStyled
                onChange={e => {
                  dispatch(setSearch(e.target.value));
                }}
                size="middle"
                value={search}
                placeholder="Search File"
                enterButton
              />
            </ContentTopTitle>
            <AddButtonWrraper style={{ gap: 10 }}>
              <Button type="primary" onClick={() => setOpenAddFile(true)}>
                Add Contracts
              </Button>
              <Button type="primary" onClick={() => setOpenAddImage(true)}>
                Add Images
              </Button>
            </AddButtonWrraper>
          </ContentTopWrapper>
        }
        placement="right"
        onClose={handleClose}
        open={open}
        width={1200}
      >
        <Files activeTabKey={activeTabKey} setActiveTabKey={setActiveTabKey} />
      </DrawerStyled>
    </>
  );
};

export default FilePage;
