import { Rule } from 'antd/es/form';

// Regular expression to match valid YouTube embed video URLs
const youtubeIframeRegex =
  /<iframe.*?src="https:\/\/www\.youtube\.com\/embed\/([A-Za-z0-9_-]{11}).*?<\/iframe>/;

// Regular expression to match valid  URLs
const urlRegex = /^(https?:\/\/|http?:\/\/)(?!.*<iframe)[^\s/$.?#].[^\s]*$/;

// Custom validation function
export const validateYoutubeUrls = (value: string[]) => {
  if (!value || value.length === 0) {
    return Promise.resolve();
  }

  const isValid = value.every(url => youtubeIframeRegex.test(url));

  if (isValid) {
    return Promise.resolve();
  } else {
    return Promise.reject('Please enter valid YouTube embed video URLs');
  }
};

export const validateNotZero = (_: unknown, value: number) => {
  if (value === 0) {
    return Promise.reject('Value cannot be 0');
  }

  return Promise.resolve();
};

export const isUrl = (url: string) => {
  return urlRegex.test(url);
};

export const isValidUrl = (url: string[]) => {
  if (!url || url.length === 0) {
    return Promise.resolve();
  }

  const isValid = url.every(url => urlRegex.test(url));

  if (isValid) {
    return Promise.resolve();
  } else {
    return Promise.reject('Please enter a valid URL');
  }

  // return urlRegex.test(url);
};

export const isNotZeroRule: Rule = {
  pattern: /^(?!0*(\.0*)?$)\d{1,3}(,\d{3})*(\.\d+)?$/,
  message: 'Value must be greater than 0 and end with a number'
};

export const decimalPrecisionRule: Rule = {
  pattern: /^(?:\d{1,3}(,\d{3})*(\.\d{0,10})?|\d+)$/,
  message: 'Maximum of 10 digits allowed after the decimal point.'
};

export function hasTextOrImage(html: string) {
  const htmlTagRegex = /<[^>]*>/g;
  const textWithoutTags = html.replace(htmlTagRegex, '');
  const hasImage = /<img [^>]*>/.test(html);
  const hasTextOrImage = /\S/.test(textWithoutTags) || hasImage;

  return hasTextOrImage;
}

// export const isValidUrl = (url: string) => {
//   if (urlRegex.test(url)) {
//     return Promise.resolve();
//   } else {
//     return Promise.reject('Please enter valid URL!');
//   }
// };
