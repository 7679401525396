import { RootState } from 'app/store';

export const selectAccessoryData = () => (state: RootState) =>
  state.accessories.accessories;

export const selectAccessoryLoading = () => (state: RootState) =>
  state.accessories.loading;

export const selectAccessoryById = () => (state: RootState) =>
  state.accessories.accessoryById;

export const selectAccessoriesByCategoryId = () => (state: RootState) =>
  state.accessories.accessoriesByCategoryId;

export const selectAccessoriesByProductId = () => (state: RootState) =>
  state.accessories.accessoriesByProductId;

export const selectAccessoryPage = () => (state: RootState) =>
  state.accessories.page;

export const selectAccessorySearch = () => (state: RootState) =>
  state.accessories.search;

export const selectAccessoryLimit = () => (state: RootState) =>
  state.accessories.limit;

export const selectAccessoryCount = () => (state: RootState) =>
  state.accessories.count;

export const selectAccessoryLoadingAction = () => (state: RootState) =>
  state.accessories.loadingAction;

export const selectAccessoryLoadingById = () => (state: RootState) =>
  state.accessories.loadingById;
