import { Button, Input, Space, Upload } from 'antd';
import styled from 'styled-components';

export const ContentTopTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ContentTopWrapper = styled.div`
  margin-bottom: 24px;
`;
export const AddButton = styled(Button)`
  margin-top: 8px;
`;
export const SearchStyled = styled(Input.Search)`
  margin-top: 8px;
  width: 700px;

  @media (max-width: 1200px) {
    width: auto;
  }
`;
export const AddButtonWrapper = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
// export const AddButtonWrapper = styled(Space)`
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
//   align-items: flex-start;
// `;

export const UploadDraggerFile = styled(Upload.Dragger)`
  .ant-upload-drag {
    margin-top: 8px;
    margin-right: 80px;
    border: none;
    background-color: white;
    .ant-upload {
      padding: 0;
    }
  }
`;
