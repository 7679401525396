import { object, string, number, array, boolean } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { VendorFormDataType } from './index';

export const VendorDetailsSchema = yupResolver<VendorFormDataType>(
  object().shape({
    is_visible: boolean(),
    name: string().required('Name is required').max(150, 'Maximum 150 symbols'),
    url: string().url('Invalid URL').required('URL is required'),
    email: string()
      .matches(/^\s*([\w-.]+@([\w-]+\.)+[\w-]{2,4})?\s*$/, 'Invalid email')
      .required('Email is required'),
    country_id: number().required('Country is required'),
    files: array().nullable(),
    description: string().required('Description is required'),
    descriptionValue: string().required('Description is required')
  })
);
