import { RootState } from 'app/store';

export const selectVendorFormData = () => (state: RootState) =>
  state.vendorForm.vendorForm;

export const selectVendorFormCount = () => (state: RootState) =>
  state.vendorForm.count;

export const selectVendorFormLoading = () => (state: RootState) =>
  state.vendorForm.loading;

export const selectVendorFormLoadingAction = () => (state: RootState) =>
  state.vendorForm.loadingAction;
