import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  Typography
} from 'antd';
import NumericInput, {
  cleanAndParse,
  parseInputValue
} from 'helpers/NumericInput';
import {
  CalculatorOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import TextArea from 'antd/es/input/TextArea';
import { selectProductCountryLoading } from 'app/features/productCountries/selectors';
import { FC, useState } from 'react';
import { isNotZeroRule } from 'helpers/validators';
import { selectCbmCountryData } from 'app/features/cbmCountries/selectors';

import { FormContainer, RadioCheckbox } from '../../styled';
import { ReactComponent as NavyIcon } from '../../../../../../assets/icons/ship_icon.svg';
import { ReactComponent as PlaneIcon } from '../../../../../../assets/icons/plane_icon.svg';
import useInputHandlers from './inputHandlers';
import useCountryCrud from './countryCrud';
//import { EditStates } from '../../types';

interface LogisticsFormProps {
  form: FormInstance;
  productId: number | undefined;
}

const LogisticsForm: FC<LogisticsFormProps> = ({ form, productId }) => {
  const {
    handleCountrySelect,
    handleCheckboxChange,
    handleLengthWidthHeight,
    handleWeightKg,
    handlePercentOrFixSelect,
    handlePercentOrFixSelectPlane,
    isManual,
    inputsRequired,
    isBigger,
    handlePricePerKg
  } = useInputHandlers({ form });

  const { Text } = Typography;

  const { handleCreateAndUpdate, handleDelete, handleCalculate } =
    useCountryCrud({
      form,
      productId
    });

  const loadingAction = useAppSelector(selectProductCountryLoading());
  const cbmCountryData = useAppSelector(selectCbmCountryData());
  const [isSecondSaveClicked, setIsSecondSaveClicked] = useState(false);

  // const [, setEditStatesNavy] = useState<EditStates>({});
  // const [, setEditStatesPlane] = useState<EditStates>({});

  return (
    <FormContainer>
      <Form.List
        name={'ctryPrcFormList'}
        initialValue={[{ percentOrFix: 'percent' }]}
      >
        {(fields, { add, remove }) => {
          const items = fields.map(({ key, name, ...restField }) => {
            const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');
            const {
              countryPrice,
              standardPrice,
              expressPrice,
              itemId = null
            } = ctryPrcFormList[name] || {};

            return {
              key,
              showArrow: !!countryPrice && countryPrice !== '-',
              label: (
                <Row>
                  <Form.Item {...restField} name={[name, 'itemId']} hidden>
                    <Input />
                  </Form.Item>

                  <Col span={9}>
                    <Form.Item
                      {...restField}
                      name={[name, 'countryId']}
                      rules={[{ required: false }]}
                    >
                      <Select
                        disabled={!!itemId}
                        showSearch
                        onSelect={(_, option) =>
                          handleCountrySelect({ name, option })
                        }
                        placeholder="Select Country"
                        optionLabelProp="label"
                        optionFilterProp="label"
                        onClick={event => event.stopPropagation()}
                      >
                        {cbmCountryData.map(country => {
                          return (
                            <Select.Option
                              key={country.id}
                              value={country.id}
                              label={country.name}
                              price={country.cbm_country.price}
                            >
                              {country.name}{' '}
                              {country.cbm_country && (
                                <Tag style={{ float: 'right' }} color="green">
                                  CBM:{' '}
                                  {parseInputValue(country.cbm_country.price)}
                                </Tag>
                              )}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Form.Item
                    {...restField}
                    name={[name, 'countryPrice']}
                    hidden
                    initialValue={'-'}
                  >
                    <Input />
                  </Form.Item>
                  {countryPrice && countryPrice !== '-' && (
                    <Form.Item {...restField}>
                      <Button
                        size="middle"
                        style={{ marginLeft: '5px' }}
                        danger
                        icon={<DeleteOutlined />}
                        onClick={event => {
                          event.stopPropagation();
                          Modal.confirm({
                            title:
                              'Are you sure you want to remove this country price?',
                            onOk: () =>
                              handleDelete(
                                itemId,
                                productId,
                                remove,
                                name,
                                'all'
                              )
                          });
                        }}
                      />
                    </Form.Item>
                  )}
                </Row>
              ),
              extra: (
                <>
                  <Form.Item
                    {...restField}
                    name={[name, 'standardPrice']}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'expressPrice']}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Typography onClick={event => event.stopPropagation()}>
                    <pre style={{ margin: '1.4%', width: '100%' }}>
                      Standard: {parseInputValue(standardPrice) || '--'}/
                      Express: {expressPrice || '--'}
                    </pre>
                  </Typography>
                </>
              ),
              children: (
                <>
                  <Row gutter={[15, 0]}>
                    <Col span={10}>
                      <Form.Item
                        style={{ marginBottom: 10 }}
                        {...restField}
                        name={[name, 'navyCheckbox']}
                        initialValue={true}
                      >
                        <RadioCheckbox
                          checked={!isManual[name]}
                          onChange={e => {
                            handleCheckboxChange(
                              e.target.checked,
                              name,
                              'navyCheckbox',
                              'manualCheckbox'
                            );
                          }}
                        >
                          <NavyIcon
                            style={{
                              opacity: isManual[name] ? 0.4 : 1
                            }}
                          />
                          {' Navy CBM'}
                        </RadioCheckbox>
                      </Form.Item>
                    </Col>
                    <Col span={2} offset={9} style={{ paddingLeft: '50px' }}>
                      {
                        <Button
                          type="primary"
                          onClick={() => handleCalculate(name, 'navy')}
                          loading={loadingAction}
                          icon={<CalculatorOutlined />}
                        >
                          Calculate
                        </Button>
                      }
                    </Col>
                    <Col span={2} style={{ paddingLeft: '50px' }}>
                      <Space>
                        {
                          <>
                            <Button
                              onClick={async () => {
                                setIsSecondSaveClicked(false);
                                await handleCreateAndUpdate(name, 'navy');

                                // if (res) {
                                //   setEditStatesNavy(prevEditStates => ({
                                //     ...prevEditStates,
                                //     [name]: !prevEditStates[name]
                                //   }));
                                // }
                              }}
                              icon={<SaveOutlined />}
                            >
                              Save
                            </Button>
                            <Button
                              danger
                              onClick={() => {
                                Modal.confirm({
                                  title:
                                    'Are you sure you want to remove this country price segment?',
                                  onOk: async () => {
                                    await handleDelete(
                                      itemId,
                                      productId,
                                      remove,
                                      name,
                                      'navy'
                                    );

                                    // if (res) {
                                    //   setEditStatesNavy(prevEditStates => ({
                                    //     ...prevEditStates,
                                    //     [name]: !prevEditStates[name]
                                    //   }));
                                    // }
                                  }
                                });
                              }}
                              icon={<DeleteOutlined />}
                            />
                          </>
                        }
                      </Space>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'navySubtotal']}
                        label="CBM Subtotal"
                        rules={[
                          { required: !isSecondSaveClicked && !isManual[name] }
                        ]}
                      >
                        <NumericInput
                          disabled={true}
                          placeholder="CBM Subtotal"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Space.Compact block key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'percentOrFix']}
                          label="Tax"
                          initialValue="percent"
                        >
                          <Select
                            disabled={isManual[name]}
                            defaultValue={'percent'}
                            style={{ minWidth: 150 }}
                            options={[
                              { value: 'percent', label: 'Tax Percent' },
                              { value: 'fixPrice', label: 'Tax Fix' }
                            ]}
                            onSelect={() =>
                              handlePercentOrFixSelect(name, 'navy')
                            }
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          label="Value"
                          {...restField}
                          name={[name, 'percentOrFixValue']}
                          rules={[isNotZeroRule]}
                        >
                          <NumericInput
                            style={{ minWidth: 200 }}
                            disabled={isManual[name]}
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, 'otherCost']}
                        label="Other Cost"
                        rules={[isNotZeroRule]}
                      >
                        <NumericInput
                          disabled={isManual[name]}
                          placeholder="Other Cost"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    style={{ marginBottom: 10 }}
                    {...restField}
                    name={[name, 'manualCheckbox']}
                    initialValue={false}
                  >
                    <RadioCheckbox
                      checked={isManual[name]}
                      onChange={e =>
                        handleCheckboxChange(
                          e.target.checked,
                          name,
                          'manualCheckbox',
                          'navyCheckbox'
                        )
                      }
                    >
                      {' Manual (Navy)'}
                    </RadioCheckbox>
                  </Form.Item>
                  <Row gutter={[24, 0]}>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'cost']}
                        label="Cost"
                        rules={[
                          { required: !isSecondSaveClicked && isManual[name] },
                          isNotZeroRule
                        ]}
                      >
                        <NumericInput
                          disabled={!isManual[name]}
                          placeholder="Cost"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Space.Compact block key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'percentOrFixManual']}
                          label="Tax"
                          initialValue="percent"
                        >
                          <Select
                            disabled={!isManual[name]}
                            defaultValue={'percent'}
                            style={{ minWidth: 150 }}
                            options={[
                              { value: 'percent', label: 'Tax Percent' },
                              { value: 'fixPrice', label: 'Tax Fix' }
                            ]}
                            onSelect={() =>
                              handlePercentOrFixSelect(name, 'manual')
                            }
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          label="Value"
                          {...restField}
                          name={[name, 'percentOrFixValueManual']}
                          rules={[isNotZeroRule]}
                        >
                          <NumericInput
                            disabled={!isManual[name]}
                            style={{ minWidth: 200 }}
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'otherCostManual']}
                        label="Other Cost"
                        rules={[isNotZeroRule]}
                      >
                        <NumericInput
                          disabled={!isManual[name]}
                          placeholder="Other Cost"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...restField} name={[name, 'commentNavy']}>
                        <TextArea
                          maxLength={1000}
                          autoSize={{ minRows: 3, maxRows: 10 }}
                          placeholder="Comment here if needed"
                        ></TextArea>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider />

                  <Row>
                    <Col span={3}>
                      <PlaneIcon />
                      {' Plane KG'}
                    </Col>
                    <Col offset={17} span={2}>
                      <Space>
                        <Button
                          // disabled={!allowCalculate[name]}
                          type="primary"
                          onClick={() => handleCalculate(name, 'plane')}
                          loading={loadingAction}
                          icon={<CalculatorOutlined />}
                        >
                          Calculate
                        </Button>

                        <>
                          <Button
                            onClick={async () => {
                              setIsSecondSaveClicked(true);
                              await handleCreateAndUpdate(name, 'plane');

                              // if (res) {
                              //   setEditStatesPlane(prevEditStates => ({
                              //     ...prevEditStates,
                              //     [name]: !prevEditStates[name]
                              //   }));
                              // }
                            }}
                            icon={<SaveOutlined />}
                          >
                            Save
                          </Button>
                          <Button
                            danger
                            onClick={() => {
                              Modal.confirm({
                                title:
                                  'Are you sure you want to remove this country price segment?',
                                onOk: async () => {
                                  // const res =
                                  await handleDelete(
                                    itemId,
                                    productId,
                                    remove,
                                    name,
                                    'plane'
                                  );

                                  // if (res) {
                                  //   setEditStatesPlane(prevEditStates => ({
                                  //     ...prevEditStates,
                                  //     [name]: !prevEditStates[name]
                                  //   }));
                                  // }
                                }
                              });
                            }}
                            icon={<DeleteOutlined />}
                          />
                        </>
                      </Space>
                    </Col>
                  </Row>

                  <Row gutter={[12, 24]}>
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, 'lengthCm']}
                        label="Lenght"
                        rules={[{ required: inputsRequired }, isNotZeroRule]}
                      >
                        <NumericInput
                          // status={!isBigger[name] ? 'warning' : ''}
                          onBlur={() => handleLengthWidthHeight(name)}
                          placeholder="(cm)"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, 'widthCm']}
                        label="Width"
                        rules={[{ required: inputsRequired }, isNotZeroRule]}
                      >
                        <NumericInput
                          // status={!isBigger[name] ? 'warning' : ''}
                          onBlur={() => handleLengthWidthHeight(name)}
                          placeholder="(cm)"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, 'heightCm']}
                        label="Height"
                        rules={[
                          {
                            required: inputsRequired
                          },
                          isNotZeroRule
                        ]}
                      >
                        <NumericInput
                          // status={!isBigger[name] ? 'warning' : ''}
                          onBlur={() => handleLengthWidthHeight(name)}
                          placeholder="(cm)"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, 'cbmWeightKg']}
                        label="CBM Weight"
                        rules={[isNotZeroRule]}
                      >
                        <NumericInput
                          status={!isBigger[name] ? 'warning' : ''}
                          // value={presalesPriceValue}
                          // onChange={() => setPresalesPriceValue}
                          placeholder="(kg)"
                          readOnly={true}
                          style={{
                            opacity: isBigger[name] ? 0.6 : 1,
                            cursor: 'no-drop'
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'weightKg']}
                        label="Weight"
                        rules={[isNotZeroRule]}
                      >
                        <NumericInput
                          onBlur={e => {
                            handleWeightKg(e.target.value, name);
                          }}
                          placeholder="(kg)"
                          status={isBigger[name] ? 'warning' : ''}
                          style={{ opacity: !isBigger[name] ? 0.6 : 1 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'pricePerKg']}
                        label="Price per KG"
                        rules={[isNotZeroRule]}
                      >
                        <NumericInput
                          onBlur={e => {
                            handlePricePerKg(e.target.value, name);
                          }}
                          placeholder="Price per KG"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, 'subtotal']}
                        label="Subtotal"
                        rules={[isNotZeroRule]}
                      >
                        <NumericInput placeholder="Subtotal" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]}>
                    <Col span={8}>
                      <Space.Compact block key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'percentOrFixPlane']}
                          label="Tax"
                          initialValue="percent"
                          // required
                        >
                          <Select
                            defaultValue={'percent'}
                            style={{ minWidth: 150 }}
                            options={[
                              { value: 'percent', label: 'Tax Percent' },
                              { value: 'fixPrice', label: 'Tax Fix' }
                            ]}
                            onSelect={() => handlePercentOrFixSelectPlane(name)}
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          label="Value"
                          {...restField}
                          name={[name, 'percentOrFixValuePlane']}
                          rules={[isNotZeroRule]}
                        >
                          <NumericInput style={{ minWidth: 200 }} />
                        </Form.Item>
                      </Space.Compact>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'otherCostPlane']}
                        label="Other Cost"
                        rules={[isNotZeroRule]}
                      >
                        <NumericInput placeholder="Other Cost" />
                      </Form.Item>
                    </Col>
                    <Col span={8} offset={2}>
                      <Form.Item {...restField} name={[name, 'commentPlane']}>
                        <TextArea
                          maxLength={1000}
                          autoSize={{ minRows: 3, maxRows: 10 }}
                          placeholder="Comment here if needed"
                        ></TextArea>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )
            };
          });

          return (
            <>
              <Button
                disabled={Number(cleanAndParse(form.getFieldValue('cbm'))) <= 0}
                type="primary"
                style={{ margin: 10 }}
                icon={<PlusOutlined />}
                onClick={() => add()}
              >
                Add Country
              </Button>
              <Collapse
                style={{ width: '100%' }}
                expandIconPosition={'end'}
                items={items}
              />

              {Number(cleanAndParse(form.getFieldValue('cbm'))) <= 0 && (
                <Text strong type="danger">
                  Country cannot be added without specifying product CBM.
                </Text>
              )}
            </>
          );
        }}
      </Form.List>
      {/* </Form> */}
    </FormContainer>
  );
};

export default LogisticsForm;
