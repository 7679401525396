import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';

import {
  selectCategoryLimit,
  selectCategoryPage,
  selectCategorySearch
} from './selectors';
import { CategoryState, CategoryEditParams, CategoryAddParams } from './types';

const initialState: CategoryState = {
  category: [],
  filterCategoryData: [],
  attributesByCategoryIds: [],
  categoryById: null,
  attributeByCategoryId: null,
  loading: false,
  loadingAction: false,
  loadingById: false,
  count: 0,
  search: '',
  page: 1,
  limit: 10
};

export const categoryApi = createAsyncThunk(
  'category/categoryApi',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const search = selectCategorySearch()(state);
    const page = selectCategoryPage()(state);
    const limit = selectCategoryLimit()(state);

    const params = {
      page,
      search,
      limit
    };

    try {
      const res = await client.post(`/category/explore`, _, { params });

      return res.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const categoryProductApi = createAsyncThunk(
  'category/categoryProductApi',
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await client.post(`/category/${id}/product`);

      return res.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const categoryEdit = createAsyncThunk(
  'category/categoryEdit',
  async (
    { id, ...data }: CategoryEditParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await client.patch(`/category/${id}`, {
        ...data
      });

      if (res.status === 200) {
        message.success('Category updated successfully');

        dispatch(categoryApi());
      }

      return res.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getCategoryById = createAsyncThunk(
  'category/categoryById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`/category/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);
export const getAttributeByCategoryId = createAsyncThunk(
  'category/attributeByCategoryId',
  async (categoryId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`/category/${categoryId}/attribute`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getAttributesByCategoryIds = createAsyncThunk(
  'category/attributesByCategoryIds',
  async (categoryIds: number[], { rejectWithValue, dispatch }) => {
    const requestData = { category_ids: categoryIds };

    try {
      const response = await client.post(
        `/category/get-attributes`,
        requestData
      );

      return dispatch(setCategoryAttribute(response.data.payload.flat()));
    } catch (error) {
      rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const postCategory = createAsyncThunk(
  'category/postCategory',
  async (
    { parentId, ...data }: CategoryAddParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await client.post('/category', {
        ...data,
        ...(parentId && { parent_id: parentId })
      });

      if (res.status === 200) {
        message.success('Category created successfully');

        dispatch(categoryApi());
      }

      return res.data.payload;
    } catch (err) {
      return rejectWithValue(err as AxiosError<ErrorType>);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/category/${id}`);
      message.success('Category deleted successfully');

      return dispatch(categoryApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setCategoryAttribute: (state, action) => {
      state.attributesByCategoryIds = action.payload.flat();
      state.count = action.payload.count;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(categoryApi.pending, state => {
        state.loading = true;
      })
      .addCase(categoryApi.fulfilled, (state, action) => {
        state.category = action.payload.rows;
        state.loading = false;
      })
      .addCase(categoryApi.rejected, state => {
        state.loading = false;
      })
      .addCase(categoryProductApi.fulfilled, (state, action) => {
        state.category = action.payload.rows;
      })
      .addCase(getCategoryById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.categoryById = action.payload;
        state.loadingById = false;
      })
      .addCase(getCategoryById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(getAttributeByCategoryId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getAttributeByCategoryId.fulfilled, (state, action) => {
        state.attributeByCategoryId = action.payload.attributes;
        state.loadingById = false;
      })
      .addCase(getAttributeByCategoryId.rejected, state => {
        state.loadingById = false;
      })
      .addCase(categoryEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(categoryEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(categoryEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postCategory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postCategory.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postCategory.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteCategory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteCategory.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteCategory.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getAttributesByCategoryIds.pending, state => {
        state.loadingAction = true;
      })
      .addCase(getAttributesByCategoryIds.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(getAttributesByCategoryIds.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit, setCategoryAttribute } =
  categorySlice.actions;
export default categorySlice.reducer;
