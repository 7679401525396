import {
  selectVendorById,
  selectVendorLoadingAction
} from 'app/features/vendor/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  vendorEdit,
  postVendor,
  getVendorById,
  clearVendorData
} from 'app/features/vendor/slice';
import { IVendor } from 'app/features/vendor/types';
import { formats, modules } from 'helpers/reactquill';
import { Controller, useForm } from 'react-hook-form';
import {
  Col,
  message,
  Modal,
  Row,
  Switch,
  Upload,
  Form,
  Input,
  Select,
  UploadProps
} from 'antd';

import DetailsHead from 'components/DetailsHead';
import { RichTextEditor } from '../../components/RichTextEditor';
import { useCustomRequest } from '../../Hook/useCustomRequest';
import { deleteFile } from '../../app/features/files/slice';
import COUNTRIES from '../../constants/countries';
import { getFileUrl } from '../../helpers/utils';
import { VendorDetailsSchema } from './schema';
import { RequiredFieldIndicator } from '../../components/RequiredFieldIndicator';
import { routePath } from '../../constants/routes';

const { Dragger } = Upload;

export interface VendorFormDataType {
  name: string;
  url: string;
  email: string;
  country_id: null | number;
  files?: any[] | null;
  description: string;
  descriptionValue: string;
  is_visible?: boolean;
}

const getFormDefaultValues = (
  vendorById: IVendor | null
): VendorFormDataType => ({
  is_visible: vendorById ? vendorById.is_visible : true,
  name: vendorById?.name ?? '',
  url: '',
  email: '',
  country_id: null,
  files: null,
  description: '',
  descriptionValue: ''
});

const countryDataAdapted = COUNTRIES.map(country => ({
  value: country.id,
  label: country.name
}));

const formFieldOptions = { shouldValidate: true, shouldDirty: true };

const VendorDetails = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const vendorById = useAppSelector(selectVendorById());
  const loadingAction = useAppSelector(selectVendorLoadingAction());

  const customRequest = useCustomRequest({
    url: 'file/create',
    file_type: 'pictures',
    folder: 'vendors',
    vendorId: id || ''
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty, isValid }
  } = useForm<VendorFormDataType>({
    defaultValues: getFormDefaultValues(vendorById),
    resolver: VendorDetailsSchema,
    mode: 'onChange'
  });

  const descriptionText = watch('description');
  const files = watch('files');

  const isSubmitDisabled = !isDirty || !isValid;

  const imageUploadText = !vendorById
    ? 'First Create Vendor...'
    : 'Click or drag image to this area to upload';

  const onClose = () => {
    reset();
    navigate(routePath.getVendors());
    dispatch(clearVendorData());
  };

  const onImageRemove = async (file: any) => {
    Modal.confirm({
      title: 'Confirm',
      content: `Are you sure you want to remove ${file.name}?`,
      onOk: async () => {
        await dispatch(deleteFile(file.id));
        const updatedFileList = files?.filter(item => item.uid !== file.uid);

        setValue('files', updatedFileList, formFieldOptions);
      },
      onCancel: () => setValue('files', files, formFieldOptions)
    });
  };

  const onChangeImageUpload: UploadProps['onChange'] = ({ fileList }) => {
    const newFileList = fileList.map(file => {
      if (file.response) {
        file.url = getFileUrl(
          file.response.data.payload.map((item: { path: string }) => item.path)
        );
        file.uid = file.response.data.payload.map(
          (item: { id: string }) => item.id
        );
      }

      return file;
    });

    setValue('files', newFileList, formFieldOptions);
  };

  const onSubmit = async (
    values: VendorFormDataType,
    e: React.BaseSyntheticEvent | undefined
  ) => {
    e?.preventDefault();
    const { name, is_visible, url, description, email, country_id } = values;

    const data = {
      name,
      description,
      url,
      email,
      country_id,
      is_visible: Boolean(is_visible)
    };

    if (id) {
      const response = await dispatch(vendorEdit({ id, ...data }));

      if (response.payload.code === 200) {
        message.success({
          content: `${name} Vendor Updated successfully`
        });
        navigate(routePath.getVendors());
      } else {
        message.error({
          content: response.payload.message
        });
      }

      return;
    } else {
      const response = await dispatch(postVendor(data));

      if (response.payload.code === 200) {
        message.success({
          content: 'Vendor created successfully.'
        });

        navigate(routePath.getVendors());
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearVendorData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getVendorById(id)).then(res => {
        const { country_id, description, email, is_visible, files, name, url } =
          res.payload ?? {};

        const data = {
          country_id,
          description,
          email,
          is_visible,
          files,
          name,
          url
        };

        setValue('descriptionValue', description);
        Object.entries(data).map(([key, value]) => {
          setValue(key as any, value);
        });
      });
    }
  }, [dispatch, id, setValue]);

  return (
    <form
      style={{ width: 'inherit', paddingBottom: '32px' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DetailsHead
        titleItem={vendorById?.name}
        onClose={onClose}
        id={id}
        isDisabled={isSubmitDisabled}
        loadingAction={loadingAction}
        headTitle={'Vendor'}
      />
      <Row gutter={[10, 4]}>
        <Col span={21}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Form.Item
                layout="vertical"
                label={
                  <RequiredFieldIndicator
                    label="Name"
                    isFilled={Boolean(field.value)}
                  />
                }
                validateStatus={errors.name && 'error'}
                help={errors.name?.message}
              >
                <Input {...field} placeholder="Enter vendor name" />
              </Form.Item>
            )}
          />
        </Col>
        <Col span={3}>
          <Controller
            name="is_visible"
            control={control}
            render={({ field }) => {
              const fieldValue = Boolean(field.value);

              return (
                <Form.Item layout="vertical" label="Availability">
                  <Switch
                    {...field}
                    value={fieldValue}
                    checkedChildren="PUBLIC"
                    unCheckedChildren="PRIVATE"
                    checked={fieldValue}
                  />
                </Form.Item>
              );
            }}
          />
        </Col>
        <Col span={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Form.Item
                layout="vertical"
                label={
                  <RequiredFieldIndicator
                    label="Email"
                    isFilled={Boolean(field.value)}
                  />
                }
                validateStatus={errors.email && 'error'}
                help={errors.email?.message}
              >
                <Input {...field} placeholder="Enter vendor email" />
              </Form.Item>
            )}
          />
        </Col>
        <Col span={12}>
          <Controller
            name="country_id"
            control={control}
            render={({ field }) => (
              <Form.Item
                layout="vertical"
                label={
                  <RequiredFieldIndicator
                    label="Country"
                    isFilled={Boolean(field.value)}
                  />
                }
                validateStatus={errors.country_id && 'error'}
                help={errors.country_id?.message}
              >
                <Select
                  {...field}
                  showSearch
                  placeholder="Select Country"
                  optionLabelProp="label"
                  optionFilterProp="label"
                  options={countryDataAdapted}
                />
              </Form.Item>
            )}
          />
        </Col>
        <Col span={24}>
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <Form.Item
                layout="vertical"
                label={
                  <RequiredFieldIndicator
                    label="Url"
                    isFilled={Boolean(field.value)}
                  />
                }
                validateStatus={errors.url && 'error'}
                help={errors.url?.message}
              >
                <Input {...field} placeholder="Enter vendor url" />
              </Form.Item>
            )}
          />
        </Col>

        <Col span={24}>
          <Controller
            name="files"
            control={control}
            render={({ field }) => (
              <Form.Item label="Upload images" layout="vertical">
                <Dragger
                  {...field}
                  disabled={!vendorById}
                  onChange={onChangeImageUpload}
                  accept="image/*"
                  fileList={files ?? []}
                  customRequest={customRequest}
                  listType="picture"
                  multiple
                  onRemove={onImageRemove}
                >
                  <p className="ant-upload-text">{imageUploadText} </p>
                </Dragger>
              </Form.Item>
            )}
          />
        </Col>
        <Col span={24} style={{ marginTop: '16px' }}>
          <Controller
            name="description"
            control={control}
            rules={{ required: 'Description is required' }}
            render={() => (
              <RichTextEditor
                value={descriptionText}
                isErrorVisible={Boolean(errors.description?.message)}
                errorMessage={errors.description?.message}
                modules={modules}
                formats={formats}
                style={{ height: 200, marginBottom: 20 }}
                placeholder="Description"
                label={
                  <RequiredFieldIndicator
                    label="Description"
                    isFilled={Boolean(descriptionText)}
                  />
                }
                onChange={(inputHtmlText, delta, source, { getText }) => {
                  const pureText = getText().replace('\n', '');

                  setValue('descriptionValue', pureText, formFieldOptions);
                  setValue(
                    'description',
                    pureText ? inputHtmlText : pureText,
                    formFieldOptions
                  );
                }}
              />
            )}
          />
        </Col>
      </Row>
    </form>
  );
};

export default VendorDetails;
