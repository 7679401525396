import { RootState } from 'app/store';

export const selectCountryData = () => (state: RootState) =>
  state.country.country;

export const selectCountryLoading = () => (state: RootState) =>
  state.country.loading;

export const selectCountryPage = () => (state: RootState) => state.country.page;

export const selectCountryCount = () => (state: RootState) =>
  state.country.count;

export const selectCountrySearch = () => (state: RootState) =>
  state.country.search;

export const selectCountryLimit = () => (state: RootState) =>
  state.country.limit;
