import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImgTableWrraper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;
