import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';

import {
  selectAccessoryLimit,
  selectAccessoryPage,
  selectAccessorySearch
} from './selectors';
import {
  AccessoriesState,
  AccessoryEditValues,
  AccessoryPostValues,
  PostProductAccessory,
  ProductAccessoryDelete,
  ProductAccessoryUpdate
} from './types';

const initialState: AccessoriesState = {
  accessories: [],
  accessoryById: null,
  accessoriesByCategoryId: undefined,
  accessoriesByProductId: undefined,
  loading: false,
  loadingAction: false,
  loadingById: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const accessoriesApi = createAsyncThunk(
  'accessories/accessoriesApi',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectAccessoryPage()(state);
    const search = selectAccessorySearch()(state);
    const limit = selectAccessoryLimit()(state);

    try {
      const res = await client.get(`/accessory`, {
        params: {
          search,
          page,
          limit
        }
      });

      return res.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getAccessoryById = createAsyncThunk(
  'accessories/accessoryById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`/accessory/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getAccessoriesByCategoryId = createAsyncThunk(
  'accessories-category/accessoriesByCategoryId',
  async (id: number, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectAccessoryPage()(state);
    const search = selectAccessorySearch()(state);
    const limit = selectAccessoryLimit()(state);

    try {
      const response = await client.get(`/accessory/get_by_category/${id}`, {
        params: {
          search,
          page,
          limit
        }
      });
      // const response = await client.get(`/accessory/get_by_category/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getAccessoriesByProductId = createAsyncThunk(
  'accessories/accessoriesByProductId',
  async (id: number, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectAccessoryPage()(state);
    const search = selectAccessorySearch()(state);
    const limit = selectAccessoryLimit()(state);

    try {
      const response = await client.get(`/accessory/get_by_product/${id}`, {
        params: {
          search,
          page,
          limit
        }
      });

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postAccessories = createAsyncThunk(
  'accessories/postAccessories',
  async (data: AccessoryPostValues, { dispatch, rejectWithValue }) => {
    try {
      await client.post('/accessory', { ...data });
      message.success(`Accessory created successfully`);

      await dispatch(accessoriesApi());
      await dispatch(getAccessoriesByCategoryId(data.accessory_category_id));
    } catch (err) {
      return rejectWithValue(err as AxiosError<ErrorType>);
    }
  }
);

export const editAccessories = createAsyncThunk(
  'accessories/accessoriesEdit',
  async (
    {
      accessoryCategoryId,
      id,
      data
    }: { id: number; data: AccessoryEditValues; accessoryCategoryId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.put(`/accessory/${Number(id)}`, { ...data });
      message.success(`Accessory with id ${id} updated successfully`);

      await dispatch(getAccessoriesByCategoryId(accessoryCategoryId));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postProductAccessories = createAsyncThunk(
  'accessories/postProductAccessories',
  async (
    {
      accessoryCategoryId,
      data
    }: { accessoryCategoryId: number; data: PostProductAccessory },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.post('/accessory/product_accessories', { ...data });
      message.success(`Accessory Added successfully`);

      await dispatch(accessoriesApi());
      await dispatch(getAccessoriesByProductId(data.product_id));
      await dispatch(getAccessoriesByCategoryId(accessoryCategoryId));
    } catch (err) {
      return rejectWithValue(err as AxiosError<ErrorType>);
    }
  }
);

export const updateProductAccessories = createAsyncThunk(
  'accessories/productAccessoriesUpdate',
  async (data: ProductAccessoryUpdate, { dispatch, rejectWithValue }) => {
    const { product_id, accessory_id } = data;

    try {
      await client.put(`/accessory/update_product_accessory`, {
        ...data
      });
      message.success(
        `Accessory price with id ${accessory_id} updated successfully`
      );

      await dispatch(getAccessoriesByProductId(product_id));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteAccessory = createAsyncThunk(
  'accessories/deleteAccessory',
  async (
    {
      accessoryId,
      accessoryCategoryId
    }: { accessoryId: number; accessoryCategoryId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.delete(`/accessory/${accessoryId}`);
      // await dispatch(accessoriesApi());
      await dispatch(getAccessoriesByCategoryId(accessoryCategoryId));
      message.success(`Accessory with id ${accessoryId} deleted successfully`);
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteProductAccessory = createAsyncThunk(
  'accessories/deleteProductAccessory',
  async (
    {
      accessoryCategoryId,
      data
    }: { accessoryCategoryId?: number; data: ProductAccessoryDelete },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.delete(`/accessory/product_accesory`, { data });
      // await dispatch(accessoriesApi());
      await dispatch(getAccessoriesByProductId(data.product_id));
      if (accessoryCategoryId)
        await dispatch(getAccessoriesByCategoryId(accessoryCategoryId));

      message.success(
        `Accessory with id ${data.accessory_id} removed successfully`
      );
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const accessoriesSlice = createSlice({
  name: 'accessories',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(accessoriesApi.pending, state => {
        state.loading = true;
      })
      .addCase(accessoriesApi.fulfilled, (state, action) => {
        state.loading = false;
        state.accessories = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(accessoriesApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getAccessoryById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getAccessoryById.fulfilled, (state, action) => {
        state.loadingById = false;
        state.accessoryById = action.payload;
      })
      .addCase(getAccessoryById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(getAccessoriesByCategoryId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getAccessoriesByCategoryId.fulfilled, (state, action) => {
        state.accessoriesByCategoryId = action.payload;
        state.loadingById = false;
      })
      .addCase(getAccessoriesByCategoryId.rejected, state => {
        state.loadingById = false;
      })
      .addCase(getAccessoriesByProductId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getAccessoriesByProductId.fulfilled, (state, action) => {
        state.accessoriesByProductId = action.payload;
        state.loadingById = false;
      })
      .addCase(getAccessoriesByProductId.rejected, state => {
        state.loadingById = false;
      })
      .addCase(editAccessories.pending, state => {
        state.loadingAction = true;
      })
      .addCase(editAccessories.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(editAccessories.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postAccessories.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postAccessories.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postAccessories.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postProductAccessories.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postProductAccessories.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postProductAccessories.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updateProductAccessories.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateProductAccessories.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateProductAccessories.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteAccessory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteAccessory.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteAccessory.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteProductAccessory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteProductAccessory.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteProductAccessory.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit } = accessoriesSlice.actions;
export default accessoriesSlice.reducer;
