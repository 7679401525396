import { Modal, Typography } from 'antd';

const DeleteModal: React.FC<{
  open: boolean;
  confirmLoading: boolean;
  onOk: () => void;
  onCancel: () => void;
}> = ({ open, confirmLoading, onOk, onCancel }) => {
  return (
    <Modal
      open={open}
      title="Confirm"
      confirmLoading={confirmLoading}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Typography.Paragraph>
        Are you sure you want to delete this item?
      </Typography.Paragraph>
    </Modal>
  );
};

export default DeleteModal;
