import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { RootState } from 'app/store';

import { CbmCountryState } from './types';
import {
  selectCbmCountryLimit,
  selectCbmCountryPage,
  selectCbmCountrySearch
} from '../cbmCountries/selectors';

const initialState: CbmCountryState = {
  loading: false,
  cbmCountries: [],
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const getCbmCountries = createAsyncThunk(
  'country/all-cbm',
  async (payload: { limit?: number }, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectCbmCountryPage()(state);
    const search = selectCbmCountrySearch()(state);
    const limit = selectCbmCountryLimit()(state);

    try {
      const res = await client.get(`/country/get/all-cbm`, {
        params: {
          search,
          page,
          limit: payload.limit || limit
        }
      });

      return res.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

const cbmCountriesSlice = createSlice({
  name: 'cbmCountries',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCbmCountries.pending, state => {
        state.loading = true;
      })
      .addCase(getCbmCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count;
        state.cbmCountries = action.payload.rows;
      })
      .addCase(getCbmCountries.rejected, state => {
        state.loading = false;
      });
  }
});

export const { setPage, setSearch, setLimit } = cbmCountriesSlice.actions;
export default cbmCountriesSlice.reducer;
