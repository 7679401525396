import { RootState } from 'app/store';

export const selectUserPage = () => (state: RootState) => state.users.page;

export const selectUserData = () => (state: RootState) => state.users.users;

export const selectUserCount = () => (state: RootState) => state.users.count;

export const selectUserLoading = () => (state: RootState) =>
  state.users.loading;

export const selectUserLoadingAction = () => (state: RootState) =>
  state.users.loadingAction;

export const selectUserLoadingByID = () => (state: RootState) =>
  state.users.loadingById;

export const selectUserSearch = () => (state: RootState) => state.users.search;

export const selectUserLimit = () => (state: RootState) => state.users.limit;

export const selectUserById = () => (state: RootState) => state.users.userById;

export const selectUserRole = (state: RootState) => state.users.userRole;

export const selectUserLoadingRole = (state: RootState) =>
  state.users.loadingRole;
