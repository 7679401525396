/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICategory } from 'app/features/category/types';

import { AttributeStateItem } from 'pages/ProductDetails/types';

export interface Option {
  halfChecked?: boolean;
  value: number;
  label: string;
  keys: string;
  index: number;
  children?: Option[];
  disabled?: boolean;
  path?: string[];
}

export const flattenCategories = (data: ICategory[]): Option[] =>
  data?.map(element => ({
    label: `${element.title_en} (${element.key})`,
    value: element.id,
    keys: element.key,
    index: element.index,
    path: element.path_en,
    children: element.children && flattenCategories(element.children)
  }));

export const filterCategoryDataByValue = (
  categoryData: ICategory[],
  treeSelectData: Option[]
): ICategory[] => {
  const selectedValues = new Set(treeSelectData?.map(item => item.value));
  const filteredData: ICategory[] = [];

  const filterRecursive = (data: ICategory[]) => {
    return data.reduce((filtered: ICategory[], item: ICategory) => {
      if (selectedValues?.has(item.id)) {
        filteredData.push(item);
      }

      if (item.children) {
        const filteredChildren = filterRecursive(item.children);

        if (filteredChildren.length > 0) {
          filteredData.push({ ...item, children: filteredChildren });
        }
      }

      return filtered;
    }, []);
  };

  filterRecursive(categoryData);

  return filteredData;
};

export const flattenTree = (categoryData: ICategory[]): ICategory[] => {
  const flattenRecursive = (data: ICategory[]): ICategory[] => {
    return data.reduce((acc: ICategory[], item: ICategory) => {
      return item.children
        ? [...acc, item, ...flattenRecursive(item.children)]
        : [...acc, item];
    }, []);
  };

  return flattenRecursive(categoryData);
};

export const hasValue = (attribute: AttributeStateItem): boolean => {
  if (attribute?.value?.type === 'enum') {
    return Boolean(attribute?.value?.enumValue);
  } else if (attribute?.value?.type === 'number') {
    return Boolean(attribute?.value?.numberRange?.min);
  }

  return false;
};

interface ColorsOfProcesses {
  [key: string]: string;
}

const colorsOfProcesses: ColorsOfProcesses = {
  REJECTED_PROCESS: 'red',
  ADMIN_PROCESS: 'cyan',
  LOGISTICS_PROCESS: 'orange',
  PRE_SALES_PROCESS: 'purple',
  SALES_PROCESS: 'green',
  DISCONTINUED_PROCESS: 'default'
};

export const getBackgroundColor = (process: string): string => {
  return colorsOfProcesses[process] || 'gold';
};
