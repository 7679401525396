import styled from 'styled-components';

export const FileDetailsContainer = styled.div`
  font-family: Arial, sans-serif;
  color: #333; /* Adjust text color */
  width: 100%;
`;

export const FileId = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  color: black; /* Default color */

  &:hover {
    color: green; /* Color change on hover */
  }
`;

export const FileName = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const FilePreviewFrame = styled.iframe`
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  width: 100%;
  height: 500px;
`;
export const TruncatedLabel = styled.span`
  // max-width: 120px; /* Adjust the maximum width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;
