import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  ShoppingOutlined,
  ShopOutlined,
  UserAddOutlined,
  BorderOutlined,
  UsbOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { Badge, Menu } from 'antd';
import { selectProductCount } from 'app/features/products/selectors';
import { useAppSelector } from 'app/hooks';
import { Link, useLocation } from 'react-router-dom';

const MenuSider = () => {
  const count = useAppSelector(selectProductCount());

  // location
  const { pathname } = useLocation();

  const menuItems = [
    {
      key: 'users',
      icon: <UserAddOutlined style={{ fontSize: 20 }} />,
      label: <Link to="users">Users</Link>
      // show: isSuper
    },
    {
      key: 'categories',
      icon: <AppstoreOutlined style={{ fontSize: 20 }} />,
      label: <Link to="categories">Categories</Link>
      // show: isSuper || isPresales || isAdmin
    },
    {
      key: 'products',
      icon: (
        <Badge count={count} size="small" overflowCount={999}>
          <ShoppingOutlined style={{ fontSize: 20 }} />
        </Badge>
      ),
      label: <Link to="products">Products</Link>
      // show: isSuper || isPresales || isAdmin || isLogistics
    },
    {
      key: 'cbm',
      icon: <BorderOutlined style={{ fontSize: 20 }} />,
      label: <Link to="cbm">CBM</Link>
      // show: isSuper || isLogistics
    },
    // {
    //   key: 'sections',
    //   icon: <UnorderedListOutlined style={{ fontSize: 20 }} />,
    //   label: <Link to="sections">Sections</Link>,
    //   show: isSuper
    // },
    {
      key: 'attributes',
      icon: <AppstoreAddOutlined style={{ fontSize: 20 }} />,
      label: <Link to="attributes">Attributes</Link>
      // show: isSuper || isAdmin
    },
    {
      key: 'vendors',
      icon: <ShopOutlined style={{ fontSize: 20 }} />,
      label: <Link to="vendors">Vendors</Link>
      // show: isSuper || isPresales
    },
    {
      key: 'accessories',
      icon: <UsbOutlined style={{ fontSize: 20 }} />,
      label: <Link to="accessories">Accessories</Link>
      // show: isSuper || isPresales || isAdmin
    },
    {
      key: 'businesses',
      icon: <TeamOutlined style={{ fontSize: 20 }} />,
      label: <Link to="businesses">Businesses</Link>
      // show: isSuper || isAdmin
    }
  ];

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[pathname.split('/')[1]]}
      defaultSelectedKeys={[pathname.split('/')[1]]}
      items={menuItems.map(item => ({
        icon: item.icon,
        label: item.label,
        key: item.key
      }))}
    ></Menu>
  );
};

export default MenuSider;
