import React from 'react';
import { Form, Input, Row, Col, Select, Typography, Tag, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Rule } from 'antd/es/form';
import { useCustomRequest } from 'Hook/useCustomRequest';

import Loading from 'components/Loading';
import DetailsHead from 'components/DetailsHead';
import { PartnerFormProps } from './types';

const validateMessages = {
  required: "'${label}' is Required!"
};

const BusinessForm: React.FC<PartnerFormProps> = ({
  loadingById,
  BusinessById,
  onSave,
  onClose,
  BusinessId,
  loadingAction,
  countryData,
  onChangeImageUpload,
  fileList,
  onImageRemove,
  createdAt,
  modifiedAt,
  statusColor,
  form
}) => {
  const { Dragger } = Upload;
  // const { Text } = Typography;

  const validator: Rule = {
    min: 2,
    max: 49,
    message: 'Min 2 to Max 50 characters allowed!'
  };

  const customRequest = useCustomRequest({
    url: 'file/createGlobal',
    file_type: 'pictures'
  });

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            onFinish={onSave}
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <DetailsHead
              titleItem={BusinessById?.name}
              onClose={onClose}
              id={BusinessId}
              loadingAction={loadingAction}
              onSubmit={form.submit}
              headTitle={'Business'}
            />
            <Form.Item hidden name="id">
              <Input hidden />
            </Form.Item>
            <Row gutter={[24, 24]}>
              <Col span={6}>
                <Form.Item
                  // rules={[{ required: true }]}
                  label="Set Status"
                  name="status"
                  initialValue={'INACTIVE'}
                >
                  <Select
                    disabled={!BusinessId}
                    options={[
                      { value: 'INACTIVE' },
                      { value: 'ACTIVE', disabled: true },
                      { value: 'ARCHIVED' },
                      { value: 'SUSPENDED' }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'The field is mandatory!'
                    },
                    {
                      // pattern: /^[a-zA-Z0-9\s]+$/, // Allow only alphanumeric characters and spaces
                      pattern: /^[\p{L}0-9\s]+$/u,
                      message: 'Special characters are not allowed'
                    },
                    validator
                  ]}
                  label="Name"
                  name="name"
                >
                  <Input
                    count={{
                      max: 50,
                      strategy: txt => txt.length,
                      exceedFormatter: (txt, { max }) => txt.slice(0, max)
                    }}
                    placeholder="Enter business name"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Country"
                  name="country_arr"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select Country"
                    optionLabelProp="label"
                    optionFilterProp="label"
                    options={countryData.map(country => {
                      return {
                        value: country.id,
                        label: country.name
                      };
                    })}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  // rules={
                  //   [
                  //     // { required: true }
                  //     // { type: 'url' }
                  //   ]
                  // }
                  label="Website"
                  name="url"
                >
                  <Input placeholder="Enter url here" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    { required: true },
                    {
                      type: 'email',
                      message: 'Not a valid email address'
                    }
                  ]}
                  label="Owner email"
                  name="owner_email"
                >
                  <Input placeholder="Enter email here" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Author"
                  name="author"
                >
                  <Input placeholder="Enter author name here" />
                </Form.Item>
              </Col> */}
              <Col span={24}>
                <Form.Item
                  name="files"
                  getValueFromEvent={event => event?.fileList || []}
                >
                  <Dragger
                    onChange={onChangeImageUpload}
                    accept="image/*"
                    fileList={fileList}
                    customRequest={customRequest}
                    listType="picture"
                    onRemove={onImageRemove}
                    multiple={false}
                    maxCount={1}
                  >
                    <p className="ant-upload-text">
                      Click or drag image to this area to upload
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  // rules={[{ required: true }]}
                >
                  <TextArea placeholder="Enter description" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              {createdAt && (
                <Col>
                  <Typography>
                    <pre>Created Date: {createdAt}</pre>
                  </Typography>
                </Col>
              )}
              {modifiedAt && (
                <Col>
                  <Typography>
                    <pre>Modified Date: {modifiedAt}</pre>
                  </Typography>
                </Col>
              )}
              <Col>
                <Typography>
                  <pre>
                    Status:{' '}
                    {BusinessById ? (
                      <Tag color={statusColor}>{BusinessById.status}</Tag>
                    ) : (
                      <Tag color={statusColor}>{'INACTIVE'}</Tag>
                    )}
                  </pre>
                </Typography>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default BusinessForm;
