import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { Modal, message } from 'antd';

import { IPostmanRequestBody, VendorFormState } from './types';
import { getProductById } from '../products/slice';

const initialState: VendorFormState = {
  vendorForm: [],
  loadingAction: false,
  loading: false,
  count: 0
};

export const vendorFormApi = createAsyncThunk(
  'products/vendorFormApi',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`/product/${productId}/vendor`);

      return response.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const postVendorForm = createAsyncThunk(
  'products/postVendorForm',
  async (
    {
      dataVendorForm,
      productId
    }: { dataVendorForm: IPostmanRequestBody; productId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await client.post(`/product/${productId}/vendor`, {
        ...dataVendorForm
      });

      if (response.status === 200) {
        message.success({
          content: 'Vendor Form Created Successfully!'
        });
        await dispatch(vendorFormApi(productId));
        await dispatch(getProductById(productId));
      } else {
        message.error({
          content: 'Failed to Create Vendor Form!'
        });
      }

      return response.data.payload;
    } catch (error) {
      return rejectWithValue(error as AxiosError<ErrorType>);
    }
  }
);

export const editVendorForm = createAsyncThunk(
  'products/editVendorForm',
  async (
    {
      productId,
      dataVendorFormEdit
    }: { productId: number; dataVendorFormEdit: IPostmanRequestBody },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const vendorUpdateResponse = await client.put(
        `/product/vendor/${productId}`,
        {
          ...dataVendorFormEdit
        }
      );

      if (vendorUpdateResponse.status === 200) {
        Modal.success({
          content: (
            <span>
              Vendor Form Was Update Successfully!
              <br />
              Please click on <span style={{ fontWeight: 'bold' }}>
                Submit
              </span>{' '}
              button for updating new price!
            </span>
          )
        });
        await dispatch(vendorFormApi(productId));
        await dispatch(getProductById(productId));
      } else {
        message.error({
          content: 'Failed to Updated Vendor Form!'
        });
      }
    } catch (error) {
      rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);
export const deleteVendorFormItem = createAsyncThunk(
  'products/deleteVendorFormItem',
  async (
    { vendorId, productId }: { vendorId: string | number; productId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.delete(`/product/vendor/${vendorId}`);
      message.success('Vendor Form Item Removed Successfully!');
      await dispatch(vendorFormApi(productId));
      await dispatch(getProductById(productId));
    } catch (error) {
      rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

const vendorFormSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(deleteVendorFormItem.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteVendorFormItem.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteVendorFormItem.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(vendorFormApi.pending, state => {
        state.loading = true;
      })
      .addCase(vendorFormApi.fulfilled, (state, action) => {
        state.vendorForm = action.payload.rows;
        state.loading = false;
      })
      .addCase(vendorFormApi.rejected, state => {
        state.loading = false;
      })
      .addCase(editVendorForm.pending, state => {
        state.loadingAction = true;
      })
      .addCase(editVendorForm.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(editVendorForm.rejected, state => {
        state.loadingAction = false;
      });
  }
});

// export const { setPage, setSearch, setProduct, setLimit } =
//   productsSlice.actions;
export default vendorFormSlice.reducer;
