import { ProductCountry } from 'app/features/country/types';

const COUNTRIES: ProductCountry[] = [
  {
    id: 1,
    name: 'Afghanistan',
    name_official: 'Islamic Republic of Afghanistan'
  },
  {
    id: 2,
    name: 'Åland Islands',
    name_official: 'Åland Islands'
  },
  {
    id: 3,
    name: 'Albania',
    name_official: 'Republic of Albania'
  },
  {
    id: 4,
    name: 'Algeria',
    name_official: "People's Democratic Republic of Algeria"
  },
  {
    id: 5,
    name: 'American Samoa',
    name_official: 'The United States Territory of American Samoa'
  },
  {
    id: 6,
    name: 'Andorra',
    name_official: 'Principality of Andorra'
  },
  {
    id: 7,
    name: 'Angola',
    name_official: 'Republic of Angola'
  },
  {
    id: 8,
    name: 'Anguilla',
    name_official: 'Anguilla'
  },
  {
    id: 9,
    name: 'Antarctica',
    name_official: 'Antarctica'
  },
  {
    id: 10,
    name: 'Antigua and Barbuda',
    name_official: 'Antigua and Barbuda'
  },
  {
    id: 11,
    name: 'Argentina',
    name_official: 'Argentine Republic'
  },
  {
    id: 12,
    name: 'Armenia',
    name_official: 'Republic of Armenia'
  },
  {
    id: 13,
    name: 'Aruba',
    name_official: 'Aruba of the Kingdom of the Netherlands'
  },
  {
    id: 14,
    name: 'Australia',
    name_official: 'Australia'
  },
  {
    id: 15,
    name: 'Austria',
    name_official: 'Republic of Austria'
  },
  {
    id: 16,
    name: 'Azerbaijan',
    name_official: 'Republic of Azerbaijan'
  },
  {
    id: 17,
    name: 'Bahamas',
    name_official: 'Commonwealth of the Bahamas'
  },
  {
    id: 18,
    name: 'Bahrain',
    name_official: 'Kingdom of Bahrain'
  },
  {
    id: 19,
    name: 'Bangladesh',
    name_official: "People's Republic of Bangladesh"
  },
  {
    id: 20,
    name: 'Barbados',
    name_official: 'Barbados'
  },
  {
    id: 21,
    name: 'Belarus',
    name_official: 'Republic of Belarus'
  },
  {
    id: 22,
    name: 'Belgium',
    name_official: 'Kingdom of Belgium'
  },
  {
    id: 23,
    name: 'Belize',
    name_official: 'Belize'
  },
  {
    id: 24,
    name: 'Benin',
    name_official: 'Republic of Benin'
  },
  {
    id: 25,
    name: 'Bermuda',
    name_official: 'Bermudas'
  },
  {
    id: 26,
    name: 'Bhutan',
    name_official: 'Kingdom of Bhutan'
  },
  {
    id: 27,
    name: 'Bolivia (Plurinational State of)',
    name_official: 'Plurinational State of Bolivia'
  },
  {
    id: 28,
    name: 'Bonaire, Saint Eustatius And Saba',
    name_official: 'Bonaire, Saint Eustatius and Saba'
  },
  {
    id: 29,
    name: 'Bosnia and Herzegovina',
    name_official: 'Bosnia and Herzegovina'
  },
  {
    id: 30,
    name: 'Botswana',
    name_official: 'Republic of Botswana'
  },
  {
    id: 31,
    name: 'Bouvet Island',
    name_official: 'Bouvet Island'
  },
  {
    id: 32,
    name: 'Brazil',
    name_official: 'Federative Republic of Brazil'
  },
  {
    id: 33,
    name: 'British Indian Ocean Territory',
    name_official: 'The British Indian Ocean Territory'
  },
  {
    id: 34,
    name: 'Brunei Darussalam',
    name_official: 'Brunei Darussalam'
  },
  {
    id: 35,
    name: 'Bulgaria',
    name_official: 'Republic of Bulgaria'
  },
  {
    id: 36,
    name: 'Burkina Faso',
    name_official: 'Burkina Faso'
  },
  {
    id: 37,
    name: 'Burundi',
    name_official: 'Republic of Burundi'
  },
  {
    id: 38,
    name: 'Cabo Verde',
    name_official: 'Republic of Cabo Verde'
  },
  {
    id: 39,
    name: 'Cambodia',
    name_official: 'Kingdom of Cambodia'
  },
  {
    id: 40,
    name: 'Cameroon',
    name_official: 'Republic of Cameroon'
  },
  {
    id: 41,
    name: 'Canada',
    name_official: 'Canada'
  },
  {
    id: 42,
    name: 'Cayman Islands',
    name_official: 'The Cayman Islands'
  },
  {
    id: 43,
    name: 'Central African Republic',
    name_official: 'Central African Republic'
  },
  {
    id: 44,
    name: 'Chad',
    name_official: 'Republic of Chad'
  },
  {
    id: 45,
    name: 'Chile',
    name_official: 'Republic of Chile'
  },
  {
    id: 46,
    name: 'China',
    name_official: "People's Republic of China"
  },
  {
    id: 47,
    name: 'Christmas Island',
    name_official: 'Territory of Christmas Island'
  },
  {
    id: 48,
    name: 'Cocos (Keeling) Islands',
    name_official: 'Territory of Cocos (Keeling) Islands'
  },
  {
    id: 49,
    name: 'Colombia',
    name_official: 'Republic of Colombia'
  },
  {
    id: 50,
    name: 'Comoros',
    name_official: 'Union of the Comoros'
  },
  {
    id: 51,
    name: 'Congo',
    name_official: 'Republic of the Congo'
  },
  {
    id: 52,
    name: 'Cook Islands',
    name_official: 'Cook Islands'
  },
  {
    id: 53,
    name: 'Costa Rica',
    name_official: 'Republic of Costa Rica'
  },
  {
    id: 54,
    name: "Côte d'Ivoire",
    name_official: "Republic of Côte d'Ivoire"
  },
  {
    id: 55,
    name: 'Croatia',
    name_official: 'Republic of Croatia'
  },
  {
    id: 56,
    name: 'Cuba',
    name_official: 'Republic of Cuba'
  },
  {
    id: 57,
    name: 'Curaçao',
    name_official: 'Curaçao'
  },
  {
    id: 58,
    name: 'Cyprus',
    name_official: 'Republic of Cyprus'
  },
  {
    id: 59,
    name: 'Czechia',
    name_official: 'Czech Republic'
  },
  {
    id: 60,
    name: "Democratic People's Republic of Korea",
    name_official: "Democratic People's Republic of Korea"
  },
  {
    id: 61,
    name: 'Democratic Republic of the Congo',
    name_official: 'Democratic Republic of Congo'
  },
  {
    id: 62,
    name: 'Denmark',
    name_official: 'Kingdom of Denmark'
  },
  {
    id: 63,
    name: 'Djibouti',
    name_official: 'Republic of Djibouti'
  },
  {
    id: 64,
    name: 'Dominica',
    name_official: 'Commonwealth of Dominica'
  },
  {
    id: 65,
    name: 'Dominican Republic',
    name_official: 'Dominican Republic'
  },
  {
    id: 66,
    name: 'Ecuador',
    name_official: 'Republic of Ecuador'
  },
  {
    id: 67,
    name: 'Egypt',
    name_official: 'Arab Republic of Egypt'
  },
  {
    id: 68,
    name: 'El Salvador',
    name_official: 'Republic of El Salvador'
  },
  {
    id: 69,
    name: 'Equatorial Guinea',
    name_official: 'Republic of Equatorial Guinea'
  },
  {
    id: 70,
    name: 'Eritrea',
    name_official: 'State of Eritrea'
  },
  {
    id: 71,
    name: 'Estonia',
    name_official: 'Republic of Estonia'
  },
  {
    id: 72,
    name: 'Ethiopia',
    name_official: 'Federal Democratic Republic of Ethiopia'
  },
  {
    id: 73,
    name: 'European Union',
    name_official: 'European Union'
  },
  {
    id: 74,
    name: 'Falkland Islands (Malvinas)',
    name_official: 'Falkland Islands'
  },
  {
    id: 75,
    name: 'Faroe Islands (Associate Member)',
    name_official: 'Faroe Islands'
  },
  {
    id: 76,
    name: 'Fiji',
    name_official: 'Republic of Fiji'
  },
  {
    id: 77,
    name: 'Finland',
    name_official: 'Republic of Finland'
  },
  {
    id: 78,
    name: 'France',
    name_official: 'French Republic'
  },
  {
    id: 79,
    name: 'French Guiana',
    name_official: 'French Guiana'
  },
  {
    id: 80,
    name: 'French Polynesia',
    name_official: 'Territory of French Polynesia'
  },
  {
    id: 81,
    name: 'French Southern and Antarctic Lands',
    name_official: 'Territory of the French Southern and Antarctic Lands'
  },
  {
    id: 82,
    name: 'Gabon',
    name_official: 'Gabonese Republic'
  },
  {
    id: 83,
    name: 'Gambia',
    name_official: 'Islamic Republic of the Gambia'
  },
  {
    id: 84,
    name: 'Georgia',
    name_official: 'Georgia'
  },
  {
    id: 85,
    name: 'Germany',
    name_official: 'Federal Republic of Germany'
  },
  {
    id: 86,
    name: 'Ghana',
    name_official: 'Republic of Ghana'
  },
  {
    id: 87,
    name: 'Gibraltar',
    name_official: 'Gibraltar'
  },
  {
    id: 88,
    name: 'Greece',
    name_official: 'Hellenic Republic'
  },
  {
    id: 89,
    name: 'Greenland',
    name_official: 'Greenland'
  },
  {
    id: 90,
    name: 'Grenada',
    name_official: 'Grenada'
  },
  {
    id: 91,
    name: 'Guadeloupe',
    name_official: 'Department of Guadeloupe'
  },
  {
    id: 92,
    name: 'Guam',
    name_official: 'Territory of Guam'
  },
  {
    id: 93,
    name: 'Guatemala',
    name_official: 'Republic of Guatemala'
  },
  {
    id: 94,
    name: 'Guernsey',
    name_official: 'Bailiwick of Guernsey'
  },
  {
    id: 95,
    name: 'Guinea',
    name_official: 'Republic of Guinea'
  },
  {
    id: 96,
    name: 'Guinea-Bissau',
    name_official: 'Republic of Guinea-Bissau'
  },
  {
    id: 97,
    name: 'Guyana',
    name_official: 'Republic of Guyana'
  },
  {
    id: 98,
    name: 'Haiti',
    name_official: 'Republic of Haiti'
  },
  {
    id: 99,
    name: 'Heard Island And McDonald Islands',
    name_official: 'Heard and McDonald Islands'
  },
  {
    id: 100,
    name: 'Holy See',
    name_official: 'Holy see'
  },
  {
    id: 101,
    name: 'Honduras',
    name_official: 'Republic of Honduras'
  },
  {
    id: 102,
    name: 'Hong Kong',
    name_official:
      "Hong Kong Special Administrative Region of the People's Republic"
  },
  {
    id: 103,
    name: 'Hungary',
    name_official: 'Hungary'
  },
  {
    id: 104,
    name: 'Iceland',
    name_official: 'Republic of Iceland'
  },
  {
    id: 105,
    name: 'India',
    name_official: 'Republic of India'
  },
  {
    id: 106,
    name: 'Indonesia',
    name_official: 'Republic of Indonesia'
  },
  {
    id: 107,
    name: 'Iran (Islamic Republic of)',
    name_official: 'Islamic Republic of Iran'
  },
  {
    id: 108,
    name: 'Iraq',
    name_official: 'Republic of Iraq'
  },
  {
    id: 109,
    name: 'Ireland',
    name_official: 'Ireland'
  },
  {
    id: 110,
    name: 'Isle of Man',
    name_official: 'The Isle of Man'
  },
  {
    id: 111,
    name: 'Israel',
    name_official: 'State of Israel'
  },
  {
    id: 112,
    name: 'Italy',
    name_official: 'Republic of Italy'
  },
  {
    id: 113,
    name: 'Jamaica',
    name_official: 'Jamaica'
  },
  {
    id: 114,
    name: 'Japan',
    name_official: 'Japan'
  },
  {
    id: 115,
    name: 'Jersey',
    name_official: 'Bailiwick of Jersey'
  },
  {
    id: 116,
    name: 'Jordan',
    name_official: 'Hashemite Kingdom of Jordan'
  },
  {
    id: 117,
    name: 'Kazakhstan',
    name_official: 'Republic of Kazakhstan'
  },
  {
    id: 118,
    name: 'Kenya',
    name_official: 'Republic of Kenya'
  },
  {
    id: 119,
    name: 'Kiribati',
    name_official: 'Republic of Kiribati'
  },
  {
    id: 120,
    name: 'Kuwait',
    name_official: 'State of Kuwait'
  },
  {
    id: 121,
    name: 'Kyrgyzstan',
    name_official: 'Kyrgyz Republic'
  },
  {
    id: 122,
    name: "Lao People's Democratic Republic",
    name_official: "Lao People's Democratic Republic"
  },
  {
    id: 123,
    name: 'Latvia',
    name_official: 'Republic of Latvia'
  },
  {
    id: 124,
    name: 'Lebanon',
    name_official: 'Lebanese Republic'
  },
  {
    id: 125,
    name: 'Lesotho',
    name_official: 'Kingdom of Lesotho'
  },
  {
    id: 126,
    name: 'Liberia',
    name_official: 'Republic of Liberia'
  },
  {
    id: 127,
    name: 'Libya',
    name_official: 'Libya'
  },
  {
    id: 128,
    name: 'Liechtenstein',
    name_official: 'Principality of Liechtenstein'
  },
  {
    id: 129,
    name: 'Lithuania',
    name_official: 'Republic of Lithuania'
  },
  {
    id: 130,
    name: 'Luxembourg',
    name_official: 'Grand Duchy of Luxembourg'
  },
  {
    id: 131,
    name: 'Macao',
    name_official: 'Macau Special Administrative Region'
  },
  {
    id: 132,
    name: 'Madagascar',
    name_official: 'Republic of Madagascar'
  },
  {
    id: 133,
    name: 'Malawi',
    name_official: 'Republic of Malawi'
  },
  {
    id: 134,
    name: 'Malaysia',
    name_official: 'Malaysia'
  },
  {
    id: 135,
    name: 'Maldives',
    name_official: 'Republic of Maldives'
  },
  {
    id: 136,
    name: 'Mali',
    name_official: 'Republic of Mali'
  },
  {
    id: 137,
    name: 'Malta',
    name_official: 'Republic of Malta'
  },
  {
    id: 138,
    name: 'Marshall Islands',
    name_official: 'Republic of the Marshall Islands'
  },
  {
    id: 139,
    name: 'Martinique',
    name_official: 'Department of Martinique'
  },
  {
    id: 140,
    name: 'Mauritania',
    name_official: 'Islamic Republic of Mauritania'
  },
  {
    id: 141,
    name: 'Mauritius',
    name_official: 'Republic of Mauritius'
  },
  {
    id: 142,
    name: 'Mayotte',
    name_official: 'Overseas Department of Mayotte'
  },
  {
    id: 143,
    name: 'Mexico',
    name_official: 'United Mexican States'
  },
  {
    id: 144,
    name: 'Micronesia (Federated States of)',
    name_official: 'Federated States of Micronesia'
  },
  {
    id: 145,
    name: 'Monaco',
    name_official: 'Principality of Monaco'
  },
  {
    id: 146,
    name: 'Mongolia',
    name_official: 'Mongolia'
  },
  {
    id: 147,
    name: 'Montenegro',
    name_official: 'Montenegro'
  },
  {
    id: 148,
    name: 'Montserrat',
    name_official: 'Montserrat'
  },
  {
    id: 149,
    name: 'Morocco',
    name_official: 'Kingdom of Morocco'
  },
  {
    id: 150,
    name: 'Mozambique',
    name_official: 'Republic of Mozambique'
  },
  {
    id: 151,
    name: 'Myanmar',
    name_official: 'Republic of Union of Myanmar'
  },
  {
    id: 152,
    name: 'Namibia',
    name_official: 'Republic of Namibia'
  },
  {
    id: 153,
    name: 'Nauru',
    name_official: 'Republic of Nauru'
  },
  {
    id: 154,
    name: 'Nepal',
    name_official: 'Federal Democratic Republic of Nepal'
  },
  {
    id: 155,
    name: 'Netherlands',
    name_official: 'Kingdom of Netherlands'
  },
  {
    id: 156,
    name: 'New Caledonia',
    name_official: 'Territory of New Caledonia and Dependencies'
  },
  {
    id: 157,
    name: 'New Zealand',
    name_official: 'New Zealand'
  },
  {
    id: 158,
    name: 'Nicaragua',
    name_official: 'Republic of Nicaragua'
  },
  {
    id: 159,
    name: 'Niger',
    name_official: 'Republic of Niger'
  },
  {
    id: 160,
    name: 'Nigeria',
    name_official: 'Federal Republic of Nigeria'
  },
  {
    id: 161,
    name: 'Niue',
    name_official: 'Niue'
  },
  {
    id: 162,
    name: 'Norfolk Island',
    name_official: 'Norfolk Islands'
  },
  {
    id: 163,
    name: 'Northern Mariana Islands',
    name_official: 'Commonwealth of the Northern Mariana Islands'
  },
  {
    id: 164,
    name: 'Norway',
    name_official: 'Kingdom of Norway'
  },
  {
    id: 165,
    name: 'Oman',
    name_official: 'Sultanate of Oman'
  },
  {
    id: 166,
    name: 'Pakistan',
    name_official: 'Islamic Republic of Pakistan'
  },
  {
    id: 167,
    name: 'Palau',
    name_official: 'Republic of Palau'
  },
  {
    id: 168,
    name: 'Palestinian Territory, Occupied',
    name_official: 'Occupied Palestinian Territory'
  },
  {
    id: 169,
    name: 'Panama',
    name_official: 'Republic of Panama'
  },
  {
    id: 170,
    name: 'Papua New Guinea',
    name_official: 'Independent State of Papua New Guinea'
  },
  {
    id: 171,
    name: 'Paraguay',
    name_official: 'Republic of Paraguay'
  },
  {
    id: 172,
    name: 'Peru',
    name_official: 'Republic of Peru'
  },
  {
    id: 173,
    name: 'Philippines',
    name_official: 'Republic of Philippines'
  },
  {
    id: 174,
    name: 'Pitcairn Islands',
    name_official: 'Pitcairn Group of Islands'
  },
  {
    id: 175,
    name: 'Poland',
    name_official: 'Republic of Poland'
  },
  {
    id: 176,
    name: 'Portugal',
    name_official: 'Portuguese Republic'
  },
  {
    id: 177,
    name: 'Puerto Rico',
    name_official: 'Commonwealth of Puerto Rico'
  },
  {
    id: 178,
    name: 'Qatar',
    name_official: 'State of Qatar'
  },
  {
    id: 179,
    name: 'Republic of Korea',
    name_official: 'Republic of Korea'
  },
  {
    id: 180,
    name: 'Republic of Moldova',
    name_official: 'Republic of Moldova'
  },
  {
    id: 181,
    name: 'Réunion',
    name_official: 'Department of Reunion'
  },
  {
    id: 182,
    name: 'Romania',
    name_official: 'Romania'
  },
  {
    id: 183,
    name: 'Russian Federation',
    name_official: 'Russian Federation'
  },
  {
    id: 184,
    name: 'Rwanda',
    name_official: 'Republic of Rwanda'
  },
  {
    id: 185,
    name: 'Saint Barthélemy',
    name_official: 'Territorial collectivity of Saint Barthélemy'
  },
  {
    id: 186,
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    name_official: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    id: 187,
    name: 'Saint Kitts and Nevis',
    name_official: 'Saint Kitts and Nevis'
  },
  {
    id: 188,
    name: 'Saint Lucia',
    name_official: 'Saint Lucia'
  },
  {
    id: 189,
    name: 'Saint Martin',
    name_official: 'Saint Martin'
  },
  {
    id: 190,
    name: 'Saint Pierre and Miquelon',
    name_official: 'Saint Pierre and Miquelon'
  },
  {
    id: 191,
    name: 'Saint Vincent and the Grenadines',
    name_official: 'Saint Vincent and the Grenadines'
  },
  {
    id: 192,
    name: 'Samoa',
    name_official: 'Independent State of Samoa'
  },
  {
    id: 193,
    name: 'San Marino',
    name_official: 'Republic of San Marino'
  },
  {
    id: 194,
    name: 'Sao Tome and Principe',
    name_official: 'Democratic Republic of Sao Tome and Principe'
  },
  {
    id: 195,
    name: 'Saudi Arabia',
    name_official: 'Kingdom of Saudi Arabia'
  },
  {
    id: 196,
    name: 'Senegal',
    name_official: 'Republic of Senegal'
  },
  {
    id: 197,
    name: 'Serbia',
    name_official: 'Republic of Serbia'
  },
  {
    id: 198,
    name: 'Seychelles',
    name_official: 'Republic of Seychelles'
  },
  {
    id: 199,
    name: 'Sierra Leone',
    name_official: 'Republic of Sierra Leone'
  },
  {
    id: 200,
    name: 'Singapore',
    name_official: 'Republic of Singapore'
  },
  {
    id: 201,
    name: 'Sint Maarten',
    name_official: 'Sint Maarten'
  },
  {
    id: 202,
    name: 'Slovakia',
    name_official: 'Slovak Republic'
  },
  {
    id: 203,
    name: 'Slovenia',
    name_official: 'Republic of Slovenia'
  },
  {
    id: 204,
    name: 'Solomon Islands',
    name_official: 'Solomon Islands'
  },
  {
    id: 205,
    name: 'Somalia',
    name_official: 'Federal Republic of Somalia'
  },
  {
    id: 206,
    name: 'South Africa',
    name_official: 'Republic of South Africa'
  },
  {
    id: 207,
    name: 'South Georgia and the South Sandwich Islands',
    name_official: 'South Georgia and the South Sandwich Islands'
  },
  {
    id: 208,
    name: 'South Sudan',
    name_official: 'Republic of South Sudan'
  },
  {
    id: 209,
    name: 'Spain',
    name_official: 'Kingdom of Spain'
  },
  {
    id: 210,
    name: 'Sri Lanka',
    name_official: 'Democratic Socialist Republic of Sri Lanka'
  },
  {
    id: 211,
    name: 'Sudan',
    name_official: 'Republic of Sudan'
  },
  {
    id: 212,
    name: 'Suriname',
    name_official: 'Republic of Suriname'
  },
  {
    id: 213,
    name: 'Svalbard and Jan Mayen',
    name_official: 'Svalbard and Jan Mayen'
  },
  {
    id: 214,
    name: 'Swaziland',
    name_official: 'Kingdom of Swaziland'
  },
  {
    id: 215,
    name: 'Sweden',
    name_official: 'Kingdom of Sweden'
  },
  {
    id: 216,
    name: 'Switzerland',
    name_official: 'Swiss Confederation'
  },
  {
    id: 217,
    name: 'Syrian Arab Republic',
    name_official: 'Syrian Arab Republic'
  },
  {
    id: 218,
    name: 'Taiwan',
    name_official: 'Republic of China'
  },
  {
    id: 219,
    name: 'Tajikistan',
    name_official: 'Republic of Tajikistan'
  },
  {
    id: 220,
    name: 'Thailand',
    name_official: 'Kingdom of Thailand'
  },
  {
    id: 221,
    name: 'The former Yugoslav Republic of Macedonia',
    name_official: 'The former Yugoslav Republic of Macedonia'
  },
  {
    id: 222,
    name: 'Timor-Leste',
    name_official: 'Democratic Republic of Timor-Leste'
  },
  {
    id: 223,
    name: 'Togo',
    name_official: 'Togolese Republic'
  },
  {
    id: 224,
    name: 'Tokelau (Associate Member)',
    name_official: 'Tokelau'
  },
  {
    id: 225,
    name: 'Tonga',
    name_official: 'Kingdom of Tonga'
  },
  {
    id: 226,
    name: 'Trinidad and Tobago',
    name_official: 'Republic of Trinidad and Tobago'
  },
  {
    id: 227,
    name: 'Tunisia',
    name_official: 'Republic of Tunisia'
  },
  {
    id: 228,
    name: 'Turkey',
    name_official: 'Republic of Turkey'
  },
  {
    id: 229,
    name: 'Turkmenistan',
    name_official: 'Turkmenistan'
  },
  {
    id: 230,
    name: 'Turks and Caicos Islands',
    name_official: 'Turks and Caicos Islands'
  },
  {
    id: 231,
    name: 'Tuvalu',
    name_official: 'Tuvalu'
  },
  {
    id: 232,
    name: 'Uganda',
    name_official: 'Republic of Uganda'
  },
  {
    id: 233,
    name: 'Ukraine',
    name_official: 'Ukraine'
  },
  {
    id: 234,
    name: 'United Arab Emirates',
    name_official: 'United Arab Emirates'
  },
  {
    id: 235,
    name: 'United Kingdom',
    name_official: 'United Kingdom of Great Britain and Northern Ireland'
  },
  {
    id: 236,
    name: 'United Republic of Tanzania',
    name_official: 'United Republic of Tanzania'
  },
  {
    id: 237,
    name: 'United States Minor Outlying Islands',
    name_official: 'United States Minor Outlying Islands'
  },
  {
    id: 238,
    name: 'United States of America',
    name_official: 'United States of America'
  },
  {
    id: 239,
    name: 'United States Virgin Islands',
    name_official: 'Virgin Islands of the United States'
  },
  {
    id: 240,
    name: 'Uruguay',
    name_official: 'Eastern Republic of Uruguay'
  },
  {
    id: 241,
    name: 'Uzbekistan',
    name_official: 'Republic of Uzbekistan'
  },
  {
    id: 242,
    name: 'Vanuatu',
    name_official: 'Republic of Vanuatu'
  },
  {
    id: 243,
    name: 'Venezuela (Bolivarian Republic of)',
    name_official: 'Bolivarian Republic of Venezuela'
  },
  {
    id: 244,
    name: 'Viet Nam',
    name_official: 'Socialist Republic of Viet Nam'
  },
  {
    id: 245,
    name: 'Virgin Islands',
    name_official: 'British Virgin Islands'
  },
  {
    id: 246,
    name: 'Wallis and Futuna',
    name_official: 'Territory of the Wallis and Futuna Islands'
  },
  {
    id: 247,
    name: 'Western Sahara',
    name_official: 'Western Sahara'
  },
  {
    id: 248,
    name: 'Yemen',
    name_official: 'Republic of Yemen'
  },
  {
    id: 249,
    name: 'Zambia',
    name_official: 'Republic of Zambia'
  },
  {
    id: 250,
    name: 'Zimbabwe',
    name_official: 'Republic of Zimbabwe'
  }
];

export default COUNTRIES;
