import { Flex, Switch, Typography } from 'antd';
import { FC } from 'react';

import {
  AddButton,
  ContentTopTitle,
  SearchStyled,
  AddButtonWrapper,
  ContentTopWrapper
} from './styled';
import { IContentTopProps } from './types';

const ContentTop: FC<IContentTopProps> = ({
  title,
  categoryTitle,
  placeholder,
  onClickAdd,
  buttonText,
  onChange,
  searchComponent,
  search,
  add,
  onSwitch
}) => {
  return (
    <ContentTopWrapper>
      <ContentTopTitle>
        <Typography.Title>{title}</Typography.Title>
        <Flex align={'center'} gap={'12px'}>
          {Boolean(onSwitch) && (
            <Switch
              style={{ flexShrink: 0 }}
              onChange={onSwitch}
              checkedChildren="Search by Category"
              unCheckedChildren="Search by Attributes"
            />
          )}
          {searchComponent && (
            <SearchStyled
              onChange={onChange}
              size="large"
              value={search}
              placeholder={placeholder}
              enterButton
            />
          )}
        </Flex>
      </ContentTopTitle>

      {add && (
        <AddButtonWrapper>
          <Typography.Title level={2}>{categoryTitle || ''}</Typography.Title>

          <AddButton onClick={onClickAdd} type="primary">
            {buttonText}
          </AddButton>
        </AddButtonWrapper>
      )}
    </ContentTopWrapper>
  );
};

export default ContentTop;
