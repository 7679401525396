import {
  attributesEdit,
  getAttributeById,
  postAttributes,
  setAtributeById
} from 'app/features/attributes/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  selectAttributeById,
  selectAttributeLoadingAction,
  selectAttributeLoadingById
} from 'app/features/attributes/selectors';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  TreeSelect
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  IAttributeEditValues,
  IAttributePostValues,
  IAttributeValues,
  IAttributes
} from 'app/features/attributes/types';
import { categoryApi } from 'app/features/category/slice';
import { selectCategoryData } from 'app/features/category/selectors';
import { flattenCategories } from 'helpers/product';
import { handleBackendValidationError } from 'helpers/errors';
import { hasDuplicates } from 'helpers/utils';

import DetailsHead from 'components/DetailsHead';
import Loading from 'components/Loading';

const validateMessages = {
  required: "'${label}' is Required!"
};

const AttributesDetails = () => {
  //states
  const [isChecked, setIsChecked] = useState(true);
  //form
  const [form] = Form.useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const attributeType: IAttributes['type'] = Form.useWatch('type', form);
  const attributeUnit: string = Form.useWatch('unit_en', form);

  const { id } = useParams();
  const attributeId = Number(id) || '';
  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const attributeById = useAppSelector(selectAttributeById());
  const loadingById = useAppSelector(selectAttributeLoadingById());
  const loadingAction = useAppSelector(selectAttributeLoadingAction());
  const categoryData = useAppSelector(selectCategoryData());
  useEffect(() => {
    dispatch(categoryApi());

    if (attributeId) {
      dispatch(getAttributeById(attributeId));
    }
  }, [dispatch, attributeId]);

  useEffect(() => {
    if (id && attributeById && attributeId) {
      const data = {
        description_en: attributeById.description_en,
        categories: attributeById.categories.map(item => ({
          label: item.title_en,
          value: item.id,
          key: item.key
        })),
        unit_en: attributeById.unit_en,
        filter: attributeById.filter,
        key: attributeById.key,
        type: attributeById.type,
        step: attributeById.step
      };

      setIsChecked(attributeById.filter);

      if (attributeById.type === 'number') {
        form.setFieldsValue({
          ...data,
          min: attributeById?.values_en?.min,
          max: attributeById?.values_en?.max
        });
      } else {
        form.setFieldsValue({
          ...data,
          values: attributeById.values_en?.values.map(value => ({
            value_en: value
          }))
        });
      }
    }
  }, [attributeById, form, id, attributeId]);

  const onClose = () => {
    form.resetFields();
    dispatch(setAtributeById(null));
    navigate(-1);
  };

  const onSave = async (data: IAttributeValues) => {
    if (!isSubmitted) {
      const categoryIds = data.categories.map(category => category.value);
      const categoryKeys =
        data.categories?.map(item =>
          'halfChecked' in item
            ? `${item.label.match(/\(([^)]+)\)/)?.[1] || ''}`
            : item.keys
        ) || [];

      const categoryKeysString = categoryKeys.join('; ');

      const payload = {
        description_en: data.description_en,
        ...(data.unit_en && { unit_en: data.unit_en }),
        ...(data.step && { step: data.step }),
        filter: isChecked,
        type: data.type,
        categories: categoryIds,
        category_keys: categoryKeysString,
        values_en:
          data.type === 'enum'
            ? {
                values: form
                  .getFieldValue('values')
                  .map((item: { value_en: string }) => {
                    return {
                      value: item.value_en
                    };
                  })
                  .map((item: { value: string }) => item.value) as string[]
              }
            : {
                min: `${form.getFieldValue('min')}`,
                max: `${form.getFieldValue('max')}`
              }
      };

      if (attributeById && attributeId) {
        const { category_keys, ...postData } = payload;

        const res = await dispatch(
          attributesEdit({
            id: attributeId,
            ...postData
          } as IAttributeEditValues)
        );

        if (res.meta.requestStatus === 'fulfilled') {
          navigate('/attributes');
        } else {
          handleBackendValidationError(res.payload.response.data.message, res);
        }
      } else {
        const res = await dispatch(
          postAttributes({ ...payload } as IAttributePostValues)
        );

        if (res.meta.requestStatus === 'fulfilled') {
          navigate('/attributes');
        } else {
          handleBackendValidationError(res.payload.response.data.message, res);
        }
      }
    }
  };

  const hendleValueBlur = (value: string, name: number) => {
    const attributes: { value_en: string }[] = form.getFieldValue(['values']);

    const currentValue = String(value).trim();

    if (!currentValue) {
      return;
    }

    const values = attributes.map(item => item?.value_en);

    const initialValue =
      attributeById?.type === 'enum' && attributeById.values_en?.values;

    const isInitialValue =
      Array.isArray(initialValue) &&
      initialValue.every(val => values.includes(val));

    if (hasDuplicates(values) && !isInitialValue) {
      form.setFields([
        {
          name: ['values', name, 'value_en'],
          errors: ['Values must be unique']
        }
      ]);
    } else {
      form.setFields([]);
    }
  };

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            onFinish={data => {
              onSave(data);
              setIsSubmitted(true);
            }}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <DetailsHead
              titleItem={`Key ${attributeById?.key}`}
              onClose={onClose}
              id={id}
              onSubmit={form.submit}
              loadingAction={loadingAction}
              headTitle={'Attribute'}
            />
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item name="filter" label="Filter">
                  <Switch checked={isChecked} onChange={e => setIsChecked(e)} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Category"
                  name="categories"
                  rules={[{ required: true }]}
                >
                  <TreeSelect
                    treeData={flattenCategories(categoryData)}
                    placeholder="Please select Category"
                    multiple
                    showSearch={false}
                    treeCheckable
                    treeCheckStrictly
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description_en"
                  label="Description"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter description in English" />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="type"
                  label="Type"
                >
                  <Select
                    placeholder="Please input a Type"
                    options={[
                      {
                        value: 'enum',
                        label: 'Enum'
                      },
                      {
                        value: 'number',
                        label: 'Number'
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name="unit_en" label="Unit">
                  <Input placeholder="Enter unit in English" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="step" label="Step">
                  <Input placeholder="Enter step" type="number" />
                </Form.Item>
              </Col>
              {attributeType ? (
                attributeType === 'number' ? (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name="min"
                        rules={[{ required: true }]}
                        label="Min Value"
                      >
                        <InputNumber
                          placeholder="Please input a Min Value"
                          style={{ width: '100%' }}
                          addonBefore={attributeUnit}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="max"
                        rules={[{ required: true }]}
                        label="Max Value"
                      >
                        <InputNumber
                          placeholder="Please input a Max Value"
                          style={{ width: '100%' }}
                          addonBefore={attributeUnit}
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <Col span={24}>
                    <Row gutter={[24, 24]}>
                      <Form.List
                        initialValue={[{ value_en: '' }]}
                        name="values"
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name }, index, arr) => (
                              <Col key={key} span={8}>
                                <Form.Item
                                  rules={[{ required: true }]}
                                  label="English Attribute Value"
                                  style={{ marginBottom: 0 }}
                                  name={[name, 'value_en']}
                                >
                                  <Input
                                    onBlur={e =>
                                      hendleValueBlur(e.target.value, name)
                                    }
                                    addonBefore={attributeUnit}
                                    placeholder="Enter Attribute Value in English"
                                  />
                                </Form.Item>
                                <Row
                                  style={{ marginTop: '8px' }}
                                  gutter={[12, 0]}
                                >
                                  <Col span={12}>
                                    {arr.length > 1 && (
                                      <Button
                                        size="small"
                                        danger
                                        block
                                        onClick={() => {
                                          remove(name);
                                        }}
                                      >
                                        <MinusCircleOutlined />
                                      </Button>
                                    )}
                                  </Col>
                                  <Col span={12}>
                                    {index === arr.length - 1 && (
                                      <Button
                                        size="small"
                                        block
                                        onClick={() => {
                                          add();
                                        }}
                                      >
                                        <PlusOutlined />
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </Col>
                )
              ) : null}
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default AttributesDetails;
