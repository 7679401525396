import { Input } from 'antd';
import styled from 'styled-components';

export const SelectWrraper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OtherFormat = styled(Input)`
  .ant-input-group-addon {
    background-color: 'white';
    font-size: 16px;
  }
  .ant-input {
    border-left: 0;
  }
  .ant-input-lg {
    padding: 7px 11px 7px 0px;
  }
`;
