import { RootState } from 'app/store';

export const selectSectionPage = () => (state: RootState) => state.section.page;

export const selectSectionData = () => (state: RootState) =>
  state.section.section;

export const selectSectionCount = () => (state: RootState) =>
  state.section.count;

export const selectSectionLoading = () => (state: RootState) =>
  state.section.loading;

export const selectSectionLoadingById = () => (state: RootState) =>
  state.section.loadingById;

export const selectSectionById = () => (state: RootState) =>
  state.section.sectionById;

export const selectSectionLoadingAction = () => (state: RootState) =>
  state.section.loadingAction;

export const selectSectionSearch = () => (state: RootState) =>
  state.section.search;

export const selectSectionLimit = () => (state: RootState) =>
  state.section.limit;
