import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client, setClientToken } from 'api';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { AxiosError } from 'axios';
// import { StartSessionExpirationTimer } from 'helpers/sessionExpirationTimer';
import { redirectToLogin } from 'helpers/redirectHelper';

import { ILoginPost, AuthState } from './types';

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  user: null,
  loading: false
};

export const postLogin = createAsyncThunk(
  'auth/postLogin',
  async (values: ILoginPost, { rejectWithValue }) => {
    try {
      const res = await client.post('/auth', values);

      const accessToken = res.data.payload.access_token;
      const refreshToken = res.data.payload.refresh_token;

      setClientToken(accessToken, refreshToken);
      localStorage.setItem('token', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      return res.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const refreshAccessToken = createAsyncThunk(
  'auth/refreshAccessToken',
  async (refreshToken: string, { rejectWithValue }) => {
    try {
      const res = await client.post('/auth/refresh', {
        refresh_token: refreshToken
      });

      const newAccessToken = res.data.payload.access_token;
      const newRefreshToken = res.data.payload.refresh_token;

      setClientToken(newAccessToken, newRefreshToken);
      localStorage.setItem('token', newAccessToken);
      localStorage.setItem('refreshToken', newRefreshToken);

      return { newAccessToken, newRefreshToken };
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const logOut = createAsyncThunk(
  'auth/logOut',
  async (_, { rejectWithValue }) => {
    try {
      await client.post('/auth/logout');
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      redirectToLogin();
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postLogin.pending, state => {
        state.loading = true;
      })
      .addCase(postLogin.fulfilled, state => {
        state.loading = false;
        state.isLoggedIn = true;
      })
      .addCase(postLogin.rejected, state => {
        state.loading = false;
      })
      .addCase(logOut.fulfilled, state => {
        state.isLoggedIn = false;
      })
      .addCase(refreshAccessToken.pending, state => {
        state.loading = true;
      })
      .addCase(refreshAccessToken.fulfilled, state => {
        state.loading = false;
        state.isLoggedIn = true;
      })
      .addCase(refreshAccessToken.rejected, state => {
        state.loading = false;
      });
  }
});

export default authSlice.reducer;
