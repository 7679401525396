import { RootState } from 'app/store';

export const selectAccessoriesCategoriesData = () => (state: RootState) =>
  state.accessoriesCategories.accessoriesCategories;

export const selectAccessoriesCategoriesLoading = () => (state: RootState) =>
  state.accessoriesCategories.loading;

export const selectAccessoriesCategoryById = () => (state: RootState) =>
  state.accessoriesCategories.accessoryCategoryById;

export const selectAccessoryCategoriesPage = () => (state: RootState) =>
  state.accessoriesCategories.page;

export const selectAccessoryCategoriesSearch = () => (state: RootState) =>
  state.accessoriesCategories.search;

export const selectAccessoryCategoriesLimit = () => (state: RootState) =>
  state.accessoriesCategories.limit;

export const selectAccessoriesCategoriesCount = () => (state: RootState) =>
  state.accessoriesCategories.count;

export const selectAccessoriesCategoriesLoadingAction =
  () => (state: RootState) =>
    state.accessoriesCategories.loadingAction;

export const selectAccessoriesCategoriesLoadingById =
  () => (state: RootState) =>
    state.accessoriesCategories.loadingById;
