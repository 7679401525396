import { RootState } from 'app/store';

export const selectCategoryLoading = () => (state: RootState) =>
  state.category.loading;

export const selectCategoryData = () => (state: RootState) =>
  state.category.category;

export const selectCategoryById = () => (state: RootState) =>
  state.category.categoryById;

export const selectAttributeByCategoryId = () => (state: RootState) =>
  state.category.attributeByCategoryId;

export const selectAttributesByCategoryIds = () => (state: RootState) =>
  state.category.attributesByCategoryIds;

export const selectCategoryActionLoading = () => (state: RootState) =>
  state.category.loadingAction;

export const selectCategorySearch = () => (state: RootState) =>
  state.category.search;

export const selectCategoryPage = () => (state: RootState) =>
  state.category.page;

export const selectCategoryLimit = () => (state: RootState) =>
  state.category.limit;

export const selectCategoryCount = () => (state: RootState) =>
  state.category.count;

export const selectCategoryFilter = () => (state: RootState) =>
  state.category.filterCategoryData;

export const selectAccessoryLoadingById = () => (state: RootState) =>
  state.accessories.loadingById;
