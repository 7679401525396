import { Button, Form, Popconfirm, Table, message } from 'antd';
import {
  setLimit,
  setPage,
  setSearch
} from 'app/features/accessoriesCategories/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {
  selectAccessoryCount,
  // selectAccessoryData,
  selectAccessoryLoadingAction,
  selectAccessoryLoadingById,
  selectAccessoryLimit,
  selectAccessoryPage,
  selectAccessorySearch,
  selectAccessoriesByProductId
} from 'app/features/accessories/selectors';
import {
  deleteProductAccessory,
  getAccessoriesByProductId,
  updateProductAccessories
} from 'app/features/accessories/slice';
import { selectNewProduct } from 'app/features/products/selectors';
// import ContentTop from 'components/ContentTop';
import NumericInput, {
  cleanAndParse,
  parseInputValue
} from 'helpers/NumericInput';
import { ProductAccessory } from 'app/features/accessories/types';

import { ButtonsWrapper, EditableCellValueWrap } from './styled';
import DeleteModal from 'components/DeleteModal';
// import AccessoryCategoryModal from 'pages/Accessories/components/AccessoryCategoryModal';
import AccessoriesDetails from '../accessories';
// import { LeftPage } from 'components/DetailsHead/styled';

const debounce = _debounce();

const AccessoriesProduct = () => {
  const newProduct = useAppSelector(selectNewProduct());
  const { id } = useParams();
  const productId = Number(id);
  const newProductId = Number(newProduct?.dataValues.id);
  const [form] = Form.useForm();

  //state
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  // const [editMode, setEditMode] = useState<boolean>(false);
  const [accessoryId, setAccessoryId] = useState<number | null>(null);
  const [showAccessoriesDetails, setShowAccessoriesDetails] = useState(false);

  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const loading = useAppSelector(selectAccessoryLoadingById());
  const page = useAppSelector(selectAccessoryPage());
  const limit = useAppSelector(selectAccessoryLimit());
  const count = useAppSelector(selectAccessoryCount());
  const search = useAppSelector(selectAccessorySearch());
  const loadingAction = useAppSelector(selectAccessoryLoadingAction());
  const accessoriesCategoryData = useAppSelector(
    selectAccessoriesByProductId()
  );

  const [editingKey, setEditingKey] = useState<number | null>(null);

  const isEditing = (record: ProductAccessory) => record.id === editingKey;

  const edit = (record: ProductAccessory) => {
    form.setFieldsValue({
      price: parseInputValue(
        String(record.products?.[0].product_accessories.price)
      )
    });
    setEditingKey(record.id);
  };

  // const cancel = () => {
  //   setEditingKey(null);
  // };

  const save = async (accessoryId: number) => {
    const row = await form.validateFields();
    const updateData = {
      product_id: productId,
      accessory_id: accessoryId,
      price: cleanAndParse(row.price)
    };

    dispatch(updateProductAccessories({ ...updateData }));
    setEditingKey(null);
  };

  useEffect(() => {
    debounce(() =>
      dispatch(getAccessoriesByProductId(productId | newProductId))
    );
  }, [dispatch, search, page, limit, productId, newProductId]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const handleDeleteAccessory = async () => {
    if (accessoryId) {
      const postData = {
        product_id: productId,
        accessory_id: accessoryId
      };

      await dispatch(deleteProductAccessory({ data: postData }));
      setOpenDeleteModal(false);
      setAccessoryId(null);
    } else {
      message.error(
        `error removing accessory with id ${accessoryId}. Please try again later`
      );
    }
  };

  // const onClose = () => {
  //   setEditMode(false);
  //   setAccessoryId(null);
  //   setShowAccessoryCategoryModal(false);
  // };

  const columns: ColumnsType<ProductAccessory> = [
    {
      title: 'Accessory name',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Accessory Category name',
      key: 'title',
      render: (record: ProductAccessory) => record?.accessory_category?.title
    },
    {
      title: 'Accessory price',
      // dataIndex: 'price',
      key: 'price',
      render: (_, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name="price">
            <NumericInput
              suffix={
                <Popconfirm
                  title="Do you want to update accessory price?"
                  onConfirm={() => save(record.id)}
                >
                  <a>Update</a> {''}
                  <CheckOutlined />
                </Popconfirm>
              }
              onPressEnter={() => save(record.id)}
            />
          </Form.Item>
        ) : (
          <EditableCellValueWrap onClick={() => edit(record)}>
            {parseInputValue(
              String(record.products?.[0].product_accessories.price)
            )}
          </EditableCellValueWrap>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Button
              danger
              icon={<CloseOutlined />}
              onClick={() => {
                setAccessoryId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Remove from product
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      {showAccessoriesDetails ? (
        <AccessoriesDetails
          id={accessoryId}
          setShowAccessoriesDetails={setShowAccessoriesDetails}
        />
      ) : (
        <>
          <Form form={form}>
            <Table
              bordered
              columns={columns}
              dataSource={
                accessoriesCategoryData ? accessoriesCategoryData : []
              }
              loading={loading}
              rowKey="id"
              pagination={{
                current: page,
                onChange: (page, pageSize) => {
                  dispatch(setPage(page));
                  dispatch(setLimit(pageSize));
                },
                pageSize: limit,
                total: count
              }}
            />
          </Form>
          <DeleteModal
            open={openDeleteModal}
            confirmLoading={loadingAction}
            onOk={handleDeleteAccessory}
            onCancel={() => {
              setOpenDeleteModal(false);
              setAccessoryId(null);
            }}
          />
        </>
      )}
    </>
  );
};

export default AccessoriesProduct;
