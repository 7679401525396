import { ChangeEvent, ChangeEventHandler, FC } from 'react';
import { Input, InputProps } from 'antd';

interface NumericInputProps extends InputProps {
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const cleanAndParse = (
  numberString: string | number | undefined
): number => {
  const num = String(numberString);
  const cleanedNumberString = num?.replace(/,/g, '');
  const parsedNumber = parseFloat(cleanedNumberString);

  return parsedNumber;
};

export const parseInputValue = (
  inputValue: string
): string | undefined | null => {
  if (!inputValue) return undefined;

  const cleanedValue = inputValue.replace(/,/g, '').replace(/^0+/, '');

  const [integerPart, decimalPart] = cleanedValue.split('.');
  const formattedIntegerPart = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 12
  }).format(Number(integerPart));

  const result =
    decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;

  const reg = /^[0-9]*\.?[0-9]*$/;

  return reg.test(cleanedValue) || cleanedValue === '' || cleanedValue === '-'
    ? result
    : null;
};

const handleChange = (
  e: ChangeEvent<HTMLInputElement>,
  onChange?: ChangeEventHandler<HTMLInputElement>
) => {
  const { value: inputValue } = e.target;
  const result = parseInputValue(inputValue);

  if (result === null) return;
  onChange?.({
    ...e,
    target: { ...e.target, value: result !== undefined ? result : '' }
  });
};

const NumericInput: FC<NumericInputProps> = ({
  value = '',
  onChange,
  ...rest
}) => {
  return (
    <Input
      {...rest}
      onChange={e => handleChange(e, onChange)}
      placeholder={rest.placeholder ?? 'Input a number'}
      maxLength={rest.maxLength ?? 100}
      value={value}
    />
  );
};

export default NumericInput;
