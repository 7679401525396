import ReactQuill, { ReactQuillProps } from 'react-quill';
import { ForwardedRef, forwardRef, ReactNode } from 'react';
import 'react-quill/dist/quill.snow.css';

import { RichTextEditorStyled, RichTextEditorWrapper } from './styled';

export interface RichTextEditorProps extends ReactQuillProps {
  errorMessage?: string;
  isErrorVisible?: boolean;
  label?: ReactNode;
}

export const RichTextEditor = forwardRef(
  (props: RichTextEditorProps, ref: ForwardedRef<ReactQuill>) => {
    const { isErrorVisible, errorMessage, label } = props;

    return (
      <RichTextEditorWrapper>
        <p className="label">{label}</p>
        <RichTextEditorStyled {...props} ref={ref} />
        {isErrorVisible && <p className="error">{errorMessage}</p>}
      </RichTextEditorWrapper>
    );
  }
);
