import { message, Modal } from 'antd';
import { validateCodeAndArticle } from 'app/features/products/slice';
import { useAppDispatch } from 'app/hooks';

import { _debounce } from './utils';

interface ValidationLogic {
  validateCode: (formCode: string) => Promise<boolean>;
  validateArticleNumber: (articleNumber: string) => Promise<boolean>;
}

export const useValidationLogic = (): ValidationLogic => {
  const dispatch = useAppDispatch();

  const delay = _debounce(1000);

  const validate = async (
    key: 'code' | 'article number',
    value: string,
    duplicateTitle: string
  ): Promise<boolean> => {
    try {
      if (value) {
        const payloadKey = key === 'code' ? 'code' : 'articul_number';
        const response = await dispatch(
          validateCodeAndArticle({
            [String(payloadKey)]: value.replace(' ', '')
          })
        );

        if (!response.payload) {
          message.success(`${key} validated successfully`);

          // return Promise.resolve();
          return true;
        } else {
          Modal.error({
            title: duplicateTitle,
            content: `There's a product with ${key} ${value.replace(
              ' ',
              ''
            )}, enter another one`
          });

          // return Promise.reject();
          return false;
        }
      }

      return false;
    } catch (error) {
      message.error(`${key} validation failed`);

      // return Promise.reject();
      return false;
    }
  };

  const validateCode = (formCode: string): Promise<boolean> =>
    new Promise<boolean>(resolve => {
      delay(() => {
        const isValid = validate('code', formCode, 'Duplicate Code');
        resolve(isValid);
      });
    });

  const validateArticleNumber = (articleNumber: string): Promise<boolean> =>
    new Promise<boolean>(resolve => {
      delay(() => {
        const isValid = validate(
          'article number',
          articleNumber,
          'Duplicate Article Number'
        );

        resolve(isValid);
      });
    });

  return {
    validateCode,
    validateArticleNumber
  };
};
