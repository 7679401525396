import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import {
  selectUserLimit,
  selectUserPage,
  selectUserSearch
} from 'app/features/users/selectors';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { Modal } from 'antd';

import { IUserEditValues, UserParams, UserId, UserState } from './types';

const initialState: UserState = {
  users: [],
  userById: null,
  userRole: null,
  loadingAction: false,
  loadingById: false,
  loadingRole: false,
  loading: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const userApi = createAsyncThunk(
  'users/userApi',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectUserPage()(state);
    const search = selectUserSearch()(state);
    const limit = selectUserLimit()(state);

    try {
      const res = await client.get(`/user`, {
        params: {
          search,
          page,
          limit
        }
      });

      return res.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getUserById = createAsyncThunk(
  'users/userById',
  async (id: UserId, { rejectWithValue }) => {
    try {
      const response = await client.get(`/user/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getUserRole = createAsyncThunk(
  'users/userRole',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get(`/user/get-my-profile`);

      return response.data.payload;
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id: UserId, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/user/${id}`);
      await dispatch(userApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postUser = createAsyncThunk(
  'users/postUser',
  async ({ ...data }: UserParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.post('/user', {
        ...data
      });

      if (response.status === 200) {
        Modal.success({
          content: 'User Created Successfully!'
        });
      } else {
        Modal.error({
          content: 'Failed to Create User!'
        });
      }

      await dispatch(userApi());
    } catch (err) {
      return rejectWithValue(err as AxiosError<ErrorType>);
    }
  }
);

export const userEdit = createAsyncThunk(
  'users/userEdit',
  async ({ id, ...data }: IUserEditValues, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.put(`/user/${id}`, {
        ...data
      });

      if (response.status === 200) {
        Modal.success({
          content: 'User Updated Successfully!'
        });
      } else {
        Modal.error({
          content: 'Failed to Update User!'
        });
      }

      await dispatch(userApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(deleteUser.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteUser.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteUser.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(userEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(userEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(userEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postUser.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postUser.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postUser.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(userApi.pending, state => {
        state.loading = true;
      })
      .addCase(userApi.fulfilled, (state, action) => {
        state.users = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(userApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getUserById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.userById = action.payload;
        state.loadingById = false;
      })
      .addCase(getUserById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(getUserRole.pending, state => {
        state.loadingRole = true;
      })
      .addCase(getUserRole.fulfilled, (state, action) => {
        state.userRole = action.payload;
        state.loadingRole = false;
      })
      .addCase(getUserRole.rejected, state => {
        state.loadingRole = false;
      });
  }
});

export const { setPage, setSearch, setLimit } = usersSlice.actions;
export default usersSlice.reducer;
