import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  categoryApi,
  deleteCategory,
  getCategoryById,
  setLimit,
  setPage
  // setSearch
} from 'app/features/category/slice';
import { CategoryId, ICategory } from 'app/features/category/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectCategoryActionLoading,
  selectCategoryCount,
  selectCategoryData,
  selectCategoryLimit,
  selectCategoryLoading,
  selectCategoryPage
} from 'app/features/category/selectors';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ContentTop from 'components/ContentTop';
import { ButtonsWrapper } from './styled';
import { CategoryModal } from './components';
import DeleteModal from 'components/DeleteModal';

// const debounce = _debounce();

const Categories: FC = () => {
  // dispatch
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // selectors
  const categoryData = useAppSelector(selectCategoryData());
  const loading = useAppSelector(selectCategoryLoading());
  const page = useAppSelector(selectCategoryPage());
  const limit = useAppSelector(selectCategoryLimit());
  const count = useAppSelector(selectCategoryCount());
  const loadingAction = useAppSelector(selectCategoryActionLoading());

  // states
  const [parentId, setParentId] = useState<CategoryId | null>(null);
  const [categoryId, setCategoryId] = useState<CategoryId | null>(null);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>(categoryData);
  const [search, setSearch] = useState<string>('');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  useEffect(() => {
    dispatch(categoryApi());
  }, [dispatch, page, limit]);

  useEffect(() => {
    dispatch(categoryApi());
    setCategories(categoryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setLimit(10));
      setParentId(null);
    };
  }, [dispatch, navigate]);

  const handleEditCategory = (id: CategoryId) => {
    dispatch(getCategoryById(id));
    setCategoryId(id);
    setShowCategoryModal(true);
  };

  const handleAddSubcategory = (id: CategoryId) => {
    setParentId(id);
    setShowCategoryModal(true);
  };

  const onClose = () => {
    setParentId(null);
    setCategoryId(null);
    setShowCategoryModal(false);
  };

  const handleDeleteCategory = () => {
    categoryId ? dispatch(deleteCategory(categoryId)) : message.error;
    setOpenDeleteModal(false);
    setCategoryId(null);
  };

  const filterCategoryBySearch = (
    category: ICategory,
    searchTerm: string
  ): boolean => {
    const matchInCategory = category.title_en
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchInChildren = category.children
      ? category.children.some(child =>
          filterCategoryBySearch(child, searchTerm)
        )
      : false;

    if (matchInCategory || matchInChildren) {
      setExpandedKeys(keys => [...keys, category.key]);
    }

    return matchInCategory || matchInChildren;
  };

  useEffect(() => {
    setExpandedKeys([]);
    const filteredCategories = search
      ? categoryData.filter(category =>
          filterCategoryBySearch(category, search)
        )
      : categoryData;

    setCategories(filteredCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, categoryData]);

  const highlightSearchText = (text: string, searchTerm: string): string => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, 'gi');

    return text.replace(
      regex,
      '<span style="background-color: yellow;">$1</span>'
    );
  };

  const columns: ColumnsType<ICategory> = [
    {
      title: 'Title',
      dataIndex: 'title_en',
      key: 'title_en',
      render: text => {
        const highlightedText = highlightSearchText(text, search);

        return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
      }
    },
    {
      title: 'Code',
      dataIndex: 'hc_code',
      key: 'hc_code'
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 260,
      key: 'Action',
      align: 'center',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                handleEditCategory(record.id);
              }}
            >
              Edit
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setCategoryId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
            <Tooltip placement="topLeft" title="Add SubCategory">
              <Button
                type="primary"
                onClick={() => handleAddSubcategory(record.id)}
                icon={<PlusOutlined />}
              ></Button>
            </Tooltip>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Categories"
        placeholder="Search Category"
        onClickAdd={() => {
          setShowCategoryModal(true);
        }}
        onChange={e => {
          setSearch(e.target.value);
        }}
        buttonText="Add Category"
        searchComponent
        search={search}
        add
      />
      <Table
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedKeys(keys => [...keys, record.key]);
            } else {
              setExpandedKeys(keys => keys.filter(key => key !== record.key));
            }
          }
        }}
        bordered
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          total: count,
          pageSize: limit
        }}
        loading={loading}
        columns={columns}
        dataSource={categories}
        rowKey="key"
      />

      <CategoryModal
        isOpen={showCategoryModal}
        onClose={onClose}
        params={{
          categoryId,
          parentId
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          handleDeleteCategory();
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setCategoryId(null);
        }}
      />
    </>
  );
};

export default Categories;
