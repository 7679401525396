import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';
import { RootState } from 'app/store';

import { BusinessState, PartnerPost } from './types';
import {
  selectBusinessLimit,
  selectBusinessPage,
  selectBusinessSearch
} from './selectors';

const initialState: BusinessState = {
  businesses: [],
  businessById: null,
  availabilityList: null,
  loading: false,
  loadingById: false,
  loadingAction: false,
  search: '',
  page: 1,
  limit: 10,
  count: 0
};

export const businessApi = createAsyncThunk(
  'businesses/businessesApi',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const search = selectBusinessSearch()(state);
    const page = selectBusinessPage()(state);
    const limit = selectBusinessLimit()(state);

    const params = {
      page,
      search,
      limit
    };

    try {
      const res = await client.get(`/partner`, { params });

      return res.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getBusinessById = createAsyncThunk(
  'businesses/businessById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`/partner/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postPartner = createAsyncThunk(
  'businesses/postPartner',
  async ({ ...data }: PartnerPost, { dispatch, rejectWithValue }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, access_id, ...dataToPost } = data;

    try {
      const res = await client.post('/partner', {
        ...dataToPost
      });

      if (res.status === 200) {
        message.success('Business created successfully');
      }

      dispatch(businessApi());

      return res.data.payload;
    } catch (err) {
      return rejectWithValue(err as AxiosError<ErrorType>);
    }
  }
);

export const updatePartner = createAsyncThunk(
  'businesses/updatePartner',
  async ({ ...data }: PartnerPost, { dispatch, rejectWithValue }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, access_id, ...dataToPost } = data;

    try {
      const res = await client.patch(`/partner/${id}`, {
        ...dataToPost
      });

      message.success('Business updated successfully');
      dispatch(businessApi());

      return res.data.payload;
    } catch (err) {
      return rejectWithValue(err as AxiosError<ErrorType>);
    }
  }
);

export const deletePartner = createAsyncThunk(
  'businesses/deletePartner',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/partner/${id}`);
      message.success('Business deleted successfully');

      return dispatch(businessApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getAvailabilityListByProdId = createAsyncThunk(
  'products/product-available-partners',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(
        `/product/${productId}/product-available-partners`
      );

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const businessesSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(businessApi.pending, state => {
        state.loading = true;
      })
      .addCase(businessApi.fulfilled, (state, action) => {
        state.loading = false;
        state.businesses = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(businessApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getBusinessById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getBusinessById.fulfilled, (state, action) => {
        state.businessById = action.payload;
        state.loadingById = false;
      })
      .addCase(getBusinessById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(postPartner.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postPartner.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postPartner.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updatePartner.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updatePartner.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updatePartner.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deletePartner.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deletePartner.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deletePartner.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getAvailabilityListByProdId.pending, state => {
        state.loading = true;
      })
      .addCase(getAvailabilityListByProdId.fulfilled, (state, action) => {
        state.loading = false;
        state.availabilityList = action.payload;
      })
      .addCase(getAvailabilityListByProdId.rejected, state => {
        state.loading = false;
      });
  }
});

export const { setPage, setSearch, setLimit } = businessesSlice.actions;
export default businessesSlice.reducer;
