import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import {
  selectProductLimit,
  selectProductPage,
  selectProductSearch
} from 'app/features/products/selectors';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';

import {
  ICodeAndArticle,
  IProductEditValues,
  IProductPostValues,
  ProductApiOptions,
  ProductState
} from './types';

const initialState: ProductState = {
  products: [],
  productInvoices: [],
  productMessages: [],
  productSpecialists: [],
  newProduct: null,
  productById: null,
  loadingAction: false,
  loadingById: false,
  loading: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0,
  getCode: '',
  codeValidation: {
    loading: false,
    result: false
  }
};

export const productApi = createAsyncThunk(
  'products/productApi',
  async (
    options: ProductApiOptions | undefined,
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const page = selectProductPage()(state);
    const search = selectProductSearch()(state);
    const limit = selectProductLimit()(state);
    const body = options?.body || '';
    const params = {
      page,
      search,
      limit
    };

    try {
      const res = await client.post(`/product/explore`, body, { params });

      return dispatch(setProduct(res.data.payload));
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getProductById = createAsyncThunk(
  'products/productById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`/product/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/product/${id}`);
      await dispatch(productApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postProduct = createAsyncThunk(
  'products/postProduct',
  async (
    {
      dataProduct,
      isPresales = false
    }: {
      dataProduct: IProductPostValues;
      isPresales: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await client.post('/product/create', {
        ...dataProduct
      });

      if (response.status === 200) {
        message.success({
          content: 'Product Created Successfully!'
        });

        if (isPresales) {
          await dispatch(getProductById(response.data.payload.dataValues.id));
        }

        return response.data.payload;
      } else {
        message.error({
          content: 'Failed to Create Product!'
        });
      }
    } catch (err) {
      return rejectWithValue(err as AxiosError<ErrorType>);
    }
  }
);

export const productEdit = createAsyncThunk(
  'products/productsEdit',
  async (
    {
      dataProduct,
      productId,
      isAdmin = false
    }: {
      dataProduct: IProductEditValues;
      productId: number;
      isAdmin?: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await client.patch(`/product/${productId}`, {
        ...dataProduct
      });

      if (response.status === 200) {
        message.success({
          content: `Product with ID ${productId} Updated Successfully`
        });

        if (!isAdmin) {
          await dispatch(getProductById(productId));
          await dispatch(getInvoicesByProductId(productId));
        }
      }

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));

      return err;
    }
  }
);

export const nextStep = createAsyncThunk(
  'products/nextStep',
  async (productId: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.post(`/product/next-step/${productId}`);

      if (response.status === 200) {
        message.success({
          content: `Step has been updated successfully.`
        });
        await dispatch(getProductById(productId));
      }

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);
export const prevStep = createAsyncThunk(
  'products/prevStep',
  async (
    { productId, reset = true }: { productId: number; reset?: boolean },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await client.post(`/product/previous-step/${productId}`);

      if (response.status === 200) {
        reset && (await dispatch(getProductById(productId)));
      }

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const prevStepBulk = createAsyncThunk(
  'products/prevStepBulk',
  async (
    {
      productId,
      reset = true,
      stepDifference
    }: { productId: number; reset?: boolean; stepDifference: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let response;

      for (let i = 0; i < Math.abs(stepDifference); i++) {
        response = await client.post(`/product/previous-step/${productId}`);
      }

      if (reset) {
        await dispatch(getProductById(productId));
      }

      if (response?.status === 200) {
        message.success({
          content: `Step has been updated successfully.`
        });
      } else {
        message.error({
          content: `Backend validation error.`
        });
      }

      return { success: true };
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const validateCodeAndArticle = createAsyncThunk(
  'products/check',
  async (codeAndArticle: ICodeAndArticle, { rejectWithValue }) => {
    try {
      const response = await client.post(`/product/check`, {
        ...codeAndArticle
      });

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getCode = createAsyncThunk(
  'products/getCode',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get(`/product/get-code`);

      if (response.status === 200) {
        message.success({
          content: `Code successfully fetched`
        });
      }

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getInvoicesByProductId = createAsyncThunk(
  'files/invoicesByProductId',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`product/${productId}/file`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getMessagesByProductId = createAsyncThunk(
  'product/messagesByProductId',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`product/${productId}/message`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getSpecialistsByProductId = createAsyncThunk(
  'product/specialistsByProductId',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`product/${productId}/user`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const optowirePricePost = createAsyncThunk(
  'products/optowirePricePost',
  async (
    {
      productId,
      optowire_price
    }: { productId: number; optowire_price: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await client.post(
        `/product/${productId}/optowire-price`,
        {
          optowire_price
        }
      );

      if (response.status === 200) {
        message.success({
          content: `Optowire Price was successfully updated`
        });
      }

      await dispatch(getProductById(productId));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setProduct: (state, action) => {
      state.products = action.payload.rows;
      state.count = action.payload.count;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    resetProductData: state => {
      state.productById = null;
      state.newProduct = null;
      state.getCode = null;
    },
    resetPrevProduct: state => {
      state.productById = null;
      state.newProduct = null;
      state.getCode = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(deleteProduct.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteProduct.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteProduct.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(productEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(productEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(productEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postProduct.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postProduct.fulfilled, (state, action) => {
        state.newProduct = action.payload;
        state.loadingAction = false;
      })
      .addCase(postProduct.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(productApi.pending, state => {
        state.loading = true;
      })
      .addCase(productApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(productApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getProductById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.productById = action.payload;
        state.loadingById = false;
      })
      .addCase(getProductById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(validateCodeAndArticle.pending, state => {
        state.codeValidation.loading = true;
        state.codeValidation.result = false;
        state.codeValidation.error = null;
      })
      .addCase(validateCodeAndArticle.fulfilled, (state, action) => {
        state.codeValidation.loading = false;
        state.codeValidation.result = action.payload;
      })
      .addCase(validateCodeAndArticle.rejected, state => {
        state.codeValidation.loading = false;
      })
      .addCase(getCode.pending, state => {
        state.loadingAction = true;
      })
      .addCase(getCode.fulfilled, (state, action) => {
        state.loadingAction = false;
        state.getCode = action.payload;
      })
      .addCase(getCode.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(prevStepBulk.pending, state => {
        state.loadingAction = true;
      })
      .addCase(prevStepBulk.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(prevStepBulk.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(prevStep.pending, state => {
        state.loadingAction = true;
      })
      .addCase(prevStep.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(prevStep.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(nextStep.pending, state => {
        state.loadingAction = true;
      })
      .addCase(nextStep.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(nextStep.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getInvoicesByProductId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getInvoicesByProductId.fulfilled, (state, action) => {
        state.productInvoices = action.payload.rows;
        state.loadingById = false;
      })
      .addCase(getInvoicesByProductId.rejected, state => {
        state.loadingById = false;
      })
      .addCase(getMessagesByProductId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getMessagesByProductId.fulfilled, (state, action) => {
        state.productMessages = action.payload.rows;
        state.loadingById = false;
      })
      .addCase(getMessagesByProductId.rejected, state => {
        state.loadingById = false;
      })
      .addCase(getSpecialistsByProductId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getSpecialistsByProductId.fulfilled, (state, action) => {
        state.productSpecialists = action.payload.rows;
        state.loadingById = false;
      })
      .addCase(getSpecialistsByProductId.rejected, state => {
        state.loadingById = false;
      });
  }
});

export const {
  setPage,
  setSearch,
  setProduct,
  setLimit,
  resetProductData,
  resetPrevProduct
} = productsSlice.actions;
export default productsSlice.reducer;
