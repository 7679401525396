import { FC } from 'react';
import { Tabs } from 'antd';

import { ProductAccessoriesProps } from './types';
import AccessoriesCategories from './components/accessoriesCategories';
import AccessoriesProduct from './components/productAccessories';

const ProductAccessories: FC<ProductAccessoriesProps> = () => (
  <Tabs
    items={[
      {
        label: 'Product Accessories',
        key: '1',
        children: <AccessoriesProduct />
      },
      {
        label: 'Add Accessory to Product',
        key: '2',
        children: <AccessoriesCategories />
      }
    ]}
  />
);

export default ProductAccessories;
