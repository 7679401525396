import { Col, Pagination, Row, Tabs, Image, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectFilesLoading,
  selectFilesLoadingAction,
  selectFilesCount,
  selectFilesSearch,
  selectFilesLimit,
  selectFilesPage,
  selectInvoiceFiles,
  selectPicturesFiles
} from 'app/features/files/selectors';
import {
  CopyOutlined,
  DeleteOutlined,
  // FilePdfTwoTone,
  FileTwoTone,
  FolderOpenOutlined,
  FolderOutlined
} from '@ant-design/icons';
import { _debounce, getFileUrl, handleCopy } from 'helpers/utils';
import {
  deleteFile,
  fetchInvoiceFilesApi,
  fetchPicturesFilesApi,
  setLimit,
  setPage,
  setSearch
} from 'app/features/files/slice';
import { useNavigate } from 'react-router-dom';
import { IFile } from 'app/features/files/types';

import noImg from '../../../../assets/img/noimage.png';
import pdfCover from '../../../../assets/img/pdf.png';
import {
  ButtonCopy,
  ButtonDelete,
  CardImg,
  FilesMeta,
  ImgWrraper
} from './styled';
import DeleteModal from 'components/DeleteModal';
import Loading from 'components/Loading';

interface AllTabsProps {
  type: 'images' | 'contracts';
}
interface ChildProps {
  activeTabKey: string;
  setActiveTabKey: React.Dispatch<React.SetStateAction<string>>;
}
const debouncedFetch = _debounce(500);

const Files = ({ activeTabKey, setActiveTabKey }: ChildProps) => {
  const [itemOpacity, setItemOpacity] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [fileId, setFileId] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (fileUrl: string) => {
    setPreviewImage(fileUrl.replace('280x198', '574x438') || '');
    setPreviewOpen(true);
    setPreviewTitle(
      fileUrl
        ? fileUrl
            .substring(fileUrl.lastIndexOf('/') + 1)
            .replace('280x198', '574x438')
        : ''
    );
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // selectors
  const imagesData = useAppSelector(selectPicturesFiles);
  const contractFilesData = useAppSelector(selectInvoiceFiles);
  const loadingAction = useAppSelector(selectFilesLoadingAction());
  const count = useAppSelector(selectFilesCount());
  const loading = useAppSelector(selectFilesLoading());
  const search = useAppSelector(selectFilesSearch());
  const limit = useAppSelector(selectFilesLimit());
  const page = useAppSelector(selectFilesPage());

  useEffect(() => {
    const fetchData = async () => {
      if (activeTabKey === '1') {
        dispatch(fetchPicturesFilesApi({ size: 'large' }));
      } else if (activeTabKey === '2') {
        dispatch(fetchInvoiceFilesApi());
      }
    };

    debouncedFetch(fetchData);
  }, [dispatch, search, page, limit, activeTabKey]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(12));
    };
  }, [dispatch, navigate]);

  const images =
    imagesData
      // ?.filter(url => url.path.includes('280x198'))
      ?.map((item: IFile) => {
        const url = getFileUrl(item.path) || '';

        return {
          uid: item.id,
          name: `${item.name}.${item.extension}`,
          status: 'done',
          extension: item.extension,
          path: item.path,
          url,
          created_at: item.created_at
        };
      }) || [];

  const contracts =
    contractFilesData?.map((item: IFile) => {
      const url = getFileUrl(item.path) || '';

      return {
        uid: item.id,
        name: `${item.name}.${item.extension}`,
        status: 'done',
        extension: item.extension,
        path: item.path,
        url,
        created_at: item.created_at
      };
    }) || [];

  const AllTabs = ({ type }: AllTabsProps) => {
    const isImagesTab = type === 'images';
    const dataToMap = isImagesTab ? images : contracts;

    return (
      <Row gutter={[24, 24]}>
        {dataToMap?.map((item, index) => {
          const createdAtDate = item.created_at && new Date(item.created_at);
          const formattedCreatedAt = createdAtDate?.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
            // timeZoneName: 'short'
          });

          return (
            <Col key={item.uid + index} span={8}>
              <CardImg
                style={
                  itemOpacity === item.path ? { opacity: 0.5 } : { opacity: 1 }
                }
                hoverable
                actions={[
                  <>
                    <ButtonCopy
                      key="copy"
                      style={{ width: '50%', color: 'green' }}
                      block
                      icon={<CopyOutlined />}
                      onClick={() => {
                        handleCopy(
                          isImagesTab ? getFileUrl(item.path) : item.path
                        );

                        setItemOpacity(item.path);
                        setTimeout(() => {
                          setItemOpacity('');
                        }, 2000);
                      }}
                    >
                      {isImagesTab ? 'Copy image URL' : 'Copy file name'}
                    </ButtonCopy>
                    <ButtonDelete
                      key="delete"
                      style={{ width: '50%' }}
                      block
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => {
                        setFileId(item.uid);
                        setOpenDeleteModal(true);
                      }}
                    >
                      Delete
                    </ButtonDelete>
                  </>
                ]}
                cover={
                  <ImgWrraper>
                    {isImagesTab ? (
                      <>
                        <Image
                          onClick={() => handlePreview(item.url)}
                          // preview={{
                          //   src: getFileUrl(
                          //     item.path.replace('280x198', '574x438')
                          //   ),
                          //   mask: (
                          //     <div
                          //       style={{
                          //         marginTop: '8px',
                          //         textAlign: 'center'
                          //       }}
                          //     >
                          //       {item.path}
                          //     </div>
                          //   )
                          // }}
                          src={item.url}
                          height={200}
                          width={265}
                        />
                      </>
                    ) : item.extension === 'pdf' ? (
                      <img
                        height={200}
                        width={265}
                        src={pdfCover}
                        // style={{ width: '78%' }}
                        alt="File Thumbnail"
                        onClick={() => window.open(getFileUrl(item.path))}
                      />
                    ) : item.extension === 'docx' ||
                      item.extension === 'doc' ? (
                      <FileTwoTone />
                    ) : (
                      <img
                        src={noImg}
                        height={200}
                        width={265}
                        alt="File Thumbnail"
                      />
                    )}
                  </ImgWrraper>
                }
              >
                <FilesMeta
                  title={item.path}
                  description={`Created at: ${formattedCreatedAt}`}
                />
                {/* </div> */}
              </CardImg>
            </Col>
          );
        })}
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Pagination
          hideOnSinglePage
          total={count}
          current={page}
          pageSize={limit}
          onChange={(page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          }}
        />
      </Row>
    );
  };

  const tabConfig = [
    {
      key: '1',
      label: 'Images',
      icon: {
        active: <FolderOpenOutlined />,
        inactive: <FolderOutlined />
      }
      // content: <AllTabs type="images" />
    },
    {
      key: '2',
      label: 'Contracts',
      icon: {
        active: <FolderOpenOutlined />,
        inactive: <FolderOutlined />
      }
      // content: <AllTabs type="contracts" />
    }
  ];

  const onChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <>
      {loading ? (
        <Loading size="large" />
      ) : (
        <>
          <Tabs
            onChange={onChange}
            type="card"
            defaultActiveKey={activeTabKey}
            items={tabConfig.map(tab => ({
              key: tab.key,
              label: (
                <span>
                  {activeTabKey === tab.key
                    ? tab.icon.active
                    : tab.icon.inactive}
                  {tab.label}
                </span>
              )
              // content: tab.content
            }))}
          ></Tabs>
          {activeTabKey === '1' && <AllTabs type="images" />}
          {activeTabKey === '2' && <AllTabs type="contracts" />}

          <DeleteModal
            open={openDeleteModal}
            confirmLoading={loadingAction}
            onOk={() => {
              dispatch(deleteFile(fileId)).then(() => {
                setOpenDeleteModal(false);
                setFileId('');
              });
            }}
            onCancel={() => {
              setOpenDeleteModal(false);
              setFileId('');
            }}
          />
        </>
      )}
    </>
  );
};

export default Files;
