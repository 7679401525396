import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  text-align: center;
`;
export const ImgTableWrraper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

export const EditableCellValueWrap = styled.div`
  padding: 5px 12px;
  cursor: pointer;

  &:hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;
