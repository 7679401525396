import { Spin, SpinProps } from 'antd';
import { FC } from 'react';

import { LoadingWrraper } from './styled';
import { ILoadingProps } from './types';

const Loading: FC<ILoadingProps> = ({ size }) => {
  return (
    <LoadingWrraper>
      <Spin size={size as SpinProps['size']} />
    </LoadingWrraper>
  );
};

export default Loading;
