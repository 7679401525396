import { RootState } from 'app/store';

export const selectProductPage = () => (state: RootState) =>
  state.products.page;

export const selectProductData = () => (state: RootState) =>
  state.products.products;

export const selectProductCount = () => (state: RootState) =>
  state.products.count;

export const selectProductLoading = () => (state: RootState) =>
  state.products.loading;

export const selectProductLoadingAction = () => (state: RootState) =>
  state.products.loadingAction;

export const selectProductLoadingById = () => (state: RootState) =>
  state.products.loadingById;

export const selectProductSearch = () => (state: RootState) =>
  state.products.search;

export const selectProductLimit = () => (state: RootState) =>
  state.products.limit;

export const selectProductById = () => (state: RootState) =>
  state.products.productById;

export const selectInvoicesByProductId = () => (state: RootState) =>
  state.products.productInvoices;

export const selectMessagesByProductId = () => (state: RootState) =>
  state.products.productMessages;

export const selectSpecialistsByProductId = () => (state: RootState) =>
  state.products.productSpecialists;

export const selectCodeValidation = () => (state: RootState) =>
  state.products.codeValidation.result;

export const selectGetCode = () => (state: RootState) => state.products.getCode;

export const selectNewProduct = () => (state: RootState) =>
  state.products.newProduct;

export const selectValidatorLoading = () => (state: RootState) =>
  state.products.codeValidation.loading;
