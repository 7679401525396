import { RootState } from 'app/store';

export const selectPricesByProductId = () => (state: RootState) =>
  state.productCountryPrices.productCountryPrices;

export const selectProductCountryLoading = () => (state: RootState) =>
  state.productCountryPrices.loading;

export const selectProductCountryLoadingAction = () => (state: RootState) =>
  state.productCountryPrices.loadingAction;

export const selectProductCountryLoadingById = () => (state: RootState) =>
  state.productCountryPrices.loadingById;
