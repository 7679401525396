/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd';
import { ICategory } from 'app/features/category/types';
import { Option, flattenCategories, flattenTree } from 'helpers/product';

const useCategoryData = (
  form: FormInstance<any>,
  categoryData: ICategory[]
) => {
  const selectedCategories: Option[] = form.getFieldValue('categories');
  const values = selectedCategories?.map(item => item.value) || [];

  const hasTelecom = flattenTree(categoryData)
    .filter(item => values.includes(item.id))
    .map(
      item =>
        item?.path_en?.includes('Telecommunication') ||
        item.title_en === 'Telecommunication'
    );

  const hasNetwork = flattenTree(categoryData)
    .filter(item => values.includes(item.id))
    .map(
      item =>
        item?.path_en?.includes('Network equipment') ||
        item.title_en === 'Network equipment'
    );

  const getTreeData = (): Option[] => {
    let treeData = flattenCategories(categoryData) as Option[];

    if (!selectedCategories?.length) return treeData;

    const isNetworkEquipment = (item: Option): boolean => {
      if (
        item.label === 'Network equipment (N)' ||
        (item.path && item.path.includes('Network equipment'))
      ) {
        return true;
      }

      if (item.children) {
        return item.children.some(isNetworkEquipment);
      }

      return false;
    };

    const isTelecom = (item: Option): boolean => {
      if (
        item.label === 'Telecommunication (T)' ||
        (item.path && item.path.includes('Telecommunication'))
      ) {
        return true;
      }

      if (item.children) {
        return item.children.some(isTelecom);
      }

      return false;
    };

    if (
      selectedCategories.some(
        category => category.label === 'Telecommunication (T)'
      ) ||
      hasTelecom.some(value => value)
    ) {
      const applyIsNetworkEquipment = (items: Option[]): Option[] => {
        return items.map(item => ({
          ...item,
          disabled:
            isNetworkEquipment(item) ||
            (hasTelecom && item.value === 321 && selectedCategories.length > 1),
          children: item.children
            ? applyIsNetworkEquipment(item.children)
            : undefined
        }));
      };

      treeData = applyIsNetworkEquipment(treeData);
    } else if (
      selectedCategories.some(
        category => category.label === 'Network equipment (N)'
      ) ||
      hasNetwork.some(value => value)
    ) {
      const applyIsTelecom = (items: Option[]): Option[] => {
        return items.map(item => ({
          ...item,
          disabled:
            isTelecom(item) ||
            (hasNetwork && item.value === 282 && selectedCategories.length > 1),
          children: item.children ? applyIsTelecom(item.children) : undefined
        }));
      };

      // treeData = treeData.map(item => ({
      //   ...item,
      //   selected: isTelecom(item),
      //   children: item.children ? applyIsTelecom(item.children) : undefined
      // }));

      treeData = applyIsTelecom(treeData);

      // treeData = treeData.map(item => ({
      //   ...item,
      //   disabled: item.label === 'Telecommunication (T)'
      // }));
    }

    return treeData;
  };

  return { getTreeData };
};

export default useCategoryData;
