import React, { useEffect } from 'react';
import { List, Modal } from 'antd';
import VirtualList from 'rc-virtual-list';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getSpecialistsByProductId } from 'app/features/products/slice';
import {
  selectProductLoadingById,
  selectSpecialistsByProductId
} from 'app/features/products/selectors';

import { IProductSpecialist, ProductSpecialistsTypes } from './types'; // Import Task type from a separate types file
import { SpecialistContainer, CardContainer } from './styled'; // Import styled components

const ContainerHeight = 400;

const ProductSpecialists: React.FC<ProductSpecialistsTypes> = ({
  productId,
  isVisible,
  onClose
}) => {
  const dispatch = useAppDispatch();

  // const productById = useAppSelector(selectProductById());
  // console.log(productById, 'productById');
  const specialistByProductId = useAppSelector(selectSpecialistsByProductId());
  const loading = useAppSelector(selectProductLoadingById());

  useEffect(() => {
    if (productId && productId !== 0 && isVisible) {
      dispatch(getSpecialistsByProductId(productId));
    }
  }, [dispatch, isVisible, productId]);

  return (
    <Modal
      title="Specialists"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <SpecialistContainer>
        <CardContainer>
          {specialistByProductId?.length ? (
            <List loading={loading}>
              <VirtualList
                data={specialistByProductId}
                height={ContainerHeight}
                itemHeight={47}
                itemKey="id"
                // onScroll={onScroll}
              >
                {(item: IProductSpecialist) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={`${item.user.first_name} ${item.user.last_name}`}
                      description={`${item.user.email}, ${new Date(
                        item.created_at
                      )}`}
                    />
                  </List.Item>
                )}
              </VirtualList>
            </List>
          ) : (
            <p>No specialists available</p>
          )}
        </CardContainer>
      </SpecialistContainer>
    </Modal>
  );
};

export default ProductSpecialists;
