import {
  Col,
  FloatButton,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  TreeSelect,
  message,
  Dropdown,
  UploadProps,
  Image
} from 'antd';
import { convertToAbsoluteUrl, _debounce } from 'helpers/utils';

import Uploader from '../../../../Hook/uploadrer';

import {
  setSearch,
  getCode,
  postProduct,
  productEdit,
  productApi
} from 'app/features/products/slice';
import {
  selectGetCode,
  selectInvoicesByProductId,
  selectNewProduct,
  selectProductLoadingAction,
  selectProductData
} from 'app/features/products/selectors';
import { selectVendorData } from 'app/features/vendor/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import { filterCategoryDataByValue, Option } from 'helpers/product';
import { selectCategoryData } from 'app/features/category/selectors';
import {
  validateYoutubeUrls,
  isValidUrl,
  hasTextOrImage
} from 'helpers/validators';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { IProductValues } from 'app/features/products/types';
import { UndoOutlined } from '@ant-design/icons';
import { getAttributesByCategoryIds } from 'app/features/category/slice';
import COUNTRIES from 'constants/countries';
import {
  handleBackendValidationError,
  handleBackendErrorNoPrefix
} from 'helpers/errors';
import { Rule } from 'antd/es/form';
import { selectUserRole } from 'app/features/users/selectors';
import { UploadFile } from 'antd/es/upload';

import { TagRender } from '../TagRender';
import { useValidationLogic } from '../../../../helpers/articleAndCodeValidator';
import { IPresales } from './types';
import { OtherFormat } from '../AttributeSelect/styled';
import { separateTextAndNumber } from '../AttributeSelect/constant';
import Editor from 'components/Editor';
import useCategoryData from 'pages/categoryData';
import { productProcess } from './constants';
import { useCustomRequestWithResponse } from '../../../../Hook/useCustomRequest';
import { BASE_URL } from '../../../../api';

const validateMessages = {
  required: "'${label}' is Required!"
};

const delay = _debounce(500);

const Presales: FC<IPresales> = ({
  // isChecked,
  // onChangeAvailability,
  setBodyFeatures,
  bodyFeatures,
  attributes,
  setBodyDescription,
  bodyDescription,
  // setColor,
  productById,
  setArticle,
  article,
  form,
  setIsTabDisabled,
  setActiveTabKey,
  onClose,
  isChecked,
  setIsChecked
}) => {
  const [isDropDownOpened, setisDropDownOpened] = useState(false);
  const [progress, setProgress] = useState(false);
  const [initialArticleNumber, setinitialArticleNumber] = useState('');
  const [initialCode, setInitialCode] = useState('');
  const [initialArticle, setInitialArticle] = useState('');
  const [articleValidateStatus, setArticleValidateStatus] =
    useState<ValidateStatus>('');

  const [codeValidateStatus, setCodeValidateStatus] =
    useState<ValidateStatus>('');

  const { id } = useParams();
  const [src, setSrc] = useState<string | undefined>(undefined);
  const [searchedProduct, setSearchedProduct] = useState(
    id ? productById?.title_en : ''
  );

  const dispatch = useAppDispatch();

  const productsData = useAppSelector(selectProductData());

  const handleProductSearch = (value: string) => {
    dispatch(setSearch(value));
  };

  const renderOptions = () => {
    return productsData.map(item => ({
      key: item.id,
      label: (
        <>
          <span>
            {item.title_en.length > 55
              ? item.title_en.substring(0, 55) + ' ...'
              : item.title_en}
          </span>
          <span style={{ float: 'right' }}>{item.code}</span>
        </>
      )
    }));
  };

  const isSearchValid =
    searchedProduct !== productById?.title_en &&
    (searchedProduct?.length ?? 0) >= 3;

  const hasRenderOptions = renderOptions().length > 0;

  const showOptionsOrNotFound = hasRenderOptions
    ? renderOptions()
    : [{ key: 'no-product', label: 'No such product' }];

  const searchResult = isSearchValid ? showOptionsOrNotFound : [];

  const productId = Number(id);
  const categoryData = useAppSelector(selectCategoryData());
  const vendorData = useAppSelector(selectVendorData());
  const productContractFiles = useAppSelector(selectInvoicesByProductId());
  const loading = useAppSelector(selectProductLoadingAction());
  const newProduct = useAppSelector(selectNewProduct());
  const code = useAppSelector(selectGetCode());
  const userRole = useAppSelector(selectUserRole);

  const { validateCode, validateArticleNumber } = useValidationLogic();
  const { getTreeData } = useCategoryData(form, categoryData);

  const countryData = COUNTRIES;

  const { Option } = Select;

  const isSuper = userRole?.role === 'SUPER_ADMIN';

  const treeSelectData: Option[] = Form.useWatch('categories', form);
  const filteredCategories = filterCategoryDataByValue(
    categoryData,
    treeSelectData
  );

  const articleValue = filteredCategories.reduce((initial: string, item) => {
    if (item.key) {
      initial += `${item.key}-`;
    }

    return initial;
  }, '');

  useEffect(() => {
    setArticle(articleValue);
  }, [articleValue, setArticle, treeSelectData]);

  useEffect(() => {
    delay(() => dispatch(productApi()));
  }, [searchedProduct]);

  useEffect(() => {
    if (productById?.id) setSearchedProduct(productById.title_en ?? '');
  }, [productById]);

  useEffect(() => {
    if (loading) return;

    const number = String(form.getFieldValue('articul_number'));
    const combinedValue = articleValue + number;
    const isInItial = initialArticle + initialArticleNumber === combinedValue;
    const shouldValidate = !productId && !newProduct && !productById && number;

    const validateArticle = async () => {
      if (isInItial) {
        setProgress(false);

        return Promise.resolve();
      } else if (!isInItial && articleValue && number) {
        const isValid = await validateArticleNumber(articleValue + number);
        setArticleValidateStatus(isValid ? 'success' : 'error');
        setProgress(false);
      }
    };

    if (shouldValidate || (productById && productId)) {
      setProgress(true);
      validateArticle();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleValue]);

  const onCategoryChange = (selectedCategories: Option[]) => {
    const categoryIds =
      selectedCategories.map(category => category.value) || [];

    if (!categoryIds.length) {
      message.error('at least one category should be selected');

      return;
    }

    const sortedCategories = selectedCategories
      .map(selectedCategory => {
        const matchingCategory = filterCategoryDataByValue(
          categoryData,
          selectedCategories
        ).find(category => category.id === selectedCategory.value);

        return {
          ...selectedCategory,
          index: matchingCategory ? matchingCategory.index : 0
        };
      })
      .sort((a, b) => a.index - b.index);

    delay(() => {
      form.setFieldsValue({
        categories: sortedCategories
      });
      dispatch(getAttributesByCategoryIds(categoryIds));
    });
  };

  useEffect(() => {
    if (id && productById) {
      const result = separateTextAndNumber(productById.articul_number);

      form.setFieldsValue({
        title_en: productById.title_en,
        articul_number: result.number,
        categories: productById.categories
          ?.map(item => ({
            label: `${item.title_en} (${item.key})`,
            value: item.id,
            index: item.index,
            path: item.path_en
          }))
          .sort((a, b) => a.index - b.index),
        model: productById.model,
        code: productById.code,
        availability: Boolean(productById.availability),
        // country: productById.country?.name,
        country: {
          label: productById.country?.name,
          value: productById.country?.id
        },
        urls: productById.urls,
        vendor_id: productById?.main_vendor?.id,
        youtube_urls: productById.youtube_urls,
        unit: productById.unit
      });

      // const fetchLogoUrl = async () => {
      //   let logoUrl;
      //
      //   if (productById) {
      //     logoUrl = productById?.logo_sticker;
      //   }
      //
      //   if (logoUrl) {
      //     const fullUrlWithPublic = `/public/${logoUrl}`;
      //     const srcUrl = convertToAbsoluteUrl(
      //       String(BASE_URL),
      //       fullUrlWithPublic
      //     );
      //
      //     await setSrc(srcUrl);
      //   }
      // };

      //fetchLogoUrl();
      setArticle(result.text);
      setinitialArticleNumber(result.number);
      setInitialArticle(result.text);
      setInitialCode(productById.code);
      // setColor(productById.color || '');
      if (productById.process === productProcess.REJECT) setIsChecked(false);
      else setIsChecked(productById.availability);
      // setVendorId(productById.vendors?.[0]?.productVendors?.[0]?.id);
    }
  }, [productById, form, id, setArticle, attributes, setIsChecked]);

  useEffect(() => {
    const fetchAndSetCodeAndArticle = async () => {
      try {
        if (!id && !code && !newProduct) {
          const codeResponse = await dispatch(getCode());
          const fetchedCode: string = codeResponse.payload;

          const generatedArticleNumber =
            Math.floor(Math.random() * 9000) + 1000;

          const updatedFields = {
            articul_number: generatedArticleNumber,
            code: fetchedCode
          };

          delay(() => {
            setinitialArticleNumber(String(generatedArticleNumber));
            setInitialArticle(article);
            setInitialCode(fetchedCode);
            form.setFieldsValue(updatedFields);
            setProgress(false);
          });
        }
      } catch (error) {
        message.error(`Error fetching code: ${error}`);
      }
    };

    if (!productId && !newProduct) {
      fetchAndSetCodeAndArticle();
    }
  }, []);

  const onFinish = async (values: IProductValues) => {
    const {
      title_en,

      articul_number,
      code,
      model,
      categories,
      country,
      youtube_urls,
      unit,
      urls,
      vendor_id
      // logo_sticker
    } = values;

    const combinedFiles = [...(productContractFiles || []).filter(Boolean)];

    const attributesPost = attributes.map(attribute => ({
      attribute_id: attribute.attributeId,
      type: attribute.value?.type,
      ...(attribute.value?.type === 'enum'
        ? { enum_value: attribute.value?.enumValue }
        : {
            min: Number(attribute.value?.numberRange?.min),
            max: Number(attribute.value?.numberRange?.max)
          })
    }));

    const countryId = Number.isInteger(country)
      ? country
      : (country as { value?: number })?.value;

    const dataProduct = {
      title_en: searchedProduct?.trim() || title_en || '',
      key: Math.floor(Math.random() * 90000) + 10000,
      description1_en: bodyDescription || '',
      html: bodyFeatures || '',
      // articul_number,
      model: model || '',
      code: code.replace(' ', ''),
      vendor_id: vendor_id || '',
      availability: Boolean(isChecked),
      categories: categories?.map(item => Number(item.value)) || [],
      country_id: Number(countryId) ?? '',
      ...(unit && { unit }),
      urls: urls || [],
      youtube_urls: youtube_urls || [],
      files: combinedFiles.map(file => file.id) || [],
      attributes: attributesPost
      //logoSticker: String(response) || null
    };

    if (id) {
      const { key, code, ...sanitizedEditData } = dataProduct;
      const res = await dispatch(
        productEdit({
          productId,
          dataProduct: {
            ...sanitizedEditData,
            articul_number: article + articul_number
          }
        })
      );

      if (res.meta.requestStatus === 'fulfilled' && res.payload) {
        onClose();
      } else {
        if (res?.payload?.response?.status !== 409)
          handleBackendValidationError(
            res?.payload?.response?.data?.message,
            res
          );
      }
    } else {
      const { files, attributes, ...sanitizedPostData } = dataProduct;
      const res = await dispatch(
        postProduct({
          dataProduct: {
            ...sanitizedPostData,
            articul_number: article + articul_number
          },
          isPresales: true
        })
      );

      if (res.meta.requestStatus === 'fulfilled' && res.payload) {
        if (isSuper) {
          setActiveTabKey('filesAndPhotos');

          return;
        }

        if (!productId) {
          setIsTabDisabled(false);
          setActiveTabKey('vendorForm');
        }
      } else {
        handleBackendErrorNoPrefix(res?.payload?.response?.data?.message, res);
      }
    }
  };

  const handleCodeChange = async (value: string) => {
    if (initialCode && value === initialCode) return Promise.resolve();
    const isValid = await validateCode(value);
    setCodeValidateStatus(isValid ? 'success' : 'error');
  };

  const handleArticleChange = async (value: string) => {
    setProgress(true);
    if (
      initialArticleNumber &&
      article + value === initialArticle + initialArticleNumber
    )
      return Promise.resolve();
    const isValid = await validateArticleNumber(article + value);
    setArticleValidateStatus(isValid ? 'success' : 'error');
    setProgress(false);
  };

  const requiredDescription: Rule = {
    validator: async () => {
      if (bodyDescription && hasTextOrImage(bodyDescription)) {
        return Promise.resolve();
      }

      message.error('Please provide a description.');

      return Promise.reject('Please provide description.');
    }
  };

  const requiredFeatures: Rule = {
    validator: async () => {
      if (bodyFeatures && hasTextOrImage(bodyFeatures)) {
        return Promise.resolve();
      }

      message.error('Please provide features.');

      return Promise.reject('Please provide features.');
    }
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleFileRemove = (file: UploadFile) => {
    setFileList(prev => prev.filter(item => item.uid !== file.uid));
  };

  const onChangeImageUpload: UploadProps['onChange'] = ({ fileList }) => {
    setFileList(fileList);
  };

  const folderName = productById?.folder_name || '';
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);
  const { customRequest: pictureUpload, response } =
    useCustomRequestWithResponse({
      url: 'file/create',
      file_type: 'pictures',
      folder: `stickers`
    });

  const onImageRemove = async (file: UploadFile) => {
    setRemovedFiles(prev => [...prev, file.uid]);

    const updatedFileList = fileList.filter(item => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  return (
    <Form
      validateMessages={validateMessages}
      size="large"
      form={form}
      name="formPresales"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError={{
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      }}
    >
      <Row gutter={[24, 24]}>
        <Col span={2}>
          <Form.Item name="availability" label="Availability">
            <Switch
              checked={isChecked}
              onChange={e => setIsChecked(e)}
              disabled={productById?.process === productProcess.REJECT}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="English Title"
            name="title_en"
            rules={[{ required: true }]}
          >
            <>
              <Dropdown
                open={isDropDownOpened}
                menu={{
                  items: searchResult
                }}
                trigger={['click']}
              >
                <Input
                  value={searchedProduct}
                  onChange={e => {
                    setSearchedProduct(e.target.value);
                    handleProductSearch(e.target.value);
                    form.setFieldValue('title_en', searchedProduct);
                  }}
                  onFocus={() => {
                    setisDropDownOpened(true);
                    form.setFieldValue('title_en', searchedProduct);
                  }}
                  onBlur={() => {
                    form.setFieldValue('title_en', searchedProduct);
                    setisDropDownOpened(false);
                  }}
                  placeholder="Products"
                />
              </Dropdown>
            </>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            validateStatus={articleValidateStatus}
            label="Artcle Number"
            name="articul_number"
          >
            <OtherFormat
              // disabled={progress}
              inputMode="numeric"
              pattern="[0-9]*"
              onKeyDown={e => {
                if (
                  !(
                    e.key === 'Backspace' ||
                    e.key === 'Delete' ||
                    /^[0-9]$/.test(e.key)
                  )
                ) {
                  e.preventDefault();
                }
              }}
              placeholder="Example: 0015"
              addonBefore={article}
              suffix={
                <Tooltip title="Reset">
                  {progress ? (
                    <Spin />
                  ) : (
                    <UndoOutlined
                      onClick={() => {
                        setArticle(article);
                        form.setFieldsValue({
                          articul_number: productId
                            ? initialArticleNumber
                            : Math.floor(Math.random() * 9000) + 1000
                        });
                      }}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: 'transparent'
                      }}
                    />
                  )}
                </Tooltip>
              }
              onChange={e => handleArticleChange(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Code"
            validateStatus={codeValidateStatus}
            name="code"
          >
            <Input
              disabled
              placeholder="00001"
              type="text"
              inputMode="numeric"
              onChange={e => handleCodeChange(e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item label="Model" name="model" rules={[{ required: true }]}>
            <Input placeholder="Enter model" />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            label="Category"
            name="categories"
            rules={[{ required: true }]}
          >
            <TreeSelect
              loading={progress}
              onChange={onCategoryChange}
              treeData={getTreeData()}
              placeholder="Select Category"
              multiple
              treeCheckable
              treeCheckStrictly
              filterTreeNode={(input, treeNode) =>
                (treeNode.label?.toString().toLowerCase() ?? '').includes(
                  input.toLowerCase()
                )
              }
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Select Unit"
            name={'unit'}
            rules={[{ required: true }]}
            // initialValue={'km'}
          >
            <Select placeholder="km, m, pcs">
              <Option value="km">km</Option>
              <Option value="meter">meter</Option>
              <Option value="count">pcs</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Select Vendor"
            name={'vendor_id'}
            rules={[{ required: true }]}
          >
            <Select
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').includes(
                  input.toLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                optionA?.label
                  .toLowerCase()
                  .localeCompare(optionB?.label.toLowerCase())
              }
              showSearch
              placeholder="Select Vendor"
              options={vendorData.map(vendor => {
                return {
                  value: vendor.id,
                  label: vendor.name
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Select Country"
              optionLabelProp="label"
              optionFilterProp="label"
              options={countryData.map(country => ({
                value: country.id,
                label: country.name
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Youtube Embed Video Urls"
            name="youtube_urls"
            rules={[
              { required: false },
              {
                validator: (_, value) => validateYoutubeUrls(value)
              }
            ]}
          >
            <Select
              tokenSeparators={[',']}
              tagRender={TagRender}
              mode="tags"
              open={false}
              placeholder="Enter Youtube Video Url"
              showSearch={false}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Url"
            name="urls"
            rules={[
              { required: false },
              {
                validator: (_, value) => isValidUrl(value)
              }
            ]}
          >
            <Select
              tokenSeparators={[',']}
              tagRender={TagRender}
              mode="tags"
              open={false}
              placeholder="Enter Web-Page Url"
              showSearch={false}
            />
          </Form.Item>
        </Col>
        {/*<Form.Item label="Upload Logo or Sticker" name="logo_sticker">*/}
        {/*  <Uploader*/}
        {/*    maxCount={1}*/}
        {/*    onChange={onChangeImageUpload}*/}
        {/*    fileList={fileList}*/}
        {/*    customRequest={pictureUpload}*/}
        {/*    onRemove={onImageRemove}*/}
        {/*  ></Uploader>*/}
        {/*</Form.Item>*/}
        {/*<Form.Item label="Preview" name="html" style={{ marginLeft: '20px' }}>*/}
        {/*  {src ? (*/}
        {/*    <Image width={200} src={src} />*/}
        {/*  ) : (*/}
        {/*    'Logo or Sticker is not loaded'*/}
        {/*  )}*/}
        {/*</Form.Item>*/}

        <Col span={24}>
          <Form.Item
            rules={[requiredDescription]}
            label="Description"
            name="description1_en"
            required
          >
            <Editor setContent={setBodyDescription} content={bodyDescription} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            rules={[requiredFeatures]}
            label="Features"
            name="html"
            required
          >
            <Editor setContent={setBodyFeatures} content={bodyFeatures} />
          </Form.Item>
        </Col>
      </Row>
      <FloatButton.BackTop />
    </Form>
  );
};

export default Presales;
