import { Drawer, Input } from 'antd';
import styled from 'styled-components';

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-close {
    margin-bottom: 36px;
  }
`;

export const UploadWrraper = styled.div`
  height: 80px;
`;

export const ContentTopTitle = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
`;
export const ContentTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SearchStyled = styled(Input.Search)`
  max-width: 600px;
`;

export const AddButtonWrraper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 6px;
`;
