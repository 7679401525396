import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export const selectFilesLoading = () => (state: RootState) =>
  state.files.loading;

export const selectFilesLoadingAction = () => (state: RootState) =>
  state.files.loadingAction;

export const selectFileById = () => (state: RootState) => state.files.fileById;

export const selectFilesSearch = () => (state: RootState) => state.files.search;

export const selectFilesPage = () => (state: RootState) => state.files.page;

export const selectFilesLimit = () => (state: RootState) => state.files.limit;

export const selectFilesCount = () => (state: RootState) => state.files.count;

const selectFilesState = (state: RootState) => state.files;

// Select "invoice" files
export const selectInvoiceFiles = createSelector(
  [selectFilesState],
  filesState => filesState.invoiceFiles
);

// Select "picture" files
export const selectPicturesFiles = createSelector(
  [selectFilesState],
  filesState => filesState.picturesFiles
);
