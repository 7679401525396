import { RootState } from 'app/store';

export const selectAttributeData = () => (state: RootState) =>
  state.attribute.attribute;

export const selectAttributeLoading = () => (state: RootState) =>
  state.attribute.loading;

export const selectAttributeById = () => (state: RootState) =>
  state.attribute.attributeById;

export const selectAttributePage = () => (state: RootState) =>
  state.attribute.page;

export const selectAttributeSearch = () => (state: RootState) =>
  state.attribute.search;

export const selectAttributeLimit = () => (state: RootState) =>
  state.attribute.limit;

export const selectAttributeCount = () => (state: RootState) =>
  state.attribute.count;

export const selectAttributeLoadingAction = () => (state: RootState) =>
  state.attribute.loadingAction;

export const selectAttributeLoadingById = () => (state: RootState) =>
  state.attribute.loadingById;

export const selectAttributesByProductId = () => (state: RootState) =>
  state.attribute.productAttributes;
