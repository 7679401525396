import { Button, Typography } from 'antd';
import styled from 'styled-components';

export const ButtonLogin = styled(Button)`
  width: 100%;
`;
export const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
`;
export const FormContainer = styled.div`
  max-width: 600px;
  width: 100vw;
  height: fit-content;
  background-color: white;
  padding: 25px 15px;
  border-radius: 10px;
`;
export const Title = styled(Typography.Title)`
  text-align: center;
  font-weight: 900;
`;
