import { Modal, Tag, message } from 'antd';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { getFileUrl, getFilename, truncateString } from 'helpers/utils';
import { getFileById } from 'app/features/files/slice';
import { isUrl } from 'helpers/validators';
import { useAppDispatch } from 'app/hooks';
import { useState } from 'react';
import { GlobalOutlined, YoutubeFilled } from '@ant-design/icons';

import { TruncatedLabel } from './styled';

interface TagRenderProps extends CustomTagProps {
  customOnClose?: (id: string) => void;
  title?: string;
  deleteConfirmMessage?: string;
}

export const TagRender = (props: TagRenderProps) => {
  const {
    label,
    closable,
    customOnClose,
    onClose,
    value,
    deleteConfirmMessage,
    title
  } = props;

  const stringLabel = String(label);
  const normalizedLabel = getFilename(String(label));
  const [ytModalOpen, setYtModalOpen] = useState(false);

  const dispatch = useAppDispatch();

  const onTagClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    Modal.confirm({
      title: title || 'Delete Tag',
      content:
        deleteConfirmMessage || 'Are you sure you want to delete this tag?', // Use the custom message here
      onOk: () => {
        onClose();
        customOnClose && customOnClose(value);
      }
    });
  };

  const onTagClick = async () => {
    if (stringLabel.includes('iframe')) {
      setYtModalOpen(true);
    } else if (isUrl(stringLabel)) {
      window.open(stringLabel, '_blank');
    } else if (stringLabel.endsWith('.doc') || stringLabel.endsWith('.docx')) {
      window.open(getFileUrl(stringLabel), '_blank');
    } else if (stringLabel.endsWith('.pdf')) {
      try {
        const response = await dispatch(getFileById(value));
        const fileData = response.payload;
        const url = getFileUrl(fileData.path);

        window.open(url);
      } catch (error) {
        message.error(`Error fetching file data: ${error}`);
      }
    }
  };

  return (
    <div>
      <Tag
        closable={closable}
        onClose={onTagClose}
        onClick={onTagClick}
        style={{
          cursor: 'pointer',
          fontSize: 14,
          background: '#0000001c',
          marginRight: 3
        }}
      >
        <TruncatedLabel>
          {stringLabel.includes('iframe') ? (
            <>
              Video <YoutubeFilled />
            </>
          ) : stringLabel.includes('https') ? (
            <>
              {(() => {
                const url = new URL(stringLabel);

                return (
                  <>
                    {`${url.hostname.replace(/^www\./, '')}${url.pathname}`}{' '}
                    <GlobalOutlined />
                  </>
                );
              })()}
            </>
          ) : (
            truncateString(normalizedLabel, 38)
          )}
        </TruncatedLabel>
      </Tag>
      <Modal
        title="Youtube Video"
        width="603px"
        style={{ top: 10 }}
        open={ytModalOpen}
        footer={null}
        onCancel={() => setYtModalOpen(false)}
      >
        <div
          style={{ width: '800px' }}
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      </Modal>
    </div>
  );
};
