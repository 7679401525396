import { RootState } from 'app/store';

export const selectVendorData = () => (state: RootState) => state.vendor.vendor;

export const selectVendorLoading = () => (state: RootState) =>
  state.vendor.loading;

export const selectVendorsSearch = () => (state: RootState) =>
  state.vendor.search;

export const selectVendorsPage = () => (state: RootState) => state.vendor.page;

export const selectVendorsLimit = () => (state: RootState) =>
  state.vendor.limit;

export const selectVendorsCount = () => (state: RootState) =>
  state.vendor.count;

export const selectVendorById = () => (state: RootState) =>
  state.vendor.vendorById;

export const selectVendorLoadingAction = () => (state: RootState) =>
  state.vendor.loadingAction;

export const selectVendorLoadingById = () => (state: RootState) =>
  state.vendor.loadingById;

export const selectOptowirePriceById = () => (state: RootState) =>
  state.vendor.optowire_price;
