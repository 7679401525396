/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  UndoOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  FloatButton,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
  // Upload,
  message
} from 'antd';
import {
  selectInvoicesByProductId,
  selectProductLoadingAction
} from 'app/features/products/selectors';
import { useBlocker, useNavigate } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { IProductLogisticsEditValues } from 'app/features/products/types';
import NumericInput, {
  cleanAndParse,
  parseInputValue
} from 'helpers/NumericInput';
import { selectPricesByProductId } from 'app/features/productCountries/selectors';
import { getPricesByProductId } from 'app/features/productCountries/slice';
import { productEdit } from 'app/features/products/slice';
import { mapInputValue, trimExcessZeros } from 'helpers/utils';
import { decimalPrecisionRule, isNotZeroRule } from 'helpers/validators';
import COUNTRIES from 'constants/countries';
import { getCbmCountries } from 'app/features/cbmCountries/slice';
import { selectCbmCountryData } from 'app/features/cbmCountries/selectors';
import Uploader from 'Hook/uploadrer';

import { CtryPrcFormList, ILogistics } from './types';
import Editor from 'components/Editor';
import { TagRender } from '../TagRender';
import { OtherFormat } from '../AttributeSelect/styled';
import { separateTextAndNumber } from '../AttributeSelect/constant';
import LogisticsForm from './components/logisticsForm';

const validateMessages = {
  required: "'${label}' is Required!"
};

// const { Dragger } = Upload;

const Logistics: FC<ILogistics> = ({
  // onChangeImageUpload,
  setBodyFeatures,
  bodyFeatures,
  setBodyDescription,
  bodyDescription,
  productById,
  form,
  fileList,
  attributes,
  setArticle,
  article,
  productId
}) => {
  const loadingAction = useAppSelector(selectProductLoadingAction());
  const productCountriesData = useAppSelector(selectPricesByProductId());
  const productContractFiles = useAppSelector(selectInvoicesByProductId());
  const cbmCountryData = useAppSelector(selectCbmCountryData());
  const countriesData = COUNTRIES;
  const [initialArticleNumber, setinitialArticleNumber] = useState('');
  const [cbmInputDisabled, setCbmInputDisabled] = useState<boolean>(true);
  const [isNavModalOpen, setIsNavModalOpen] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const isSubmitted = form.getFieldsValue().submitted;
    const touchedNotSubmitted = isFormTouched && !isSubmitted;

    return (
      touchedNotSubmitted && currentLocation.pathname !== nextLocation.pathname
    );
  });

  useEffect(() => {
    dispatch(getCbmCountries({ limit: 50 }));
  }, [dispatch, navigate]);

  const handleOk = () => {
    setIsNavModalOpen(false);
    if (blocker && blocker.proceed) blocker.proceed();
  };

  const handleCancel = () => {
    setIsNavModalOpen(false);
    if (blocker && blocker.reset) blocker.reset();
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsNavModalOpen(true);
    }
  }, [blocker.state, isFormTouched]);

  useEffect(() => {
    const handleBeforeUnload = (event: { preventDefault: () => void }) => {
      const isSubmitted = form.getFieldsValue().submitted;
      const touchedNotSubmitted = isFormTouched && !isSubmitted;

      if (touchedNotSubmitted) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormTouched, form]);

  // const delay = _debounce(1000);

  useEffect(() => {
    if (productId && productById && productCountriesData) {
      const images = productById.files;
      const files = productContractFiles;
      const result = separateTextAndNumber(productById.articul_number);
      const countryIds = productCountriesData.map(item => item.country_id);
      const cbm = cleanAndParse(productById.cbm) || 0;
      const countries =
        cbmCountryData.filter(country => countryIds.includes(country.id)) || [];

      const additionalValues = countries.map(country => ({
        ...(country.cbm_country && {
          countryPrice: parseInputValue(country.cbm_country?.price),
          countryId: { value: country.id, label: country.name }
        })
      }));

      const ctryPrcFormList: CtryPrcFormList[] = productCountriesData.map(
        data => ({
          percentOrFix: data.navy_price_obj?.tax_fix ? 'fixPrice' : 'percent',
          itemId: data.id,
          countryId: {
            value: data.country_id,
            label:
              countriesData.find(country => country.id === data.country_id)
                ?.name ?? 'No country'
          },
          navySubtotal: mapInputValue(
            cbm > 0
              ? cbm *
                  cleanAndParse(
                    additionalValues?.find(
                      item => item?.countryId?.value === data?.country_id
                    )?.countryPrice || undefined
                  )
              : undefined
          ),
          countryPrice:
            parseInputValue(
              String(
                additionalValues?.find(
                  item => item?.countryId?.value === data?.country_id
                )?.countryPrice
              )
            ) || undefined,
          standardPrice: mapInputValue(data.total_navy_price),
          percentOrFixManual: data.navy_price_obj?.tax_fix_manual
            ? 'fixPrice'
            : 'percent',
          percentOrFixPlane: data.plane_price_obj?.tax_fix_plane
            ? 'fixPrice'
            : 'percent',
          // percentOrFixValue:
          //   (data.logistics_type === 'NAVY_CBM' &&
          //     mapInputValue(data.navy_price_obj?.tax_percent)) ||
          //   mapInputValue(data.navy_price_obj?.tax_fix) ||
          //   undefined,
          percentOrFixValue:
            data.logistics_type === 'NAVY_CBM'
              ? mapInputValue(
                  data.navy_price_obj?.tax_percent ||
                    data.navy_price_obj?.tax_fix
                )
              : undefined,
          otherCost:
            data.logistics_type === 'NAVY_CBM'
              ? mapInputValue(data.navy_price_obj?.other_cost)
              : undefined,
          percentOrFixValueManual:
            data.logistics_type === 'NAVY_MANUAL'
              ? mapInputValue(
                  data.navy_price_obj?.tax_percent ||
                    data.navy_price_obj?.tax_fix
                )
              : undefined,
          // percentOrFixValueManual:
          //   (data.logistics_type === 'NAVY_MANUAL' &&
          //     mapInputValue(data.navy_price_obj?.tax_percent)) ||
          //   mapInputValue(data.navy_price_obj?.tax_fix) ||
          //   undefined,
          lengthCm: mapInputValue(data.plane_price_obj?.length),
          cbmWeightKg: mapInputValue(data.plane_price_obj?.cbm_weight),
          expressPrice: mapInputValue(data.total_plane_price),
          subtotal: mapInputValue(data.plane_price_obj?.subtotal),
          widthCm: mapInputValue(data.plane_price_obj?.width),
          heightCm: mapInputValue(data.plane_price_obj?.height),
          weightKg: mapInputValue(data.plane_price_obj?.weight),
          pricePerKg: mapInputValue(data.plane_price_obj?.price_per_kg),
          otherCostPlane: mapInputValue(data.plane_price_obj?.other_cost_plane),
          percentOrFixValuePlane:
            mapInputValue(data.plane_price_obj?.tax_percent_plane) ||
            mapInputValue(data.plane_price_obj?.tax_fix_plane),
          commentNavy: data.navy_comment,
          commentPlane: data.plane_comment,
          cost: mapInputValue(data.navy_price_obj?.cost),
          otherCostManual:
            data.logistics_type === 'NAVY_MANUAL'
              ? mapInputValue(data.navy_price_obj?.other_cost)
              : undefined,
          manualCheckbox: data.logistics_type === 'NAVY_MANUAL' ? true : false,
          navyCheckbox: data.logistics_type === 'NAVY_CBM' ? true : false
        })
      );

      form.setFieldsValue({
        title_en: productById.title_en,
        articul_number: result.number,
        cbm:
          productById.cbm > 0
            ? trimExcessZeros(parseInputValue(String(productById.cbm)) || '')
            : '',
        pre_sales_price: parseInputValue(productById.pre_sales_price),
        files: files.map(file => ({
          value: file.id,
          label: file.path
        })),
        images,
        ctryPrcFormList,
        kg: parseInputValue(String(productById.kg))
      });
      setArticle(result.text);
      setinitialArticleNumber(result.number);
    }
  }, [productId, productCountriesData, cbmCountryData, productById]);

  useEffect(() => {
    if (productId) dispatch(getPricesByProductId(productId));
  }, []);

  const onFinish = async (values: IProductLogisticsEditValues) => {
    const { cbm, kg } = values;
    // const images = productById?.files;
    const files = productContractFiles;
    const combinedFiles = [
      // ...(images || []).filter(Boolean),
      ...(files || []).filter(Boolean)
    ];

    if (productId && productById) {
      const attributesPost = attributes.map(attribute => ({
        attribute_id: attribute.attributeId,
        type: attribute.value?.type,
        ...(attribute.value?.type === 'enum'
          ? { enum_value: attribute.value?.enumValue }
          : {
              min: Number(attribute.value?.numberRange?.min),
              max: Number(attribute.value?.numberRange?.max)
            })
      }));

      const dataProduct = {
        cbm: cleanAndParse(cbm),
        categories: productById.categories?.map(item => Number(item.id)),
        files: combinedFiles.map(file => file.id),
        attributes: attributesPost,
        ...(kg && { kg: cleanAndParse(kg) })
      };

      try {
        await dispatch(
          productEdit({
            productId,
            dataProduct: {
              ...dataProduct
            }
          })
        );
        // onClose();
        // activeKey('nestedForm');
      } catch (error) {
        message.error(`An error occurred: ${error}`, 2);
      }
    }
  };

  // const handleEditAll = async () => {
  //   const countryPriceForm = form.getFieldValue('countryPriceForm');
  //   const fieldNames = Object.keys(countryPriceForm);

  //   const updatedEditStates = fieldNames.reduce(
  //     (acc, fieldName) => {
  //       acc[fieldName] = !editStates[fieldName];

  //       return acc;
  //     },
  //     { ...editStates }
  //   );

  //   setEditStates(updatedEditStates);
  //   // setSubmitMode(true);
  // };

  return (
    <Form
      validateMessages={validateMessages}
      size="large"
      form={form}
      name="formLogistics"
      layout="vertical"
      onValuesChange={changedValues => {
        setIsFormTouched(changedValues ? true : false);
        form.setFieldsValue({
          ...form.getFieldsValue(),
          isFormTouched: changedValues ? true : false
        });

        !!changedValues &&
          form.setFieldsValue({ ...form.getFieldsValue(), submitted: false });
      }}
      initialValues={{
        countryPriceForm: [{}]
      }}
      onFinish={onFinish}
      // onFinish={onFinish}
      scrollToFirstError={{
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      }}
    >
      <Modal
        title="You have unsaved changes. The changes will be discarded if you leave without saving. Do you want to save them ?"
        open={isNavModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Leave and discard changes'}
        closable={false}
        cancelText={'Stay to save changes'}
        okButtonProps={{ type: 'default' }}
        cancelButtonProps={{ type: 'primary' }}
      ></Modal>
      <Col>
        <Form.Item initialValue={true} hidden name="submitted" />
      </Col>
      <Col>
        <Form.Item initialValue={true} hidden name="isFormTouched" />
      </Col>
      <Row gutter={[24, 24]}>
        <Col span={7}>
          <Form.Item
            label="English Title"
            name="title_en"
            rules={[{ required: true }]}
          >
            <Input placeholder="Enter title in English" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            // hasFeedback
            // validateStatus={articleValidateStatus}
            label="Artcle Number"
            name="articul_number"
          >
            <OtherFormat
              disabled={true}
              placeholder="Example: 0015"
              addonBefore={article}
              type="text"
              suffix={
                <Tooltip title="Reset">
                  <UndoOutlined
                    onClick={() => {
                      setArticle(article);
                      form.setFieldsValue({
                        articul_number: initialArticleNumber
                      });
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: 'transparent'
                    }}
                  />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item
            rules={[
              isNotZeroRule,
              decimalPrecisionRule,
              {
                required: true
              }
            ]}
            label="Cb㎥"
            name="cbm"
          >
            <NumericInput
              name="cbm"
              disabled={cbmInputDisabled}
              placeholder="Size ㎥"
              suffix={
                <>
                  <Button
                    style={{ marginRight: 1 }}
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (cbmInputDisabled) {
                        setCbmInputDisabled(!cbmInputDisabled);
                      } else {
                        Modal.confirm({
                          title: 'Confirm CBM Edit',
                          content:
                            'Are you sure you want to edit the Product CBM? This action will require recalculation of all existing country prices.',
                          onOk: () => {
                            form.submit();
                            setCbmInputDisabled(!cbmInputDisabled);
                          }
                        });
                      }
                    }}
                  >
                    {cbmInputDisabled ? <EditOutlined /> : <CheckOutlined />}
                  </Button>
                  {!cbmInputDisabled && (
                    <Button
                      danger
                      size="small"
                      onClick={() => {
                        productById &&
                          form.setFieldsValue({
                            cbm: trimExcessZeros(
                              parseInputValue(String(productById.cbm)) || ''
                            )
                          });
                        setCbmInputDisabled(!cbmInputDisabled);
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                  )}
                </>
              }
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item rules={[isNotZeroRule]} name="kg" label="Weight">
            <NumericInput placeholder="KG" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="pre_sales_price" label="Pre Sales Price">
            <NumericInput
              // value={presalesPriceValue}
              // onChange={() => setPresalesPriceValue}
              placeholder="Pre Sales Price"
              disabled={true}
            />
          </Form.Item>
        </Col>

        <LogisticsForm form={form} productId={productId} />

        <Col span={12}>
          <Form.Item
            label="Product Datasheet"
            name={'files'}
            rules={[{ required: false }]}
          >
            <Select
              disabled
              mode="multiple"
              loading={loadingAction}
              placeholder="Select Contract"
              // defaultValue={id && contractFileList}
              optionLabelProp="label"
              optionFilterProp="label"
              tokenSeparators={[',']}
              tagRender={TagRender}
              // onSelect={value => handleFileClick(value)}
            ></Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="images"
            label="Product Images"
            getValueFromEvent={event => event?.fileList || []}
          >
            <Uploader
              disabled
              // accept="image/*"
              // onChange={onChangeImageUpload}
              fileList={fileList}
              // customRequest={pictureUpload}
              // onRemove={onImageRemove}
            />
            {/* <Dragger
              disabled
              // onChange={onChangeImageUpload}
              style={{ height: 0, marginBottom: 20 }}
              height={0}
              fileList={fileList}
              accept="image/*"
              listType="picture-card"
              multiple
            ></Dragger> */}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Description" name="description1_en">
            <Editor
              setContent={setBodyDescription}
              content={bodyDescription}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Features" name="html">
            <Editor
              setContent={setBodyFeatures}
              content={bodyFeatures}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <FloatButton.BackTop />
    </Form>
  );
};

export default Logistics;
