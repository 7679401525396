import styled from 'styled-components';
import { Tag } from 'antd';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const ImgTableWrraper = styled.div`
  border-radius: 8px;
  overflow: hidden;

  img,
  .ant-image-mask {
    border-radius: 8px;
  }
`;

export const NameLink = styled.a`
  color: rgba(0, 0, 0, 0.88);
  text-decoration: underline;

  :hover {
    color: rgba(0, 0, 0, 0.88);
  }
`;

export const DescriptionWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  max-height: 4.6em;
  line-height: 1.4em;
`;

export const IconWrapper = styled(Tag)`
  margin: 8px 0 0 0;
  width: fit-content;

  span {
    margin: 0;
  }
`;
