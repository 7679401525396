import styled from 'styled-components';

export const WelcomeTextWrapper = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
`;

export const WelcomeHeader = styled.h1`
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
`;

export const WelcomeSubtext = styled.p`
  font-size: 18px;
  color: #666;
`;
