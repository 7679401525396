// import {} from 'app/features/country/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import {
  deleteAccessory,
  // editAccessories,
  getAccessoriesByCategoryId,
  getAccessoryById,
  // postAccessories,
  setLimit,
  setPage,
  setSearch
} from 'app/features/accessories/slice';
import {
  selectAccessoriesByCategoryId,
  // selectAccessoryById,
  selectAccessoryCount,
  selectAccessoryLimit,
  // selectAccessoryLoading,
  selectAccessoryLoadingAction,
  selectAccessoryLoadingById,
  selectAccessoryPage,
  selectAccessorySearch
} from 'app/features/accessories/selectors';
import {
  AccessoriesByCategoryId
  // Accessory,
  // AccessoryPostValues
} from 'app/features/accessories/types';
import Table, { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { ButtonsWrapper } from 'pages/Categories/styled';
import ContentTop from 'components/ContentTop';
import AccessoryModal from 'pages/AccessoriesDetails/components/AccessoryModal';
import DeleteModal from 'components/DeleteModal';

const AccessoriesDetails = () => {
  const { id } = useParams();
  const accessoryCategoryId = Number(id);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [showAccessoryModal, setShowAccessoryModal] = useState(false);
  const [accessoryId, setAccessoryId] = useState<number>(Number(id));

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const accessoriesByCategoryId = useAppSelector(
    selectAccessoriesByCategoryId()
  );

  const page = useAppSelector(selectAccessoryPage());
  const limit = useAppSelector(selectAccessoryLimit());
  const count = useAppSelector(selectAccessoryCount());
  const search = useAppSelector(selectAccessorySearch());
  const loadingAction = useAppSelector(selectAccessoryLoadingAction());
  const loadingById = useAppSelector(selectAccessoryLoadingById());

  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    setShowAccessoryModal(false);
    setEditMode(false);
    setAccessoryId(0);
  };

  useEffect(() => {
    if (id) {
      dispatch(getAccessoriesByCategoryId(Number(id)));
    }
  }, [dispatch, id, search, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const onAddAccessory = () => {
    setShowAccessoryModal(true);
    setEditMode(false);
  };

  const onEditAccessory = (id: number) => {
    dispatch(getAccessoryById(id));
    setAccessoryId(id);
    setShowAccessoryModal(true);
    setEditMode(true);
  };

  const handleDeleteAccessory = async () => {
    await dispatch(deleteAccessory({ accessoryId, accessoryCategoryId }));
    setOpenDeleteModal(false);
    setAccessoryId(0);
  };

  // const onSave = async (values: AccessoryPostValues) => {
  //   const { price, title } = values;

  //   // console.log(values, 'onSave', 'values');
  //   // const files = fileList
  //   //   .map(item => item.response?.data?.data?.id || item.uid)
  //   //   .filter(item => !!item);
  //   // try {
  //   //   const data = {
  //   //     title,
  //   //     price
  //   //     // files
  //   //   };

  //   //   if (id) {
  //   //     await dispatch(editAccessories({ id, ...data }));
  //   //     message.success({
  //   //       content: `${name} Vendor Updated successfully`
  //   //     });
  //   //   } else {
  //   //     await dispatch(postAccessories(data));

  //   //     setAccessoryIdId(vendorId);
  //   //   }

  //   //   // onClose();
  //   // } catch (error) {
  //   //   Modal.error({
  //   //     title: 'Error',
  //   //     content: `Error saving vendor:, ${error}`
  //   //   });
  //   // }
  // };

  const columns: ColumnsType<AccessoriesByCategoryId> = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                onEditAccessory(record.id);
              }}
            >
              Edit Accessory
            </Button>
            {/* <Button
              icon={<EditOutlined />}
              onClick={() => {
                onEditAccessory(record.id);
              }}
            >
              Edit Accessorie
            </Button> */}
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setAccessoryId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Accessories"
        // categoryTitle={
        //   accessoriesByCategoryId?.find(
        //     acc => acc?.accessory_category?.id === accessoryCategoryId
        //   )?.accessory_category?.title
        // }
        placeholder="Search Accessories"
        buttonText="Add Accessory"
        onClickAdd={onAddAccessory}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={accessoriesByCategoryId}
        loading={loadingById}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
      <AccessoryModal
        isOpen={showAccessoryModal}
        // isOpen={showAccessoryCategoryModal}
        onClose={onClose}
        params={{
          accessoryCategoryId,
          accessoryId,
          editMode
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={handleDeleteAccessory}
        onCancel={() => {
          setOpenDeleteModal(false);
          setAccessoryId(0);
        }}
      />
    </>
  );
};

export default AccessoriesDetails;
