import { message } from 'antd';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import {
  selectSectionLimit,
  selectSectionPage,
  selectSectionSearch
} from './selectors';
import { IPostSectionProduct, ISectionById, SectionState } from './types';

const initialState: SectionState = {
  section: [],
  sectionById: null,
  loading: false,
  loadingById: false,
  loadingAction: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const sectionApi = createAsyncThunk(
  'section/sectionApi',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectSectionPage()(state);
    const search = selectSectionSearch()(state);
    const limit = selectSectionLimit()(state);
    // const body = '';
    // const params = {
    //   page,
    //   search,
    //   limit
    // };

    try {
      const res = await client.get(`/section`, {
        params: {
          search,
          page,
          limit
        }
      });
      // const res = await client.post(`/section/explore`, body, { params });

      return dispatch(setSection(res.data.payload));
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const deleteSection = createAsyncThunk(
  'section/deleteSection',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/section/${id}`);

      await dispatch(sectionApi());
      message.success('Section Deleted Successfully');
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteSectionProduct = createAsyncThunk(
  'section/deleteSectionProduct',
  async (
    { sectionId, productId }: { sectionId: number; productId: number[] },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.delete(
        `/section/product/${Number(sectionId)}/${Number(productId)}`
      );
      dispatch(getSectionById(Number(sectionId)));
      message.success('Product Deleted Successfully');
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const sectionEdit = createAsyncThunk(
  'section/sectionEdit',
  async ({ id, ...data }: ISectionById, { dispatch, rejectWithValue }) => {
    try {
      await client.patch(`/section/${id}`, {
        ...data
      });

      await dispatch(sectionApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getSectionById = createAsyncThunk(
  'section/getSectionById',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await client.get(`/section/${id}`);

      return dispatch(setSectionById(response.data.payload));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postSection = createAsyncThunk(
  'section/postSection',
  async ({ ...data }: ISectionById, { dispatch, rejectWithValue }) => {
    try {
      await client.post('/section/create', {
        ...data
      });
      await dispatch(sectionApi());
      message.success('Section Created Successfully!');
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

// export const postSectionProduct = createAsyncThunk(
//   'section/postSectionProduct',
//   async (values: IPostSectionProduct[], { rejectWithValue }) => {
//     try {
//       await client.post(`/section/product`, values);
//     } catch (error) {
//       return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
//     }
//   }
// );

export const postSectionProduct = createAsyncThunk(
  'section/postSectionProduct',
  async (
    { sectionId, ...data }: IPostSectionProduct,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.post(`/section/product/${sectionId}`, data);
      message.success('Product added successfully');
      dispatch(getSectionById(Number(sectionId)));
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setSection: (state, action) => {
      state.section = action.payload.rows;
      state.count = action.payload.count;
    },
    setSectionById: (state, action) => {
      state.sectionById = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(sectionApi.pending, state => {
        state.loading = true;
      })
      .addCase(sectionApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(sectionApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getSectionById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getSectionById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getSectionById.rejected, state => {
        state.loadingById = true;
      })
      .addCase(postSection.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postSection.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postSection.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSection.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteSection.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSection.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(sectionEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(sectionEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(sectionEdit.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSectionById, setSearch, setSection, setLimit } =
  sectionSlice.actions;
export default sectionSlice.reducer;
