import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Modal,
  message,
  Typography,
  Checkbox,
  Radio,
  Form
} from 'antd';
import {
  selectOptowirePriceById,
  selectVendorData
} from 'app/features/vendor/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';

import {
  SaveOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  StarFilled
} from '@ant-design/icons';
import { fetchInvoiceFilesApi, setSearch } from 'app/features/files/slice';
import {
  deleteVendorFormItem,
  editVendorForm,
  postVendorForm,
  vendorFormApi
} from 'app/features/vendorForm/slice';
import {
  selectNewProduct,
  selectProductLoadingAction
} from 'app/features/products/selectors';
import {
  IFilesData,
  IPostmanRequestBody,
  IProductValues,
  IVendorFormPost
} from 'app/features/vendorForm/types';
import { _debounce, getFilename } from 'helpers/utils';
import { useCustomRequest } from 'Hook/useCustomRequest';
import Dragger from 'antd/es/upload/Dragger';
import { useParams } from 'react-router-dom';
import { selectVendorFormData } from 'app/features/vendorForm/selectors';
import { optowirePricePost } from 'app/features/products/slice';
import { selectFilesLoading } from 'app/features/files/selectors';
import NumericInput, { cleanAndParse } from 'helpers/NumericInput';
import { getOptowirePriceByProductId } from 'app/features/vendor/slice';
import { isNotZeroRule } from 'helpers/validators';
import { getPricesByProductId } from 'app/features/productCountries/slice';
import { selectPricesByProductId } from 'app/features/productCountries/selectors';
import { handleBackendValidationError } from 'helpers/errors';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { TagRender } from '../TagRender';
import { IVendorForm } from './types';
import { minOfVendorPrices } from '../../../../helpers/priceCalculations';
import Loading from '../../../../components/Loading';
import { getChangedItems } from '../../../../helpers/array';

const validateMessages = {
  required: "'${label}' is Required!"
};

interface VendorFormValues {
  vendorForms?: IVendorFormPost[];
  pre_sales_price?: string;
}

const VendorForm: FC<IVendorForm> = ({
  contractFileList,
  productId,
  productById,
  onChangeFileUpload,
  setActiveTabKey,
  onClose
}) => {
  const { control, handleSubmit, watch, setValue, getValues } =
    useForm<VendorFormValues>({
      defaultValues: {
        vendorForms: [],
        pre_sales_price: ''
      }
    });

  const {
    fields: vendorFormsHookData,
    append,
    update,
    remove
  } = useFieldArray({
    control,
    name: 'vendorForms'
  });

  const [editStates, setEditStates] = useState<{
    [fieldName: number]: boolean;
  }>({});

  const [submitMode, setSubmitMode] = useState<boolean>(false);
  const [vendorId, setVendorId] = useState<string>('');
  const dispatch = useAppDispatch();
  const vendorData = useAppSelector(selectVendorData());
  const vendorFormData = useAppSelector(selectVendorFormData());
  const newProduct = useAppSelector(selectNewProduct());
  const loadingAction = useAppSelector(selectProductLoadingAction());
  const loading = useAppSelector(selectFilesLoading());
  const optowirePrice = useAppSelector(selectOptowirePriceById());

  const [checkedIndex, setCheckedIndex] = useState<number | null>(null);
  const delay = _debounce(300);

  const { id } = useParams();
  const { Text } = Typography;

  const isAvailable = !!productById?.availability;
  const productCountriesData = useAppSelector(selectPricesByProductId());

  useEffect(() => {
    if (productId) {
      dispatch(vendorFormApi(productId));
      dispatch(getOptowirePriceByProductId(productId));
      dispatch(getPricesByProductId(productId));
    }

    dispatch(fetchInvoiceFilesApi({ hide: true }));
  }, [productId, dispatch]);

  useEffect(() => {
    setValue('pre_sales_price', '');

    if (productId && productById) {
      const vendorForms = vendorFormData?.flatMap(({ productVendors }) =>
        productVendors.map(
          ({ title, price, vendorId, files, id, is_main_vendor }) => ({
            title,
            is_main_vendor: is_main_vendor ?? false,
            price: price,
            vendorId,
            vendorFiles: files.map(file => ({
              value: file.id,
              label: file.path
            })),
            itemId: id
          })
        )
      );

      setValue('vendorForms', vendorForms as any);
      const vendorFormValues = getValues('vendorForms');

      if (vendorFormValues) {
        const currentVendorForms = vendorFormValues ?? [];
        const mainVendorPrice = currentVendorForms.find(
          item => item.is_main_vendor
        )?.price;

        if (mainVendorPrice)
          setValue('pre_sales_price', String(mainVendorPrice ?? 0));
      }
    }
  }, [productById, productId, vendorFormData, setValue, vendorId, getValues]);

  const fileUpload = useCustomRequest({
    url: 'file/createInvoiceFile',
    vendorId,
    productId,
    hide: true
  });

  const setMainField =
    (index: number, field: any) => (event: CheckboxChangeEvent) => {
      const { checked } = event.target;

      if (!checked) {
        field.onChange(event);

        return;
      }

      const currentVendorForms = getValues('vendorForms') ?? [];
      const updatedVendorForms = currentVendorForms.map((vendorForm, idx) => ({
        ...vendorForm,
        is_main_vendor: idx === index
      }));

      const mainVendorPrice = updatedVendorForms.find(
        item => item.is_main_vendor
      )?.price;

      const cleanedPrice = mainVendorPrice
        ? parseFloat(String(mainVendorPrice).replace(/,/g, ''))
        : 0;

      setValue('vendorForms', updatedVendorForms);
      setValue('pre_sales_price', String(cleanedPrice));
    };

  async function handleSave(data: any) {
    const vendorForms: any[] = data.vendorForms; // Assuming data is an array of vendor forms
    const dataVendorFormEdit: any = {
      pre_sales_price: Number(data.pre_sales_price),
      product_vendors: []
    };

    vendorForms?.forEach((item: any) => {
      const vendorFiles = item.vendorFiles ?? [];
      const uploadedVendorFiles = item.uploadedVendorFiles ?? [];
      const contractFiles: string[] =
        vendorFiles?.flatMap((file: any) => (file.value ? file.value : [])) ??
        [];

      const updatedContractFiles: any[] =
        vendorFiles?.filter((file: any) => typeof file !== 'object') ?? [];

      const newContractFileIds: string[] =
        uploadedVendorFiles?.flatMap(
          (file: any) =>
            file?.response?.data?.payload?.map(
              (item: { id: string }) => item.id
            ) ?? []
        ) ?? [];

      const combinedFiles: string[] = [
        ...(newContractFileIds ?? []),
        ...(contractFiles ?? []),
        ...(updatedContractFiles ?? [])
      ];

      dataVendorFormEdit.product_vendors.push({
        id: item.itemId,
        price: item.price ? cleanAndParse(item.price) : 0,
        title: item.title as string,
        is_main_vendor: item.is_main_vendor,
        vendor_id: item.vendorId,
        files: combinedFiles?.map((file: any) => file) || []
      });
    });

    if (productId) {
      try {
        await dispatch(
          editVendorForm({
            productId,
            dataVendorFormEdit
          })
        );
      } catch (error) {
        console.error('Error editing vendor form:', error);
      }
    }

    if (vendorForms.length) {
      const vendorPrice = vendorForms.find(item => item.is_main_vendor).price;

      if (productById?.main_vendor.name === 'Optowire') {
        Modal.confirm({
          title: `Do you want to set ${vendorPrice} as price for Optowire?`,
          onOk: async () => {
            try {
              if (id && productId) {
                await dispatch(
                  optowirePricePost({
                    productId: productId,
                    optowire_price: vendorPrice
                  })
                );
              } else if (newProduct && newProduct.dataValues.id) {
                await dispatch(
                  optowirePricePost({
                    productId: newProduct.dataValues.id,
                    optowire_price: vendorPrice
                  })
                );
              }
            } catch (error) {
              message.error(`An error occurred: ${error}`, 2);
            }
          }
        });
      }
    }
  }

  const contractOptions = contractFileList.map(option => ({
    value: option.uid,
    label: getFilename(option.name)
  }));

  if (loading) {
    return <Loading size="large" />;
  }

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div
            style={{
              border: '2px solid #d9d9d9',
              borderRadius: '8px',
              padding: '30px'
            }}
          >
            <Row gutter={[24, 24]}>
              {isAvailable && (
                <Col span={20} offset={2}>
                  <Text style={{ marginLeft: 35 }} strong type="danger">
                    You cant make any changes while the product is active. To
                    modify it, first, deactivate the product from the Presales
                    tab.
                  </Text>
                </Col>
              )}
              {!isAvailable && !!productCountriesData?.length && (
                <Col span={20} offset={6}>
                  <Text strong type="danger">
                    Price modification is not allowed as there is an existing
                    logistics record.
                  </Text>
                </Col>
              )}
              <Col span={18} offset={3}>
                <Controller
                  name="pre_sales_price"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <NumericInput
                      {...field}
                      disabled
                      addonBefore={'Presales Price Calc:'}
                      placeholder="Calculation"
                    />
                  )}
                />
              </Col>
            </Row>

            <Divider type="horizontal" />

            <Row gutter={[16, 16]}>
              {vendorFormsHookData.map((vendorFormHookItem, index) => (
                <Col span={24} key={vendorFormHookItem.id}>
                  <Row gutter={[16, 16]}>
                    <Col span={2}>
                      <Form.Item label="Main Vendor" layout="vertical">
                        <Controller
                          name={`vendorForms.${index}.is_main_vendor`}
                          control={control}
                          render={({ field: currentField }) => (
                            <Checkbox
                              {...currentField}
                              checked={currentField.value}
                              onChange={setMainField(index, currentField)}
                              style={{
                                visibility:
                                  index !== checkedIndex ? 'visible' : 'hidden'
                              }}
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Select Vendor" layout="vertical">
                        <Controller
                          name={`vendorForms.${index}.vendorId`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              // disabled={isVendorIdDisabled && !isEditDisabled}
                              optionLabelProp="label"
                              optionFilterProp="label"
                              showSearch
                              placeholder="Select Vendor"
                              options={vendorData.map(vendor => ({
                                value: vendor.id,
                                label: getFilename(vendor.name)
                              }))}
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Vendor Title" vertical>
                        <Controller
                          name={`vendorForms.${index}.title`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder="Enter Vendor Title"
                              disabled={isAvailable}
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label="Vendor price" vertical>
                        <Controller
                          name={`vendorForms.${index}.price`}
                          control={control}
                          render={({ field }) => (
                            <NumericInput
                              {...field}
                              value={String(field.value)}
                              onChange={value => field.onChange(value)}
                              placeholder="Vendor Price"
                              disabled={isAvailable}
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Vendor datasheet" layout="vertical">
                        <Controller
                          name={`vendorForms.${index}.vendorFiles`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              tagRender={TagRender}
                              {...field}
                              mode="multiple"
                              allowClear
                              showSearch
                              // maxTagCount="responsive"
                              placeholder="Select uploaded files"
                              options={contractOptions}
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          )}
                        />
                      </Form.Item>

                      <Controller
                        control={control}
                        name={`vendorForms.${index}.uploadedVendorFiles`}
                        render={({ field }) => (
                          <Form.Item
                            {...field}
                            getValueFromEvent={event => {
                              return event?.fileList || [];
                            }}
                          >
                            <Dragger
                              customRequest={options => {
                                fileUpload(options);
                                setVendorId(vendorId);
                              }}
                              accept=".pdf"
                              multiple
                              onChange={info => {
                                field.onChange(info.fileList);
                                onChangeFileUpload(info);
                              }}
                              style={{
                                height: '80%'
                              }}
                            >
                              <p
                                style={{
                                  fontSize: '13px'
                                }}
                              >
                                Click or drag file to this area to upload
                              </p>
                            </Dragger>
                          </Form.Item>
                        )}
                      />
                    </Col>

                    <Col span={2}>
                      <Form.Item label="Remove" layout="vertical">
                        <Button
                          onClick={() => {
                            Modal.confirm({
                              title: 'Confirm',
                              content: `Are you sure you want to remove vendor form number ${
                                index + 1
                              }? This cant be undone!`,
                              onOk: async () => {
                                if (id !== undefined && productId) {
                                  await dispatch(
                                    deleteVendorFormItem({
                                      vendorId: Number(
                                        vendorFormHookItem.itemId
                                      ),
                                      productId: productId
                                    })
                                  );
                                }

                                remove(index);
                              }
                            });
                          }}
                          block
                          disabled={isAvailable}
                          type="primary"
                          danger
                          icon={<MinusCircleOutlined />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>

            {!isAvailable && (
              <Row gutter={[24, 24]} justify="center">
                <Col span={24}>
                  <Button
                    onClick={() =>
                      append({
                        title: '',
                        price: 0,
                        vendorFiles: [],
                        is_main_vendor: false

                        // id: Math.floor(Math.random() * 10) + 1
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Vendor Form
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>

      <Divider />

      <Row justify="center">
        <Col span={6}>
          <Button
            type="primary"
            block
            icon={submitMode ? <SaveOutlined /> : <EditOutlined />}
            htmlType="submit"
            disabled={isAvailable}
            loading={loadingAction}
          >
            {submitMode ? 'Submit' : 'Save'}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default VendorForm;
