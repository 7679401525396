import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';

import {
  selectAccessoryCategoriesLimit,
  selectAccessoryCategoriesPage,
  selectAccessoryCategoriesSearch
} from './selectors';
import {
  AccessoriesCategoriesState,
  AccessoriesCategoryPostValues
} from './types';

const initialState: AccessoriesCategoriesState = {
  accessoriesCategories: [],
  accessoryCategoryById: null,
  loading: false,
  loadingAction: false,
  loadingById: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const accessoriesCategoriesApi = createAsyncThunk(
  'accessories-category/accessoriesCategoriesApi',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectAccessoryCategoriesPage()(state);
    const search = selectAccessoryCategoriesSearch()(state);
    const limit = selectAccessoryCategoriesLimit()(state);

    try {
      const res = await client.get(`/accessories-category`, {
        params: {
          search,
          page,
          limit
        }
      });

      return res.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getAccessoriesCategoryById = createAsyncThunk(
  'accessories-category/accessoriesCategoryById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`/accessories-category/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postAccessoriesCategory = createAsyncThunk(
  'accessories-category/postAccessories',
  async (
    data: AccessoriesCategoryPostValues,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.post('/accessories-category', { ...data });
      message.success(`Accessories Category created successfully`);

      return dispatch(accessoriesCategoriesApi());
    } catch (err) {
      return rejectWithValue(err as AxiosError<ErrorType>);
    }
  }
);

export const editAccessoriesCategory = createAsyncThunk(
  'accessories-category/attributesEdit',
  async (
    { id, data }: { id: number; data: AccessoriesCategoryPostValues },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.put(`/accessories-category/${Number(id)}`, { ...data });
      message.success(
        `Accessories Category with id ${id} updated successfully`
      );

      await dispatch(accessoriesCategoriesApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteAccessoriesCategory = createAsyncThunk(
  'accessories-category/deleteAccessory',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/accessories-category/${id}`);
      await dispatch(accessoriesCategoriesApi());
      message.success(
        `Accessories Category with id ${id} deleted successfully`
      );
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);
const accessoriesCategorySlice = createSlice({
  name: 'accessories-category',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(accessoriesCategoriesApi.pending, state => {
        state.loading = true;
      })
      .addCase(accessoriesCategoriesApi.fulfilled, (state, action) => {
        state.loading = false;
        state.accessoriesCategories = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(accessoriesCategoriesApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getAccessoriesCategoryById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getAccessoriesCategoryById.fulfilled, (state, action) => {
        state.loadingById = false;
        state.accessoryCategoryById = action.payload;
      })
      .addCase(getAccessoriesCategoryById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(editAccessoriesCategory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(editAccessoriesCategory.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(editAccessoriesCategory.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postAccessoriesCategory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postAccessoriesCategory.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postAccessoriesCategory.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteAccessoriesCategory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteAccessoriesCategory.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteAccessoriesCategory.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit } =
  accessoriesCategorySlice.actions;
export default accessoriesCategorySlice.reducer;
