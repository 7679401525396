/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, Button, Input, Form } from 'antd';
import { categoryEdit, postCategory } from 'app/features/category/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectCategoryActionLoading,
  selectCategoryById
} from 'app/features/category/selectors';
import { FC, memo, useEffect } from 'react';
import { CategoryParams } from 'app/features/category/types';
import {
  selectAccessoriesCategoriesLoadingAction,
  selectAccessoriesCategoriesLoadingById,
  selectAccessoriesCategoryById
} from 'app/features/accessoriesCategories/selectors';
import {
  editAccessoriesCategory,
  postAccessoriesCategory
} from 'app/features/accessoriesCategories/slice';
import { AccessoriesCategoryPostValues } from 'app/features/accessoriesCategories/types';
import { AccessoryCategory } from 'app/features/accessories/types';

import { IAccessoryCategoryModalProps } from './types';

const AccessoryCategoryModal: FC<IAccessoryCategoryModalProps> = ({
  isOpen,
  params,
  onClose
}) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const loadingAction = useAppSelector(
    selectAccessoriesCategoriesLoadingAction()
  );

  const accessoryCategoryById = useAppSelector(selectAccessoriesCategoryById());
  const loadingById = useAppSelector(selectAccessoriesCategoriesLoadingById());

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const modalTitle = () => {
    if (params.editMode) {
      return 'Edit Accessories Category';
    }

    // else if (!params.accessoryCategoryId && params.parentId) {
    return 'Add Accessories Category';
    // }

    // return 'Edit Category';
  };

  useEffect(() => {
    if (accessoryCategoryById) {
      form.setFieldsValue({
        title: accessoryCategoryById.title
      });
    }
  }, [accessoryCategoryById, form]);

  const onSave = async (
    values: AccessoriesCategoryPostValues | AccessoryCategory
  ) => {
    const data = {
      title: values.title
    };

    if (params.editMode && params.accessoryCategoryId) {
      await dispatch(
        editAccessoriesCategory({
          id: params.accessoryCategoryId,
          data
        })
      );
      handleCloseModal();
    } else {
      await dispatch(postAccessoriesCategory(data));
      handleCloseModal();
    }
  };

  return (
    <Modal
      title={modalTitle()}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button
          type="primary"
          onClick={form.submit}
          loading={loadingById || loadingAction}
          key="Save"
        >
          Save
        </Button>
      ]}
    >
      <Form
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        form={form}
        onFinish={onSave}
        layout="vertical"
        size="large"
      >
        <Form.Item
          name="title"
          label="English Title"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter title in English" />
        </Form.Item>
        {/* <Form.Item name="key" label="Category Key" rules={[{ required: true }]}>
          <Input
            disabled={!!params.categoryId}
            placeholder="Enter Category Key"
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default memo(AccessoryCategoryModal);
