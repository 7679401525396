import { RootState } from 'app/store';

export const selectCbmCountryData = () => (state: RootState) =>
  state.cbmCountries.cbmCountries;

export const selectCbmCountryLoading = () => (state: RootState) =>
  state.cbmCountries.loading;

export const selectCbmCountryPage = () => (state: RootState) =>
  state.cbmCountries.page;

export const selectCbmCountryCount = () => (state: RootState) =>
  state.cbmCountries.count;

export const selectCbmCountrySearch = () => (state: RootState) =>
  state.cbmCountries.search;

export const selectCbmCountryLimit = () => (state: RootState) =>
  state.cbmCountries.limit;
