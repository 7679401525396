/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect, useState } from 'react';
import {
  UploadRequestOption,
  UploadRequestError
} from 'rc-upload/lib/interface';
import { RcFile } from 'antd/es/upload';
import { client } from 'api';

type CustomRequestOptions = {
  url: string;
  name?: boolean;
  file_type?: string;
  folder?: string;
  hide?: boolean;
  vendorId?: string;
  productId?: number;
};

const useCustomRequest = ({
  url,
  file_type,
  folder,
  hide,
  vendorId,
  productId
}: CustomRequestOptions) => {
  // refs
  const timer = useRef<NodeJS.Timeout | null>(null);
  const queue = useRef<UploadRequestOption<any>[]>([]);

  const customRequest = (e: UploadRequestOption<any>) => {
    queue.current.push(e);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(async () => {
      const formData = new FormData();
      queue.current.forEach(item => {
        formData.append('file', item.file as Blob, (item.file as RcFile).name);
      });

      if (folder) formData.append('folder_name', String(folder));
      else if (!file_type && productId)
        formData.append('product_id', String(productId));
      if (file_type) formData.append('file_type', String(file_type));
      if (hide) formData.append('hide', String(hide));
      if (vendorId) formData.append('vendor_id', String(vendorId));

      try {
        const res = await client.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              queue.current.forEach(item => {
                if (item.onProgress) {
                  item.onProgress({ percent: percentCompleted });
                }
              });
            }
          }
        });

        queue.current.forEach(item => item.onSuccess?.(res));
      } catch (err: unknown) {
        queue.current.forEach(item =>
          item.onError?.(err as UploadRequestError)
        );
      } finally {
        queue.current = [];
      }
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return customRequest;
};

const useCustomRequestWithResponse = ({
  url,
  file_type,
  folder,
  hide,
  vendorId,
  productId
}: CustomRequestOptions) => {
  // refs
  const timer = useRef<NodeJS.Timeout | null>(null);
  const queue = useRef<UploadRequestOption<any>[]>([]);
  const [response, setResponse] = useState('');

  const customRequest = (e: UploadRequestOption<any>) => {
    queue.current.push(e);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(async () => {
      const formData = new FormData();
      queue.current.forEach(item => {
        formData.append('file', item.file as Blob, (item.file as RcFile).name);
      });

      if (folder) formData.append('folder_name', String(folder));
      else if (!file_type && productId)
        formData.append('product_id', String(productId));
      if (file_type) formData.append('file_type', String(file_type));
      if (hide) formData.append('hide', String(hide));
      if (vendorId) formData.append('vendor_id', String(vendorId));

      try {
        const res = await client.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              queue.current.forEach(item => {
                if (item.onProgress) {
                  item.onProgress({ percent: percentCompleted });
                }
              });
            }
          }
        });

        setResponse(res.data.payload[0].path);
        queue.current.forEach(item => item.onSuccess?.(res));
      } catch (err: unknown) {
        queue.current.forEach(item =>
          item.onError?.(err as UploadRequestError)
        );
      } finally {
        queue.current = [];
      }
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return { customRequest, response };
};

export { useCustomRequest, useCustomRequestWithResponse };
