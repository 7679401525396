import { Modal, Button, Input, Form } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, memo, useEffect } from 'react';
import {
  // Accessory,
  // AccessoryCategory,
  AccessoryEditValues,
  AccessoryPostValues
} from 'app/features/accessories/types';
import {
  selectAccessoryById,
  selectAccessoryLoadingAction,
  selectAccessoryLoadingById
} from 'app/features/accessories/selectors';
import {
  editAccessories,
  postAccessories
} from 'app/features/accessories/slice';
import NumericInput, {
  cleanAndParse,
  parseInputValue
} from 'helpers/NumericInput';

import { IAccessoryModalProps } from './types';

const AccessoryModal: FC<IAccessoryModalProps> = ({
  isOpen,
  params,
  onClose
}) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const accessoryById = useAppSelector(selectAccessoryById());
  const loadingById = useAppSelector(selectAccessoryLoadingById());
  const loadingAction = useAppSelector(selectAccessoryLoadingAction());

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const modalTitle = () => {
    if (params.editMode) {
      return 'Edit Accessory';
    }

    return 'Add Accessory';
  };

  useEffect(() => {
    if (accessoryById) {
      form.setFieldsValue({
        title: accessoryById.title,
        price: parseInputValue(String(accessoryById.price)),
        key: accessoryById.key
      });
    }
  }, [accessoryById, form]);

  const onSave = async (values: AccessoryEditValues | AccessoryPostValues) => {
    const { title, price, key } = values;
    const postData = {
      title: title,
      price: cleanAndParse(price),
      ...(key && { key: key }),
      accessory_category_id: params.accessoryCategoryId
    };

    if (params.editMode && params.accessoryCategoryId) {
      const { accessory_category_id, ...data } = postData;
      await dispatch(
        editAccessories({
          accessoryCategoryId: accessory_category_id,
          id: params.accessoryId,
          data
        })
      );
      handleCloseModal();
    } else {
      await dispatch(postAccessories(postData));
      handleCloseModal();
    }
  };

  return (
    <Modal
      title={modalTitle()}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button
          type="primary"
          onClick={form.submit}
          loading={loadingById || loadingAction}
          key="Save"
        >
          Save
        </Button>
      ]}
    >
      <Form
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        form={form}
        onFinish={onSave}
        layout="vertical"
        size="large"
      >
        <Form.Item
          name="title"
          label="English Title"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter title in English" />
        </Form.Item>
        <Form.Item name="price" label="Price" rules={[{ required: true }]}>
          <NumericInput placeholder="Enter Price" />
        </Form.Item>
        <Form.Item name="key" label="Key" rules={[{ required: true }]}>
          <Input placeholder="Enter Key" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(AccessoryModal);
