import { Button, Table } from 'antd';
import {
  // deleteUser,
  userApi,
  getUserById,
  setLimit,
  setPage,
  setSearch,
  deleteUser
} from 'app/features/users/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectUserData,
  selectUserLoading,
  selectUserLoadingAction,
  selectUserLimit,
  selectUserPage,
  selectUserSearch,
  selectUserCount
} from 'app/features/users/selectors';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/utils';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { IUser, UserId } from 'app/features/users/types';

import ContentTop from 'components/ContentTop';
import { ButtonsWrapper } from './styled';
import DeleteModal from 'components/DeleteModal';
import { UserModal } from './components';

const debounce = _debounce();

const Users = () => {
  // state
  const [userId, setUserId] = useState<UserId>('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const usersData = useAppSelector(selectUserData());
  const page = useAppSelector(selectUserPage());
  const loading = useAppSelector(selectUserLoading());
  const search = useAppSelector(selectUserSearch());
  const limit = useAppSelector(selectUserLimit());
  const count = useAppSelector(selectUserCount());
  const loadingAction = useAppSelector(selectUserLoadingAction());

  const navigate = useNavigate();

  const onEditUser = (id: UserId) => {
    dispatch(getUserById(id));
    setUserId(id);
    setShowUserModal(true);
  };

  const columns: ColumnsType<IUser> = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name'
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                onEditUser(record.id);
              }}
            >
              Edit
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setUserId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  useEffect(() => {
    debounce(() => dispatch(userApi()));
  }, [dispatch, search, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const onClose = () => {
    setUserId('');
    setShowUserModal(false);
  };

  return (
    <>
      <ContentTop
        title="Users"
        placeholder="Search User"
        buttonText="Add User"
        onClickAdd={() => setShowUserModal(true)}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />
      <Table
        loading={loading}
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
        bordered
        columns={columns}
        dataSource={usersData}
        rowKey="id"
      />
      <UserModal
        isOpen={showUserModal}
        onClose={onClose}
        params={{
          userId
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteUser(userId)).then(() => {
            setOpenDeleteModal(false);
            setUserId('');
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          // setUserId(0);
        }}
      />
    </>
  );
};

export default Users;
