import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { Tag } from 'antd';
import styled from 'styled-components';

export const TrueIcon = styled(CheckCircleOutlined)`
  font-size: 20px;
  margin: 2px 0 0 2px;
`;
export const FalseIcon = styled(CloseCircleOutlined)`
  font-size: 20px;
  margin: 2px 0 0 2px;
`;
export const IconWrapper = styled(Tag)`
  width: 40px;
  height: 100%;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImgTableWrraper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

export const StyledInfoCircle = styled(InfoCircleOutlined)`
  color: #3ed701;
  font-size: 20px;
  position: absolute;
  top: 5%;
  right: 3%;
`;
