import { message, Modal, ModalFuncProps } from 'antd';
import { AxiosError } from 'axios';

export type ErrorType = {
  data: null;
  message: string;
  status: number;
};
export interface FormValidationError {
  errorFields: {
    name: string[];
    errors: string[];
  }[];
}

export const showErrorMessage = (err: AxiosError<ErrorType>) => {
  message.error(
    err?.response?.data?.message ||
      err?.message ||
      'Something went wrong check the console'
  );
};

export const showErrorUploadMessage = (err: AxiosError<ErrorType>) => {
  const errorProps: ModalFuncProps = {
    title: 'Error',
    content: err?.response?.data?.message || 'Something went wrong'
  };

  Modal.error(errorProps);
};

export const handleSubmitError = (error: unknown) => {
  message.error(`Submission failed: ${error}`);
  console.error('Submission failed:', error);
};

export const handleValidationError = (error: unknown) => {
  const validationError = error as FormValidationError;
  message.error(
    `Validation failed: ${validationError.errorFields[0]?.errors}`,
    3
  );
  console.error('Validation failed:', validationError);
};

export const handleBackendValidationError = (
  errorMessage: string,
  error: unknown
) => {
  message.error(
    `Backend validation error: ${
      errorMessage ||
      (error as { message?: string })?.message ||
      'Unknown error'
    }: Check the console for more info.`,
    3
  );

  console.error('Validation failed:', error);
};

export const handleBackendErrorNoPrefix = (
  errorMessage: string,
  error: unknown
) => {
  message.error(
    `${
      errorMessage ||
      (error as { message?: string })?.message ||
      'Unknown error'
    }`,
    3
  );
};

export const handleRefreshError = (error: { message: string }) => {
  message.error(`error: ${error.message}: Check the console for more info.`, 3);
  console.error(error);
};
