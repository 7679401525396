import { logOut, refreshAccessToken } from 'app/features/auth/slice';
import { store } from 'app/store';
import axios, { AxiosError } from 'axios';
import { unwrapResult } from '@reduxjs/toolkit';
import { handleRefreshError } from 'helpers/errors';

export const BASE_URL = process.env.REACT_APP_API_URL;

const token = localStorage.getItem('token');
const refreshToken = localStorage.getItem('refreshToken');

export const client = axios.create({
  baseURL: BASE_URL,
  headers: {
    'x-access-token': token ? `${token}` : '',
    'x-refresh-token': refreshToken ? `${refreshToken}` : '',
    'Accept-language': 'en'
  }
});

export const setClientToken = (accessToken: string, refreshToken: string) => {
  client.defaults.headers.common['x-access-token'] = `${accessToken}`;
  client.defaults.headers['x-access-token'] = `${accessToken}`;

  client.defaults.headers.common['x-refresh-token'] = `${refreshToken}`;
  client.defaults.headers['x-refresh-token'] = `${refreshToken}`;
};

let isRefreshing = false;

client.interceptors.response.use(
  response => response,

  async (error: AxiosError) => {
    if (error.response?.status === 401 && refreshToken && !isRefreshing) {
      isRefreshing = true;

      try {
        const res = await store.dispatch(refreshAccessToken(refreshToken));
        const { newAccessToken, newRefreshToken } = unwrapResult(res);

        if (error.config) {
          const { headers } = error.config;

          headers['x-access-token'] = newAccessToken;
          headers['x-refresh-token'] = newRefreshToken;
          setClientToken(newAccessToken, newRefreshToken);

          window.location.reload();

          return client.request(error.config);
        } else {
          store.dispatch(logOut());

          return Promise.reject(error);
        }
      } catch (refreshError) {
        const typedRefreshError = refreshError as { message: string };
        handleRefreshError(typedRefreshError);
        store.dispatch(logOut());
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);
