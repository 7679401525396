import { Card, Divider, Tag, Layout } from 'antd';
import styled from 'styled-components';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

export const LayoutStyled = styled(Layout)`
  min-height: 100vh;
`;
export const MenuOutlined1 = styled(MenuFoldOutlined)`
  color: white;
  font-size: 25px;
`;
export const MenuOutlined2 = styled(MenuUnfoldOutlined)`
  color: white;
  font-size: 25px;
`;

export const LogoImgWrapper = styled.div`
  cursor: pointer;
  font-size: 36px;
  font-family: cursive;
  font-weight: 900;
  color: white;
`;
export const SiderTop = styled.div<{ collapsed: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${props => (props.collapsed ? 'center' : 'end')};
  padding: 10px;
  justify-content: space-between;
`;
export const LogoImg = styled.img`
  width: 100%;
`;

export const ContentStyled = styled(Layout.Content)<{ bgcolor: string }>`
  &&& {
    margin: 24px;
    padding: 24px;
    border-radius: 15px;
    background: ${props => props.bgcolor};
    min-height: calc(100vh - 112px);
  }
`;
export const HeaderStyled = styled(Layout.Header)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(0, 21, 41);
    backdrop-filter: saturate(180%) blur(5px);
    position: sticky;
    top: 0;
    z-index: 5;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  }
`;
export const SiderStyled = styled(Layout.Sider)`
  && {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 64px;
  }
`;
export const ContentWrapper = styled.div<{ collapsed: boolean }>`
  width: ${props => props.collapsed && 'calc(100% - 50px)'};
  margin-left: ${props => (props.collapsed ? '50px' : '220px')};
  transition: all 0.3s;
`;
export const MenuWrraper = styled.div`
  display: flex;
`;

export const DarkCard = styled(Card)`
  width: 90%;
  margin: 10px;
  background-color: #272c36;
  color: #fff;
  border: 1px solid #40454f;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica Neue', sans-serif;

  .ant-card-meta-description {
    color: #b9b9b9;
    font-size: 14px;
  }

  /* Styling for the title text */
  .ant-card-meta-title {
    color: #489d6b;
    font-size: 20px;
    font-weight: bold;
  }

  /* Styling for the <p> elements inside DarkCard */
  .value {
    color: #ccc;
    margin: 5px 0;
    font-size: 14px;
  }
  .key {
    font-size: 12px;
    color: #9a9a9a;
  }
`;
export const CustomDivider = styled(Divider)`
  background-color: #40454f;
  margin-top: 5%;
`;

export const VersionTagLong = styled(Tag)`
  width: 90%;
  position: absolute;
  top: 85%;
  margin: 10px;
`;

export const VersionTag = styled(Tag)`
  margin-top: 260px;
  margin-left: 10px;
  text-align: center;
`;
