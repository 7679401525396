import { Button, Image, Table, Tooltip } from 'antd';
import {
  deleteVendor,
  vendorApi,
  setLimit,
  setPage,
  setSearch
} from 'app/features/vendor/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectVendorData,
  selectVendorLoading,
  selectVendorLoadingAction,
  selectVendorsCount,
  selectVendorsLimit,
  selectVendorsPage,
  selectVendorsSearch
} from 'app/features/vendor/selectors';
import { useEffect, useState } from 'react';
import { _debounce, getFileUrl } from 'helpers/utils';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { IVendor } from 'app/features/vendor/types';

import ContentTop from 'components/ContentTop';
import {
  ButtonsWrapper,
  DescriptionWrapper,
  IconWrapper,
  ImgTableWrraper,
  NameLink
} from './styled';
import DeleteModal from 'components/DeleteModal';
import noImg from '../../assets/img/noimage.png';

const debounce = _debounce();

const vendorInfoInitial = { name: '', id: '' };

const Vendors = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [vendorInfo, setVendorInfo] = useState(vendorInfoInitial);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const vendorData = useAppSelector(selectVendorData());
  const loading = useAppSelector(selectVendorLoading());
  const page = useAppSelector(selectVendorsPage());
  const limit = useAppSelector(selectVendorsLimit());
  const count = useAppSelector(selectVendorsCount());
  const search = useAppSelector(selectVendorsSearch());
  const loadingAction = useAppSelector(selectVendorLoadingAction());

  useEffect(() => {
    debounce(() => dispatch(vendorApi({})));
  }, [dispatch, search, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const columns: ColumnsType<IVendor> = [
    {
      title: 'Availability',
      dataIndex: 'is_visible',
      align: 'left',
      width: 150,
      key: 'is_visible',

      render: isVisible => {
        const color = isVisible ? 'green' : 'red';
        const text = isVisible ? 'Available' : 'Unavailable';

        return <IconWrapper color={color}>{text}</IconWrapper>;
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (_, record) => {
        return (
          <Tooltip placement="bottom" title="Go to vendor page">
            <NameLink href={record.url} target="_blank" rel="noreferrer">
              {record.name}
            </NameLink>
          </Tooltip>
        );
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => {
        return (
          <DescriptionWrapper
            dangerouslySetInnerHTML={{
              __html: text
            }}
          ></DescriptionWrapper>
        );
      }
    },
    {
      title: 'Image',
      dataIndex: 'Files',
      align: 'center',
      width: 250,
      key: 'image',
      render: (_, item) => {
        return (
          <ImgTableWrraper>
            {item.files?.length ? (
              <Image
                fallback={noImg}
                width={70}
                height={55}
                alt={item.files[0].name}
                src={getFileUrl(item.files[0]?.path)}
              />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },

    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      width: '200px',
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}>Edit</Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setVendorInfo({ name: record.name, id: record.id });
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Vendors"
        placeholder="Search Vendor"
        buttonText="Add Vendor"
        onClickAdd={() => navigate('details')}
        onChange={e => dispatch(setSearch(e.target.value))}
        searchComponent
        search={search}
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={vendorData}
        loading={loading}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />

      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(
            deleteVendor({ id: vendorInfo.id, name: vendorInfo.name })
          ).then(() => {
            setOpenDeleteModal(false);
            setVendorInfo(vendorInfoInitial);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setVendorInfo(vendorInfoInitial);
        }}
      />
    </>
  );
};

export default Vendors;
