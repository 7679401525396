import { Form, InputNumber, Select } from 'antd';
import { FC, memo, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectAttributesByCategoryIds } from 'app/features/category/selectors';

import { IAttributeSelectProps } from './types';
import { SelectWrraper } from './styled';
import { DefaultValue, attrData, attributeDisabled } from './constant';

const AttributeSelect: FC<IAttributeSelectProps> = ({
  onChange,
  selected,
  attributes
}) => {
  const attributesByCategoryIds = useAppSelector(
    selectAttributesByCategoryIds()
  );

  const [numberValue, setNumberValue] = useState<string[]>(
    DefaultValue(selected)
  );

  function handleSelectChange(e: number) {
    setNumberValue([]);
    const selectedAttribute = arr.find(item => item?.id === e);
    onChange({
      attribute: selectedAttribute,
      attributeId: e,
      value: {
        type: selectedAttribute?.type as 'number' | 'enum'
      }
    });
  }

  function handleEnumTypeChange(newValue: string) {
    setNumberValue([newValue]);
    const selectedAttribute = arr.find(
      item => item?.id === selected.attributeId
    );

    onChange({
      attribute: selectedAttribute,
      attributeId: selected.attributeId,
      value: {
        type: 'enum',
        enumValue: newValue
      }
    });
  }

  function handleNumberTypeChange(newValue: string | null) {
    if (newValue) setNumberValue([newValue]);

    if (attribute)
      onChange({
        attribute: selected.attribute,
        attributeId: attribute.id,
        value: {
          type: 'number',
          numberRange: {
            min: newValue,
            max: newValue
          }
        }
      });
  }

  const arr = attrData(attributesByCategoryIds || []);

  const attribute = selected?.attribute;

  const getNumberType = () => {
    if (attribute && attribute?.type === 'number') {
      return (
        <InputNumber
          style={{ width: '100%' }}
          addonBefore={attribute.unit_en}
          max={attribute.values_en.max}
          min={attribute.values_en.min}
          value={numberValue[0]}
          onChange={handleNumberTypeChange}
        />
      );
    }
  };

  const getEnumType = () => {
    if (!selected) return null;

    if (attribute?.type === 'enum') {
      return (
        <Select
          placeholder="Enter value"
          value={numberValue[0]}
          onChange={handleEnumTypeChange}
          allowClear={false}
          style={{ width: '100%' }}
          options={attribute?.values_en?.values?.map(value => ({
            value,
            label: value
          }))}
        />
      );
    }

    return getNumberType();
  };

  return (
    <>
      {Array.isArray(arr) && arr.length > 0 && (
        <>
          <Form.Item label="Attribute">
            <SelectWrraper>
              <Select
                allowClear={false}
                placeholder="Please select description"
                value={selected?.attributeId}
                showSearch
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  optionA?.label
                    .toLowerCase()
                    .localeCompare(optionB?.label.toLowerCase())
                }
                onChange={handleSelectChange}
                options={arr.map(attribute => ({
                  value: attribute.id,
                  label: attribute.description_en,
                  disabled: attributeDisabled(attributes, attribute.id)
                }))}
              />
            </SelectWrraper>
          </Form.Item>

          {getEnumType()}
        </>
      )}
    </>
  );
};

export default memo(AttributeSelect);
