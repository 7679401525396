import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Modal, Upload } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';

import noImg from '../assets/img/noimage.png';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const Uploader: React.FC<UploadProps> = ({
  onChange,
  fileList,
  // setFileList,
  customRequest,
  onRemove,
  disabled,
  accept,
  maxCount
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    const fullSizeUrl = file.url?.replace('108x88', '574x438');
    const previewSrc = fullSizeUrl || (file.preview as string);

    const previewTitle =
      file.name?.replace('108x88', '574x438') ||
      (file.url ? file.url.substring(file.url.lastIndexOf('/') + 1) : '');

    setPreviewImage(previewSrc);
    setPreviewOpen(true);
    setPreviewTitle(previewTitle);
  };

  const uploadButton = (
    <button
      style={{ border: 0, background: 'none', cursor: 'pointer' }}
      type="button"
    >
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <>
      <Upload
        maxCount={maxCount}
        disabled={disabled}
        listType="picture-card"
        fileList={fileList}
        multiple
        onPreview={handlePreview}
        onChange={onChange}
        onRemove={onRemove}
        customRequest={customRequest}
        accept={accept}
      >
        {!disabled && uploadButton}
        {/* {fileList.length >= 20 ? null : uploadButton} */}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <Image
          alt="example"
          style={{ width: '100%' }}
          src={previewImage || noImg}
        />
      </Modal>
    </>
  );
};

export default Uploader;
