import { cleanAndParse, parseInputValue } from 'helpers/NumericInput';
import { useEffect, useState } from 'react';
import { Form } from 'antd';

import {
  HandleCountrySelectParams,
  InputHandlerProps,
  DisabledStates
} from '../../types';

export const useInputHandlers = ({ form }: InputHandlerProps) => {
  const [isManual, setIsManual] = useState<DisabledStates>({});
  const [inputsRequired, setInputsRequired] = useState<boolean>(false);
  const [isBigger, setIsBigger] = useState<DisabledStates>({});
  const [allowCalculate, setAllowCalculate] = useState<DisabledStates>({});

  const formWatch = Form.useWatch('ctryPrcFormList', form);

  useEffect(() => {
    const initialFormData = form.getFieldValue('ctryPrcFormList');

    setIsBigger(prevIsBigger => ({
      ...prevIsBigger,
      ...Object.keys(initialFormData).reduce((acc, fieldName) => {
        const cbmWeightKgValue = initialFormData[fieldName]?.cbmWeightKg;
        const weightKgValue = initialFormData[fieldName]?.weightKg;

        return {
          ...acc,
          [fieldName]:
            Number(weightKgValue) > Number(cbmWeightKgValue) ? true : false
        };
      }, {})
    }));
    setIsManual(prev => ({
      ...prev,
      ...Object.keys(initialFormData).reduce((acc, fieldName) => {
        const { manualCheckbox, navyCheckbox } =
          initialFormData[fieldName] || {};

        return {
          ...acc,
          [fieldName]: navyCheckbox,
          [fieldName]: manualCheckbox
        };
      }, {})
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formWatch]);

  const handleCountrySelect = ({ option, name }: HandleCountrySelectParams) => {
    const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');

    const cbmPrice = cleanAndParse(form.getFieldValue('cbm'));
    const navySubtotal = parseInputValue(String(option.price * cbmPrice)) || '';

    ctryPrcFormList[name] = {
      ...ctryPrcFormList[name],
      navySubtotal,
      countryPrice: option?.price
    };

    form.setFieldsValue({ ctryPrcFormList });
  };

  const handleCheckboxChange = (
    checked: boolean,
    name: number,
    field1: string,
    field2: string
  ) => {
    const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');
    handleSegmentClear(name);

    setIsManual(prevIsManual => ({
      ...prevIsManual,
      [name]: field1 === 'navyCheckbox' ? checked : !checked
    }));

    ctryPrcFormList[name] = {
      ...ctryPrcFormList[name],
      [field1]: checked,
      [field2]: !checked
    };
    form.setFieldsValue({ ctryPrcFormList });
  };

  const handlePercentOrFixSelect = (name: number, field: string) => {
    const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');

    const fieldToRemove =
      field === 'navy' ? 'percentOrFixValue' : 'percentOrFixValueManual';

    ctryPrcFormList[name] = {
      ...ctryPrcFormList[name],
      // standardPrice: parseInputValue(String(result)),
      [fieldToRemove]: null
    };

    form.setFieldsValue({ ctryPrcFormList });
  };

  const handlePercentOrFixSelectPlane = (name: number) => {
    const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');

    ctryPrcFormList[name] = {
      ...ctryPrcFormList[name],
      percentOrFixValuePlane: null
    };

    form.setFieldsValue({ ctryPrcFormList });
  };

  const handleLengthWidthHeight = (name: number) => {
    const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');
    const { lengthCm, widthCm, heightCm, weightKg } =
      ctryPrcFormList[name] || {};

    const areInputsPresent = lengthCm || widthCm || heightCm;
    const allInputsPresent = lengthCm && widthCm && heightCm;
    const fieldNamesToValidate = [
      ['ctryPrcFormList', name, 'lengthCm'],
      ['ctryPrcFormList', name, 'widthCm'],
      ['ctryPrcFormList', name, 'heightCm']
    ];

    if (!areInputsPresent) {
      form.validateFields(fieldNamesToValidate);
    }

    setInputsRequired(areInputsPresent);

    const weightKgCalc =
      allInputsPresent &&
      (cleanAndParse(lengthCm) *
        cleanAndParse(widthCm) *
        cleanAndParse(heightCm)) /
        6000;

    ctryPrcFormList[name] = {
      ...ctryPrcFormList[name],
      cbmWeightKg: parseInputValue(String(weightKgCalc))
    };

    form.setFieldsValue({ ctryPrcFormList });
    setIsBigger(prevIsBigger => ({
      ...prevIsBigger,
      [name]:
        cleanAndParse(weightKg) > weightKgCalc || !allInputsPresent
          ? true
          : false
    }));
  };

  const handleWeightKg = (value: string, name: number) => {
    const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');
    const weightKg = cleanAndParse(value);
    const { lengthCm, widthCm, heightCm } = ctryPrcFormList[name] || {};
    const allInputsPresent = lengthCm && widthCm && heightCm;
    const cbmWeightKg = cleanAndParse(ctryPrcFormList[name]?.cbmWeightKg) || 0;
    const pricePerKg = cleanAndParse(ctryPrcFormList[name]?.pricePerKg) || '';

    setIsBigger(prevIsBigger => ({
      ...prevIsBigger,
      [name]: weightKg > cbmWeightKg || !allInputsPresent ? true : false
    }));

    setAllowCalculate(prevState => ({
      ...prevState,
      [name]: value.trim() !== '' && pricePerKg !== ''
    }));
  };

  const handlePricePerKg = (value: string, name: number) => {
    const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');
    const weightKg = cleanAndParse(ctryPrcFormList[name]?.weightKg) || '';

    setAllowCalculate(prevState => ({
      ...prevState,
      [name]: value.trim() !== '' && weightKg !== ''
    }));
  };

  const handleSegmentClear = (name: number) => {
    const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');

    ctryPrcFormList[name] = {
      ...ctryPrcFormList[name],
      standardPrice: null,
      otherCost: null,
      percentOrFixValue: null,
      cost: null,
      otherCostManual: null,
      percentOrFixValueManual: null
    };

    form.setFieldsValue({ ctryPrcFormList });
  };

  return {
    handleCountrySelect,
    handleCheckboxChange,
    handleLengthWidthHeight,
    handleWeightKg,
    handlePercentOrFixSelect,
    handlePercentOrFixSelectPlane,
    isManual,
    inputsRequired,
    isBigger,
    allowCalculate,
    handlePricePerKg
  };
};

export default useInputHandlers;
