export function RequiredFieldIndicator({
  isFilled,
  label
}: {
  isFilled: boolean;
  label: string;
}) {
  return (
    <div>
      <span>{label}</span>
      <sup
        style={{
          marginLeft: '4px',
          color: isFilled ? 'rgba(0, 0, 0, 0.88)' : '#ff4d4f'
        }}
      >
        *
      </sup>
    </div>
  );
}
