import { RootState } from 'app/store';

export const selectBusinessData = () => (state: RootState) =>
  state.businesses.businesses;

export const selectBusinessLoading = () => (state: RootState) =>
  state.businesses.loading;

export const selectBusinessById = () => (state: RootState) =>
  state.businesses.businessById;

export const selectBusinessLoadingById = () => (state: RootState) =>
  state.businesses.loadingById;

export const selectBusinessActionLoading = () => (state: RootState) =>
  state.businesses.loadingAction;

export const selectBusinessSearch = () => (state: RootState) =>
  state.businesses.search;

export const selectBusinessPage = () => (state: RootState) =>
  state.businesses.page;

export const selectBusinessLimit = () => (state: RootState) =>
  state.businesses.limit;

export const selectBusinessCount = () => (state: RootState) =>
  state.businesses.count;

export const selectBusinessAvailabilityList = () => (state: RootState) =>
  state.businesses.availabilityList;
