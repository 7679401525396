import { Card, Input } from 'antd';
import styled from 'styled-components';

export const SpecialistContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardContainer = styled(Card)`
  width: 100%;
`;

export const InfoInput = styled(Input)`
  margin: 8px 0;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;
export const NameInput = styled(Input)`
  margin: 8px 0;
`;
