import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Form, Modal, UploadFile, UploadProps } from 'antd';
import { getFileUrl, getStatusColor } from 'helpers/utils';
// import { countryApi } from 'app/features/country/slice';
import { deleteFile } from 'app/features/files/slice';
import COUNTRIES from 'constants/countries';
import {
  selectBusinessActionLoading,
  selectBusinessById,
  selectBusinessLoadingById
} from 'app/features/businesses/selectors';
import {
  getBusinessById,
  postPartner,
  updatePartner
} from 'app/features/businesses/slice';
import { PartnerPost } from 'app/features/businesses/types';
import { handleBackendValidationError } from 'helpers/errors';

import BusinessForm from './components/BusinessForm';

const BusinessDetails = () => {
  const { id } = useParams();
  const BusinessId = Number(id);

  // state
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // const [vendorId, setVendorId] = useState<string | undefined>(id);
  const [createdAt, setCreatedAt] = useState<string>();
  const [modifiedAt, setModifiedAt] = useState<string>();

  // const vendorId = String(id);
  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const BusinessById = useAppSelector(selectBusinessById());
  const loadingAction = useAppSelector(selectBusinessActionLoading());
  const loadingById = useAppSelector(selectBusinessLoadingById());
  const countryData = COUNTRIES;

  const statusColor = getStatusColor(BusinessById?.status || '');

  // form
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  // useEffect(() => {
  //   dispatch(countryApi());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (BusinessId) {
      dispatch(getBusinessById(BusinessId));
    }
  }, [dispatch, BusinessId]);

  const onChangeImageUpload: UploadProps['onChange'] = ({ fileList }) => {
    const newFileList = fileList.map(file => {
      if (file.response) {
        file.url = getFileUrl(
          file.response.data.payload.map((item: { path: string }) => item.path)
        );
        file.uid = file.response.data.payload.map(
          (item: { id: string }) => item.id
        );
        file.fileName = file.response.data.payload.map(
          (item: { path: string }) => item.path
        );
      }

      return file;
    });

    setFileList(newFileList);
  };

  const onImageRemove = async (file: UploadFile) => {
    const originalFileList = [...fileList];
    Modal.confirm({
      title: 'Confirm',
      content: `Are you sure you want to remove ${file.fileName}?`,
      onOk: async () => {
        await dispatch(deleteFile(file.uid));
        const updatedFileList = fileList.filter(item => item.uid !== file.uid);
        setFileList(updatedFileList);
      },
      onCancel: () => {
        setFileList(originalFileList);
      }
    });
  };

  const onSave = async (values: PartnerPost) => {
    const { id, name, description, url, owner_email, country_arr, status } =
      values;

    const filePath: string | undefined = fileList?.[0]?.fileName?.[0];

    const data = {
      id,
      name,
      ...(url && { url }),
      ...(description && { description }),
      owner_email,
      country_arr,
      ...(filePath && { logo_path: filePath }),
      ...(id && { status })
    };

    if (id) {
      const res = await dispatch(updatePartner(data));

      if (res.meta.requestStatus === 'fulfilled' && res.payload) {
        onClose();
      } else {
        handleBackendValidationError(
          res?.payload?.response?.data?.message,
          res
        );
      }
    } else {
      const res = await dispatch(postPartner(data));

      if (res.meta.requestStatus === 'fulfilled' && res.payload) {
        onClose();
      } else {
        handleBackendValidationError(
          res?.payload?.response?.data?.message,
          res
        );
      }
    }
  };

  useEffect(() => {
    if (id && BusinessById) {
      const createdAtDate = new Date(BusinessById.created_at);
      const modifiedAtDate = new Date(BusinessById.updated_at);
      const formattedCreatedAt = createdAtDate?.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });

      const formattedUpdatedAt = modifiedAtDate?.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });

      form.setFieldsValue({
        id: BusinessById.id,
        name: BusinessById.name,
        country_arr: BusinessById.country_arr,
        url: BusinessById.url,
        owner_email: BusinessById.owner_email,
        status: BusinessById?.status,
        description: BusinessById.description
      });
      setCreatedAt(formattedCreatedAt);
      setModifiedAt(formattedUpdatedAt);

      if (BusinessById?.logo_path) {
        setFileList([
          {
            uid: BusinessById.logo_path,
            name: BusinessById.logo_path.replace('products_global/', ''),
            status: 'done',
            url: getFileUrl(BusinessById.logo_path)
          }
        ]);
      } else {
        setFileList([]);
      }
    }
  }, [BusinessById, form, id]);

  return (
    <BusinessForm
      loadingById={loadingById}
      BusinessById={BusinessById}
      onSave={onSave}
      onClose={onClose}
      BusinessId={BusinessId}
      loadingAction={loadingAction}
      countryData={countryData}
      onChangeImageUpload={onChangeImageUpload}
      fileList={fileList}
      onImageRemove={onImageRemove}
      createdAt={createdAt}
      modifiedAt={modifiedAt}
      statusColor={statusColor}
      form={form}
    />
  );
};

export default BusinessDetails;
