import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { Button, Popover, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IAttributes } from 'app/features/attributes/types';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/utils';
import {
  attributeApi,
  deleteAttribute,
  setLimit,
  setPage,
  setSearch
} from 'app/features/attributes/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectAttributeCount,
  selectAttributeData,
  selectAttributeLimit,
  selectAttributeLoading,
  selectAttributeLoadingAction,
  selectAttributePage,
  selectAttributeSearch
} from 'app/features/attributes/selectors';

import { ButtonsWrapper } from './styled';
import ContentTop from 'components/ContentTop';
import DeleteModal from 'components/DeleteModal';

const debounce = _debounce();

const Attributes = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [attributeId, setAttributeId] = useState<number>(0);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const attributeData = useAppSelector(selectAttributeData());
  const page = useAppSelector(selectAttributePage());
  const search = useAppSelector(selectAttributeSearch());
  const limit = useAppSelector(selectAttributeLimit());
  const loading = useAppSelector(selectAttributeLoading());
  const count = useAppSelector(selectAttributeCount());
  const loadingAction = useAppSelector(selectAttributeLoadingAction());
  const [byCategory, setbyCategory] = useState(false);
  const placeholderTextBase = 'Search Attribute';
  const placeholderText = byCategory
    ? `${placeholderTextBase} by Category`
    : placeholderTextBase;

  useEffect(() => {
    debounce(() => dispatch(attributeApi(byCategory)));
  }, [dispatch, search, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const handleSwitch = (checked: boolean) => {
    setbyCategory(checked);
  };

  const columns: ColumnsType<IAttributes> = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Name',
      dataIndex: 'description_en',
      key: 'description_en'
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      width: 300,
      render: (categories: any[]) => {
        const content = (
          <div>
            {categories.map((category: any) => (
              <div key={category.id}>
                <span>{category.title_en}</span>
                <br />
              </div>
            ))}
          </div>
        );

        if (categories.length > 3) {
          return (
            <Popover content={content} trigger="click">
              <div style={{ cursor: 'pointer' }}>
                {categories.slice(0, 2).map((category: any) => (
                  <div key={category.id}>
                    <span>{category.title_en}</span>
                    <br />
                  </div>
                ))}
                <p>...</p>
              </div>
            </Popover>
          );
        }

        return content;
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Unit',
      dataIndex: 'unit_en',
      key: 'unit'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}>Edit</Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setAttributeId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Attributes"
        placeholder={placeholderText}
        buttonText="Add Attribute"
        upload
        onClickAdd={() => navigate('details')}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        add
        searchComponent
        search={search}
        onSwitch={handleSwitch}
      />
      <Table
        loading={loading}
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
        bordered
        rowKey="id"
        columns={columns}
        dataSource={attributeData}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteAttribute(attributeId)).then(() => {
            setOpenDeleteModal(false);
            setAttributeId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setAttributeId(0);
        }}
      />
    </>
  );
};

export default Attributes;
