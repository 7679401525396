import { Space, Tag } from 'antd';
import { FC } from 'react';

import {
  HeaderDivider,
  LeftPage,
  PageHeader,
  PageTitle,
  ProductTitle,
  SaveButton
} from './styled';
import { IDetailsHeadProps } from './types';

const DetailsHead: FC<IDetailsHeadProps> = ({
  titleItem,
  onClose,
  id,
  headTitle,
  loadingAction,
  onSubmit,
  isDisabled = false
}) => {
  const title = id ? `View and Edit ${headTitle}` : `Add ${headTitle}`;

  return (
    <>
      <PageHeader>
        <Space>
          <LeftPage onClick={onClose} />

          <PageTitle level={3}>{title}</PageTitle>
          {Boolean(id && titleItem) && (
            <Tag color="green">
              <ProductTitle strong>{titleItem}</ProductTitle>
            </Tag>
          )}
        </Space>

        <SaveButton
          disabled={isDisabled}
          style={{ width: '200px' }}
          loading={loadingAction}
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
        >
          Save
        </SaveButton>
      </PageHeader>

      <HeaderDivider />
    </>
  );
};

export default DetailsHead;
