import {
  handleBackendValidationError,
  handleValidationError
} from 'helpers/errors';
import { useAppDispatch } from 'app/hooks';
import {
  calculatePrices,
  createProductCountry,
  deleteProductCountry,
  updateProductCountry
} from 'app/features/productCountries/slice';
import {
  CalculatePrices,
  NavyCbm,
  NavyManual,
  NavyPrice,
  Plane,
  PlanePrice,
  ProductCountryNavy,
  ProductCountryPlane,
  // ProductCountryPrices,
  ProductCountryPricesPost
} from 'app/features/productCountries/types';
import { cleanAndParse, parseInputValue } from 'helpers/NumericInput';
import { message } from 'antd';

import { CtryPrcFormList, CrudHandlerProps, PlaneCalc } from '../../types';

export const useCountryCrud = ({ form, productId }: CrudHandlerProps) => {
  const dispatch = useAppDispatch();

  const handleCalculate = async (name: number, transportType: string) => {
    const ctryPrcFormList: CtryPrcFormList[] =
      form.getFieldValue('ctryPrcFormList');

    const preSalesPrice =
      cleanAndParse(form.getFieldValue('pre_sales_price')) || 0;

    const {
      cost,
      percentOrFix,
      countryId,
      navyCheckbox,
      percentOrFixManual,
      percentOrFixPlane,
      percentOrFixValue,
      otherCost,
      lengthCm,
      widthCm,
      heightCm,
      weightKg,
      pricePerKg,
      otherCostPlane,
      percentOrFixValuePlane,
      otherCostManual,
      percentOrFixValueManual
    }: CtryPrcFormList = ctryPrcFormList[name];

    const navyPrice: NavyCbm = {
      type: 'NAVY_CBM',
      country_id: Number(countryId.value || countryId),
      ...(otherCost && { other_cost: cleanAndParse(otherCost) }),
      ...(percentOrFix === 'fixPrice' &&
        percentOrFixValue && {
          tax_fix: cleanAndParse(percentOrFixValue)
        }),
      ...(percentOrFix === 'percent' &&
        percentOrFixValue && {
          tax_percent: cleanAndParse(percentOrFixValue)
        })
    };

    const navyPriceManual: NavyManual = {
      type: 'NAVY_MANUAL',
      cost: cleanAndParse(cost),
      product_pre_sales_price: cleanAndParse(preSalesPrice),
      ...(otherCostManual && {
        other_cost: cleanAndParse(otherCostManual)
      }),
      ...(percentOrFixManual === 'fixPrice' &&
        percentOrFixValueManual && {
          tax_fix: cleanAndParse(percentOrFixValueManual)
        }),
      ...(percentOrFixManual === 'percent' &&
        percentOrFixValueManual && {
          tax_percent: cleanAndParse(percentOrFixValueManual)
        })
    };

    const planePrice: Plane = {
      pro_sales_price: cleanAndParse(preSalesPrice),
      type: 'PLANE',
      ...(lengthCm && { length: cleanAndParse(lengthCm) }),
      ...(widthCm && { width: cleanAndParse(widthCm) }),
      ...(heightCm && { height: cleanAndParse(heightCm) }),
      ...(weightKg && { weight: cleanAndParse(weightKg) }),
      price_per_kg: cleanAndParse(pricePerKg),
      ...(otherCostPlane && {
        other_cost: cleanAndParse(otherCostPlane)
      }),
      ...(percentOrFixPlane === 'fixPrice' && {
        tax_fix: cleanAndParse(percentOrFixValuePlane)
      }),
      ...(percentOrFixPlane === 'percent' && {
        tax_percent: cleanAndParse(percentOrFixValuePlane)
      })
    };

    const priceToCalculate: CalculatePrices =
      navyCheckbox && transportType === 'navy'
        ? navyPrice
        : !navyCheckbox && transportType === 'navy'
        ? navyPriceManual
        : planePrice;

    if (productId) {
      const res = await dispatch(
        calculatePrices({
          productId,
          priceToCalculate
        })
      );

      if (res.meta.requestStatus === 'fulfilled' && res.payload) {
        const resultNavy = res.payload;
        const resultPlane: PlaneCalc = res.payload;
        const ctryPrcFormList = form.getFieldValue('ctryPrcFormList');
        if (
          priceToCalculate.type === 'NAVY_CBM' ||
          priceToCalculate.type === 'NAVY_MANUAL'
        ) {
          ctryPrcFormList[name] = {
            ...ctryPrcFormList[name],
            standardPrice: parseInputValue(
              String(resultNavy !== 0 ? resultNavy : '')
            )
          };
          form.setFieldsValue({ ctryPrcFormList });
        } else if (priceToCalculate.type === 'PLANE')
          ctryPrcFormList[name] = {
            ...ctryPrcFormList[name],
            expressPrice: parseInputValue(
              String(resultPlane.express !== 0 ? resultPlane.express : '')
            ),
            subtotal: parseInputValue(
              String(resultPlane.subtotal !== 0 ? resultPlane.subtotal : '')
            )
          };
        form.setFieldsValue({ ctryPrcFormList });
      } else if (res.meta.requestStatus === 'fulfilled' && !res.payload) {
        message.error('No number to insert');
      } else {
        handleBackendValidationError(
          res?.payload?.response?.data?.message,
          res
        );
      }
    }
  };

  const handleCreateAndUpdate = async (
    name: number,
    transportType: string
  ): Promise<boolean> => {
    const ctryPrcFormList: CtryPrcFormList[] =
      form.getFieldValue('ctryPrcFormList');

    const {
      cost,
      percentOrFix,
      countryId,
      standardPrice,
      navyCheckbox,
      percentOrFixManual,
      percentOrFixPlane,
      percentOrFixValue,
      otherCost,
      commentNavy,
      lengthCm,
      cbmWeightKg,
      expressPrice,
      subtotal,
      widthCm,
      heightCm,
      weightKg,
      pricePerKg,
      otherCostPlane,
      percentOrFixValuePlane,
      commentPlane,
      otherCostManual,
      percentOrFixValueManual,
      itemId
    }: CtryPrcFormList = ctryPrcFormList[name];

    const navyPrice: NavyPrice = {
      ...(otherCost && {
        other_cost: Number(cleanAndParse(otherCost))
      }),
      ...(percentOrFix === 'fixPrice' &&
        percentOrFixValue && {
          tax_fix: Number(cleanAndParse(percentOrFixValue))
        }),
      ...(percentOrFix === 'percent' &&
        percentOrFixValue && {
          tax_percent: Number(cleanAndParse(percentOrFixValue))
        }),
      ...(cost && { cost: Number(cleanAndParse(cost)) }),
      ...(otherCostManual && {
        other_cost: Number(cleanAndParse(otherCostManual))
      }),
      ...(percentOrFixManual === 'fixPrice' &&
        percentOrFixValueManual && {
          tax_fix: Number(cleanAndParse(percentOrFixValueManual))
        }),
      ...(percentOrFixManual === 'percent' &&
        percentOrFixValueManual && {
          tax_percent: Number(cleanAndParse(percentOrFixValueManual))
        })
    };

    const planePrice: PlanePrice = {
      ...(lengthCm && { length: cleanAndParse(lengthCm) }),
      ...(widthCm && { width: cleanAndParse(widthCm) }),
      ...(heightCm && { height: cleanAndParse(heightCm) }),
      ...(cbmWeightKg && { cbm_weight: cleanAndParse(cbmWeightKg) }),
      ...(weightKg && { weight: cleanAndParse(weightKg) }),
      ...(pricePerKg && { price_per_kg: cleanAndParse(pricePerKg) }),
      ...(subtotal && { subtotal: cleanAndParse(subtotal) }),
      ...(otherCostPlane && {
        other_cost_plane: cleanAndParse(otherCostPlane)
      }),
      ...(percentOrFixPlane === 'fixPrice' &&
        percentOrFixValuePlane && {
          tax_fix_plane: cleanAndParse(percentOrFixValuePlane)
        }),
      ...(percentOrFixPlane === 'percent' &&
        percentOrFixValuePlane && {
          tax_percent_plane: cleanAndParse(percentOrFixValuePlane)
        })
    };

    const productCountryNavy: ProductCountryNavy = {
      country_id: Number(countryId.value || countryId),
      ...(standardPrice && { total_navy_price: cleanAndParse(standardPrice) }),
      navy_price_obj: navyPrice,
      ...(commentNavy && { navy_comment: commentNavy }),
      logistics_type: navyCheckbox ? 'NAVY_CBM' : 'NAVY_MANUAL'
    };

    const productCountryPlane: ProductCountryPlane = {
      country_id: Number(countryId.value || countryId),
      plane_price_obj: planePrice,
      ...(expressPrice && { total_plane_price: cleanAndParse(expressPrice) }),
      ...(commentPlane && { plane_comment: commentPlane }),
      logistics_type: navyCheckbox ? 'NAVY_CBM' : 'NAVY_MANUAL'
    };

    const productCountry: ProductCountryPricesPost =
      transportType === 'navy' ? productCountryNavy : productCountryPlane;

    try {
      await form.validateFields();

      if (productId && itemId) {
        const res = await dispatch(
          updateProductCountry({
            itemId,
            productId,
            productCountry,
            transportType
          })
        );

        if (res.meta.requestStatus === 'fulfilled' && res.payload) {
          form.setFieldsValue({ ...form.getFieldsValue(), submitted: true });

          return true;
        }

        if (res.meta.requestStatus !== 'fulfilled' && !res.payload) {
          handleBackendValidationError(
            res?.payload?.response?.data?.message,
            res
          );
        }
      } else if (productId && !itemId) {
        const res = await dispatch(
          createProductCountry({
            productId,
            productCountry,
            transportType
          })
        );

        if (res.meta.requestStatus === 'fulfilled' && res.payload) {
          form.setFieldsValue({ ...form.getFieldsValue(), submitted: true });

          return true;
        }

        if (res.meta.requestStatus !== 'fulfilled' && !res.payload) {
          handleBackendValidationError(
            res?.payload?.response?.data?.message,
            res
          );
        }
      }

      return false;
    } catch (error) {
      handleValidationError(error);

      return false;
    }
  };

  const handleDelete = async (
    itemId: number | undefined,
    productId: number | undefined,
    remove: (name: number) => void,
    name: number,
    type: string
  ) => {
    if (itemId && productId) {
      if (type === 'navy') {
        const res = await dispatch(
          deleteProductCountry({ itemId, productId, type: `/${type}` })
        );

        if (res.meta.requestStatus === 'fulfilled' && res.payload) {
          form.setFieldsValue({ ...form.getFieldsValue(), submitted: true });

          return true;
        }
      } else if (type === 'plane') {
        const res = await dispatch(
          deleteProductCountry({ itemId, productId, type: `/${type}` })
        );

        if (res.meta.requestStatus === 'fulfilled' && res.payload) {
          form.setFieldsValue({ ...form.getFieldsValue(), submitted: true });

          return true;
        }
      } else if (type === 'all') {
        const res = await dispatch(deleteProductCountry({ itemId, productId }));

        if (res.meta.requestStatus === 'fulfilled' && res.payload) {
          form.setFieldsValue({ ...form.getFieldsValue(), submitted: true });

          return true;
        }
      }

      return false;
    } else if (!itemId) {
      remove(name);
    }
  };

  return {
    handleCreateAndUpdate,
    handleDelete,
    handleCalculate
  };
};

export default useCountryCrud;
