import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import {
  ErrorType,
  showErrorMessage,
  showErrorUploadMessage
} from 'helpers/errors';
import { Modal, message } from 'antd';

import {
  selectAttributeLimit,
  selectAttributePage,
  selectAttributeSearch
} from './selectors';
import {
  AttributesState,
  IAttributeEditValues,
  IAttributePostValues
} from './types';

const initialState: AttributesState = {
  attribute: [],
  productAttributes: [],
  attributeById: null,
  loading: false,
  loadingAction: false,
  loadingById: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const attributeApi = createAsyncThunk(
  'attributes/attributeApi',
  async (byCategory: boolean, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectAttributePage()(state);
    const search = selectAttributeSearch()(state);
    const limit = selectAttributeLimit()(state);

    const searchData = byCategory
      ? {
          fromCategory: search
        }
      : { search };

    try {
      const res = await client.get(`/attribute`, {
        params: {
          page,
          limit,
          ...searchData
        }
      });

      return dispatch(setAttribute(res.data.payload));
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const attributeApiProduct = createAsyncThunk(
  'attributes/attributeApiProduct',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get('/attribute');

      return res.data.payload.rows;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getAttributeById = createAsyncThunk(
  'attribute/attributeById',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.get(`/attribute/${id}`);

      return dispatch(setAtributeById(response.data.payload));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postAttributes = createAsyncThunk(
  'attribute/postAttributes',
  async (data: IAttributePostValues, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.post('/attribute', { ...data });

      if (response.status === 200) {
        message.success(`Attribute created successfully`);
        await dispatch(attributeApi(false));

        return response.data.payload;
      }
    } catch (err) {
      return rejectWithValue(err as AxiosError<ErrorType>);
    }
  }
);

export const attributesEdit = createAsyncThunk(
  'attribute/attributesEdit',
  async (
    { id, ...data }: IAttributeEditValues,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await client.patch(`/attribute/${id}`, { ...data });

      if (response.status === 200) {
        message.success(`Attribute with id ${id} updated successfully`);
        await dispatch(attributeApi(false));

        return response.data.payload;
      }
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteAttribute = createAsyncThunk(
  'attribute/deleteAttribute',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/attribute/${id}`);
      message.success(`Attribute with id ${id} deleted successfully`);

      await dispatch(attributeApi(false));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const handleUploadAttr = createAsyncThunk(
  'attribute/handleUploadAttr',
  async (file: File, { dispatch, rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await client.post('attribute/excel', formData).then(() => {
        Modal.success({
          content: 'Excel file uploaded successfully!'
        });
      });
      await dispatch(attributeApi(false));
    } catch (err) {
      return rejectWithValue(
        showErrorUploadMessage(err as AxiosError<ErrorType>)
      );
    }
  }
);

export const getAttributesByProductId = createAsyncThunk(
  'product/attributesByProductId',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(
        `product/${productId}/attribute?page=1&limit=50`
      );

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    setAtributeById: (state, action) => {
      state.attributeById = action.payload;
    },
    setAttribute: (state, action) => {
      state.attribute = action.payload.rows;
      state.count = action.payload.count;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(attributeApi.pending, state => {
        state.loading = true;
      })
      .addCase(attributeApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(attributeApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getAttributeById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getAttributeById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getAttributeById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(attributesEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(attributesEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(attributesEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postAttributes.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postAttributes.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postAttributes.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(attributeApiProduct.fulfilled, (state, action) => {
        state.attribute = action.payload.rows;
      })
      .addCase(deleteAttribute.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteAttribute.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteAttribute.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(handleUploadAttr.pending, state => {
        state.loading = true;
      })
      .addCase(handleUploadAttr.fulfilled, state => {
        state.loading = false;
      })
      .addCase(handleUploadAttr.rejected, state => {
        state.loading = false;
      })
      .addCase(getAttributesByProductId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getAttributesByProductId.fulfilled, (state, action) => {
        state.productAttributes = action.payload.rows;
        state.loadingById = false;
      })
      .addCase(getAttributesByProductId.rejected, state => {
        state.loadingById = false;
      });
  }
});

export const { setAtributeById, setAttribute, setPage, setSearch, setLimit } =
  attributeSlice.actions;
export default attributeSlice.reducer;
