import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';
import { RootState } from 'app/store';

import { CountryState } from './types';
import { ICbmPost, ICbmUpdate } from 'pages/Cbm/types';
import {
  selectCountryLimit,
  selectCountryPage,
  selectCountrySearch
} from '../country/selectors';
import { getCbmCountries } from '../cbmCountries/slice';

const initialState: CountryState = {
  loading: false,
  country: [],
  page: 1,
  limit: 50,
  search: '',
  count: 0
};

export const countryApi = createAsyncThunk(
  'country/countryApi',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectCountryPage()(state);
    const search = selectCountrySearch()(state);
    const limit = selectCountryLimit()(state);

    try {
      const res = await client.get(`/country`, {
        params: {
          search,
          page,
          limit
        }
      });

      return res.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const createCbm = createAsyncThunk(
  'country/createCbm',
  async (values: ICbmPost, { rejectWithValue, dispatch }) => {
    try {
      await client.post('/country/cbm', values);
      message.success(`CBM created successfully`, 2);

      dispatch(countryApi());
      dispatch(getCbmCountries({}));
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);
export const editCbm = createAsyncThunk(
  'country/editCbm',
  async (
    {
      cbmId,
      sanitisedDataProduct
    }: { cbmId: number; sanitisedDataProduct: ICbmUpdate },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await client.put(`/country/cbm/${cbmId}`, sanitisedDataProduct);
      message.success(`CBM Updated successfully`, 2);

      dispatch(countryApi());
      dispatch(getCbmCountries({}));
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);
export const deleteCbm = createAsyncThunk(
  'country/deleteCbm',
  async (country_id: number, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/country/cbm/${country_id}`);
      message.success(`CBM price removed successfully`, 2);

      dispatch(countryApi());
      dispatch(getCbmCountries({}));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(countryApi.pending, state => {
        state.loading = true;
      })
      .addCase(countryApi.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count;
        state.country = action.payload.rows;
      })
      .addCase(countryApi.rejected, state => {
        state.loading = false;
      });
  }
});

export const { setPage, setSearch, setLimit } = countrySlice.actions;
export default countrySlice.reducer;
