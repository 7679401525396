import { Col, Form, Row, Select, UploadFile, message } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import Dragger from 'antd/es/upload/Dragger';
import { useCustomRequest } from 'Hook/useCustomRequest';
import { fetchInvoiceFilesApi, setSearch } from 'app/features/files/slice';
import {
  selectInvoicesByProductId,
  selectNewProduct
} from 'app/features/products/selectors';
import { useParams } from 'react-router-dom';
import { IProductEditValues } from 'app/features/products/types';
import {
  // getInvoicesByProductId,
  productEdit
} from 'app/features/products/slice';
import { _debounce, getFilename } from 'helpers/utils';
import { selectFilesLoading } from 'app/features/files/selectors';
import { handleBackendValidationError } from 'helpers/errors';
import Uploader from 'Hook/uploadrer';

import { TagRender } from '../TagRender';
import { IFilesAndPhotos } from './types';

const validateMessages = {
  required: "'${label}' is Required!"
};

const FilesAndPhotos: FC<IFilesAndPhotos> = ({
  productById,
  attributes,
  onChangeImageUpload,
  onChangeFileUpload,
  fileList,
  form,
  contractFileList,
  setFileList,
  onClose
}) => {
  //State
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);

  //Dispatch
  const dispatch = useAppDispatch();
  const delay = _debounce(300);

  //selectors
  const loadingAction = useAppSelector(selectFilesLoading());
  const newProduct = useAppSelector(selectNewProduct());
  const productContractFiles = useAppSelector(selectInvoicesByProductId());

  const { id } = useParams();
  const productId = Number(id);
  const newProductId = Number(newProduct?.dataValues.id);

  const folderName = productById?.folder_name || '';
  const newFolderName = newProduct?.dataValues.folder_name || '';

  const pictureUpload = useCustomRequest({
    url: 'file/create',
    file_type: 'pictures',
    folder: productId ? `products/${folderName}` : `products/${newFolderName}`
  });

  const fileUpload = useCustomRequest({
    url: 'file/createInvoiceFile',
    productId: productId ? productId : newProductId
  });

  const onImageRemove = async (file: UploadFile) => {
    setRemovedFiles(prev => [...prev, file.uid]);

    const updatedFileList = fileList.filter(item => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const onFileRemove = async (id: string) => {
    setRemovedFiles(prev => [...prev, id]);
  };

  useEffect(() => {
    dispatch(fetchInvoiceFilesApi());
  }, [productId, dispatch]);

  useEffect(() => {
    if (id && productById) {
      const images = productById.files;
      const files = productContractFiles;

      form.setFieldsValue({
        filesData: files.map(file => ({
          value: file.id,
          label: file.path
        })),
        images
      });
    }
  }, [productById, id, form, productContractFiles]);

  const onFinish = async (values: IProductEditValues) => {
    const { filesData, uploadedFiles } = values;
    const contractFiles = filesData?.flatMap(item => item.value) ?? [];
    const updatedContractFiles =
      filesData?.filter(item => typeof item !== 'object') ?? [];

    // const imageFiles = images?.flatMap(item => item) ?? [];
    const uploadedContractFiles = uploadedFiles?.flatMap(item => item) ?? [];

    const newContractFiles: string[] = uploadedContractFiles
      ?.map(file =>
        file?.response?.data?.payload?.map((item: { id: string }) => item.id)
      )
      .filter(Boolean)
      .flat();

    // const existingFiles: string[] = imageFiles
    //   .filter(item => !item.response)
    //   .map(item => ('uid' in item ? item.uid : (item as { id: string }).id))
    //   .filter(Boolean);

    // const newFiles: string[] = imageFiles
    //   ?.map(file =>
    //     file.response?.data?.payload?.map((item: { id: string }) => item.id)
    //   )
    //   .filter(Boolean)
    //   .flat();

    const combinedFiles: string[] = [
      ...(updatedContractFiles ?? ''),
      ...(contractFiles ?? ''),
      ...(newContractFiles ?? '')
    ].filter(id => id && id !== '') as string[];

    if (productId && productById) {
      const attributesPost = attributes.map(attribute => ({
        attribute_id: attribute.attributeId,
        type: attribute.value?.type,
        ...(attribute.value?.type === 'enum'
          ? { enum_value: attribute.value?.enumValue }
          : {
              min: Number(attribute.value?.numberRange?.min),
              max: Number(attribute.value?.numberRange?.max)
            })
      }));

      const dataProduct = {
        categories: productById.categories?.map(item => Number(item.id)) || [],
        files: combinedFiles,
        ...(removedFiles.length && { deletedFiles: removedFiles }),
        attributes: attributesPost
      };

      const res = await dispatch(
        productEdit({
          productId: productId,
          dataProduct
        })
      );

      if (res.meta.requestStatus === 'fulfilled') {
        onClose();
      } else {
        handleBackendValidationError(
          res?.payload?.response?.data?.message,
          res
        );
      }
    } else if (newProductId && productById) {
      const dataProduct = {
        categories: productById.categories?.map(item => Number(item.id)) || [],
        files: combinedFiles,
        attributes: []
      };

      const res = await dispatch(
        productEdit({
          productId: newProductId,
          dataProduct
        })
      );

      if (res.meta.requestStatus === 'fulfilled') {
        onClose();
      } else {
        handleBackendValidationError(
          res?.payload?.response?.data?.message,
          res
        );
      }
    } else {
      message.error('no product is available');
    }
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(setPage(1));
  //     dispatch(setSearch(''));
  //     dispatch(setLimit(12));
  //   };
  // }, [dispatch]);

  const contractOptions = contractFileList.map(option => ({
    value: option.uid,
    label: getFilename(option.name)
  }));

  // const onImageRemove = (file) => {
  //   // Your logic to remove the image from the fileList
  //   const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
  //   setFileList(updatedFileList);
  // };

  return (
    <Form
      validateMessages={validateMessages}
      size="large"
      form={form}
      name="formFilesAndImages"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError={{
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      }}
    >
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            label="Product Datasheet"
            name={'filesData'}
            rules={[{ required: false }]}
            // getValueFromEvent={event => event?.values || event}
          >
            <Select
              mode="multiple"
              loading={loadingAction}
              placeholder="Select Contract"
              optionLabelProp="label"
              optionFilterProp="label"
              tokenSeparators={[',']}
              tagRender={props => (
                <TagRender
                  {...props}
                  customOnClose={onFileRemove}
                  title="Delete File"
                  deleteConfirmMessage="Are you sure you want to delete this file?"
                />
              )}
              showSearch
              onSearch={value => {
                delay(() => {
                  dispatch(setSearch(value));
                  dispatch(fetchInvoiceFilesApi());
                });
              }}
              onSelect={() => dispatch(setSearch(''))}
              options={contractOptions}
            ></Select>
          </Form.Item>
          <Form.Item
            // label="Product Datasheet"
            name={'uploadedFiles'}
            rules={[{ required: false }]}
            getValueFromEvent={event => event?.fileList || []}
          >
            <Dragger
              style={{ marginTop: 10 }}
              customRequest={fileUpload}
              accept=".pdf"
              multiple
              onChange={onChangeFileUpload}
            >
              <p className="ant-upload-text">
                Click or drag (Contacts, Contracts, Invoice) to this area to
                upload
              </p>
            </Dragger>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="images"
            label="Product Images"
            getValueFromEvent={event => event?.fileList || []}
          >
            <Uploader
              onChange={onChangeImageUpload}
              fileList={fileList}
              customRequest={pictureUpload}
              onRemove={onImageRemove}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FilesAndPhotos;
