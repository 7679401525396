import React, { useEffect, useState } from 'react';
import { AuditOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Layout,
  Modal,
  Skeleton,
  theme,
  Typography
} from 'antd';
import { logOut } from 'app/features/auth/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Outlet, useNavigate } from 'react-router-dom';
import Meta from 'antd/es/card/Meta';
import { getUserRole } from 'app/features/users/slice';
import { selectUserRole } from 'app/features/users/selectors';

import {
  LogoImgWrapper,
  SiderTop,
  LayoutStyled,
  MenuOutlined1,
  MenuOutlined2,
  ContentStyled,
  HeaderStyled,
  SiderStyled,
  ContentWrapper,
  MenuWrraper,
  CustomDivider,
  VersionTagLong,
  DarkCard,
  VersionTag
} from './styled';
import MenuSider from 'components/MenuSider';
import FilePage from 'components/FilePage';

const { Paragraph } = Typography;

const version = process.env.REACT_APP_VERSION;

const PageLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserRole());
  }, [dispatch]);

  const userRole = useAppSelector(selectUserRole);

  const onCollapse = () => setCollapsed(prev => !prev);

  const onClickOut = () => {
    Modal.confirm({
      title: 'logOut',
      content: <Paragraph>Are you sure?</Paragraph>,
      onOk: () => dispatch(logOut())
    });
  };

  return (
    <LayoutStyled>
      <FilePage open={open} setOpen={setOpen} />
      <SiderStyled
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={60}
        width={220}
      >
        <SiderTop collapsed={collapsed}>
          {collapsed ? (
            <MenuOutlined2 onClick={onCollapse} />
          ) : (
            <MenuOutlined1 onClick={onCollapse} />
          )}
        </SiderTop>

        <MenuSider />
        {!collapsed && (
          <DarkCard>
            <Skeleton loading={false} avatar active>
              <Meta
                avatar={<Avatar icon={<UserOutlined />} />}
                title="Specialist"
                description={userRole?.first_name}
              />
            </Skeleton>
            <CustomDivider />
            <p className="value">
              <span className="key">Role:</span>{' '}
              {userRole?.role.toLocaleLowerCase().replaceAll('_', ' ')}
            </p>
            <p className="value">
              <span className="key">User name:</span> {userRole?.username}
            </p>
            <p className="value">
              <span className="key">Last name:</span>{' '}
              {userRole?.last_name.toLocaleLowerCase().replaceAll('_', ' ')}
            </p>
          </DarkCard>
        )}
        {!collapsed ? (
          <VersionTagLong color="orange">
            Under Construction V. {version}
          </VersionTagLong>
        ) : (
          <VersionTag color="orange">{version}</VersionTag>
        )}
      </SiderStyled>
      <Layout>
        <HeaderStyled>
          <LogoImgWrapper onClick={() => navigate('/')}>
            Big Database
          </LogoImgWrapper>
          <div style={{ display: 'flex' }}>
            <MenuWrraper>
              <Button
                size="large"
                style={{ marginRight: '35px' }}
                icon={<AuditOutlined />}
                onClick={() => setOpen(true)}
              >
                Files
              </Button>
            </MenuWrraper>
            <Button size="large" onClick={onClickOut} icon={<LogoutOutlined />}>
              LogOut
            </Button>
          </div>
        </HeaderStyled>
        <ContentWrapper collapsed={collapsed}>
          <ContentStyled bgcolor={colorBgContainer}>
            <Outlet />
          </ContentStyled>
        </ContentWrapper>
      </Layout>
    </LayoutStyled>
  );
};

export default PageLayout;
