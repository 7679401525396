import { Button, Image, Modal, Table, Tooltip } from 'antd';
import {
  setLimit,
  setPage,
  setSearch,
  businessApi,
  updatePartner
} from 'app/features/businesses/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { _debounce, getFileUrl } from 'helpers/utils';
import { Link, useNavigate } from 'react-router-dom';
import {
  DatabaseOutlined,
  EditOutlined,
  ExceptionOutlined
  // EyeOutlined
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {
  selectBusinessActionLoading,
  selectBusinessCount,
  selectBusinessData,
  selectBusinessLimit,
  selectBusinessLoading,
  selectBusinessPage,
  selectBusinessSearch
} from 'app/features/businesses/selectors';
import { Business } from 'app/features/businesses/types';
import countriesData from 'constants/countries';

import ContentTop from 'components/ContentTop';
import { ButtonsWrapper, ImgTableWrraper } from './styled';
import noImg from '../../assets/img/noimage.png';

const debounce = _debounce();

const Businesses = () => {
  //state
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isArchive, setIsArchive] = useState<boolean>();
  const [recordId, setRecordId] = useState<number>();
  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const partnerData = useAppSelector(selectBusinessData());
  const loading = useAppSelector(selectBusinessLoading());
  const page = useAppSelector(selectBusinessPage());
  const limit = useAppSelector(selectBusinessLimit());
  const count = useAppSelector(selectBusinessCount());
  const search = useAppSelector(selectBusinessSearch());
  const loadingAction = useAppSelector(selectBusinessActionLoading());

  useEffect(() => {
    debounce(() => dispatch(businessApi()));
  }, [dispatch, search, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const columns: ColumnsType<Business> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      align: 'center'
    },
    {
      title: 'Partner Name',
      dataIndex: 'name',
      key: 'name',
      width: 130,
      align: 'center'
    },
    {
      title: 'Country',
      dataIndex: 'country_id',
      key: 'country_id',
      align: 'center',
      width: 130,
      render: (value, record) => {
        const countryNames = record.country_arr?.map(
          countryId =>
            countriesData.find(country => country.id === countryId)?.name
        );

        const singleCountry = countriesData.find(
          country => country.id === value
        )?.name;

        return countryNames?.join(', ') || singleCountry;
      }
    },
    {
      title: 'Logo',
      dataIndex: 'logo_path',
      align: 'center',
      key: 'logo_path',
      width: 100,
      render: file => {
        return (
          <ImgTableWrraper>
            {file ? (
              <Image width={70} alt={file} src={getFileUrl(file)} />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },
    {
      title: 'Website',
      dataIndex: 'url',
      key: 'url',
      align: 'center',
      width: 150
    },
    {
      title: 'Owner’s email',
      dataIndex: 'owner_email',
      key: 'owner_email',
      align: 'center',
      width: 150
    },
    {
      title: 'Author',
      dataIndex: 'authorUser',
      key: 'authorUser',
      align: 'center',
      width: 150,
      render: user => (
        <div>
          {`${user?.first_name} ${user?.last_name}`}
          {/* <br /> */}
          {/* {user?.role.toLowerCase().replace(/_/g, ' ')} */}
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100
    },
    {
      title: 'Created date',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 200,
      render: (date: string) => {
        const createdAt = new Date(date);
        const formattedCreatedAt = createdAt?.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        });

        return <span>{formattedCreatedAt}</span>;
      }
    },
    {
      title: 'Modified date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      width: 200,
      render: (date: string) => {
        const modifiedAtDate = new Date(date);
        const formattedUpdatedAt = modifiedAtDate?.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        });

        return <span>{formattedUpdatedAt}</span>;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      width: 160,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Tooltip title="View and edit details">
              <Link to={`details/${record.id}`}>
                <Button type="primary" size="large" icon={<EditOutlined />} />
              </Link>
            </Tooltip>
            <Tooltip title="Suspend Record">
              <Button
                size="large"
                danger
                type="dashed"
                icon={<ExceptionOutlined />}
                onClick={() => {
                  setRecordId(record.id);
                  setIsArchive(false);
                  setOpenDeleteModal(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Archive Record">
              <Button
                size="large"
                danger
                icon={<DatabaseOutlined />}
                onClick={() => {
                  setRecordId(record.id);
                  setIsArchive(true);
                  setOpenDeleteModal(true);
                }}
              />
            </Tooltip>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Businesses"
        placeholder="Search Business"
        buttonText="Add Business"
        onClickAdd={() => navigate('details')}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={partnerData}
        loading={loading}
        rowKey="id"
        scroll={{ x: 1500, y: '100%' }}
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
      <Modal
        title={
          isArchive
            ? `Do you want to Archive this Business?`
            : `Do you want to Suspend this Business?`
        }
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={async () => {
          if (isArchive) {
            await dispatch(updatePartner({ status: 'ARCHIVED', id: recordId }));
          } else {
            await dispatch(
              updatePartner({ status: 'SUSPENDED', id: recordId })
            );
          }

          setOpenDeleteModal(false);
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
      />
    </>
  );
};

export default Businesses;
