import { Modal, Button, Input, Form } from 'antd';
import { categoryEdit, postCategory } from 'app/features/category/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectCategoryActionLoading,
  selectCategoryById
} from 'app/features/category/selectors';
import { FC, memo, useEffect, useState } from 'react';
import { CategoryParams, ICategoryById } from 'app/features/category/types';
import { handleBackendValidationError } from 'helpers/errors';

import { ICategoryModalProps } from './types';

const CategoryModal: FC<ICategoryModalProps> = ({
  isOpen,
  params,
  onClose
}) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const loadingAction = useAppSelector(selectCategoryActionLoading());
  const categoryById = useAppSelector(selectCategoryById());
  const [selectedCategory, setSelectedCategory] =
    useState<ICategoryById | null>(null);

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
    setSelectedCategory(null);
  };

  const modalTitle = () => {
    if (!params.parentId && !params.categoryId) {
      return 'Add Category';
    } else if (!params.categoryId && params.parentId) {
      return 'Add SubCategory';
    }

    return 'Edit Category';
  };

  useEffect(() => {
    if (categoryById) {
      form.setFieldsValue({
        title_en: categoryById.title_en,
        key: categoryById.key,
        hc_code: categoryById.hc_code
      });
      setSelectedCategory(categoryById);
    }
  }, [categoryById, form]);

  const onSave = async (values: CategoryParams) => {
    const { title_en, key, hc_code } = values;

    const dataPost = {
      title_en,
      key,
      ...(hc_code && { hc_code })
    };

    const dataPatch = {
      title_en,
      ...(hc_code && { hc_code })
    };

    if (params.categoryId) {
      const res = await dispatch(
        categoryEdit({ ...dataPatch, id: params.categoryId })
      );

      if (res.meta.requestStatus === 'fulfilled' && res.payload) {
        handleCloseModal();
      } else {
        handleBackendValidationError(
          res?.payload?.response?.data?.message,
          res
        );
      }
    } else {
      const res = await dispatch(
        postCategory({ ...dataPost, parentId: params.parentId })
      );

      if (res.meta.requestStatus === 'fulfilled' && res.payload) {
        handleCloseModal();
      } else {
        handleBackendValidationError(
          res?.payload?.response?.data?.message,
          res
        );
      }
    }
  };

  return (
    <Modal
      title={modalTitle()}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button
          type="primary"
          onClick={form.submit}
          loading={loadingAction}
          key="Save"
        >
          Save
        </Button>
      ]}
    >
      <Form
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        form={form}
        onFinish={onSave}
        layout="vertical"
        size="large"
      >
        <Form.Item
          name="title_en"
          label="English Title"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter title in English" />
        </Form.Item>
        <Form.Item name="key" label="Category Key" rules={[{ required: true }]}>
          <Input
            disabled={!!params.categoryId}
            placeholder="Enter Category Key"
          />
        </Form.Item>
        <Form.Item
          name="hc_code"
          label="Code"
          rules={[{ required: false, min: 2, message: 'Minimum 2 characters' }]}
        >
          <Input
            type="number"
            disabled={!!selectedCategory?.hc_code}
            placeholder="Enter Category code"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(CategoryModal);
