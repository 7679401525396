import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectLoginIsLoggedIn } from 'app/features/auth/selectors';
import { WelcomeHeader, WelcomeSubtext, WelcomeTextWrapper } from 'styled';

import Login from 'pages/Login';
import PageLayout from 'components/PageLayout';
import Categories from 'pages/Categories';
import Products from 'pages/Products';
import ProductDetails from 'pages/ProductDetails';
import Vendors from 'pages/Vendors';
import VendorDetails from 'pages/VendorDetails';
// import Sections from 'pages/Sections';
import Attributes from 'pages/Attributes';
import Users from 'pages/Users';
import AttributesDetails from 'pages/AttributesDetails';
// import SectionsDetails from 'pages/SectionsDetails';
import Cbm from 'pages/Cbm';
import Accessories from 'pages/Accessories';
import AccessoriesDetails from 'pages/AccessoriesDetails';
import Businesses from 'pages/Businesses';
import BusinessDetails from 'pages/BusinessDetails';
import { routePath } from './constants/routes';

const App = () => {
  const isLoggedIn = useAppSelector(selectLoginIsLoggedIn());

  // const ErrorBoundary = () => {
  //   const error = useRouteError();
  //   console.error(error);

  //   // Uncaught ReferenceError: path is not defined
  //   return <div>Dang!</div>;
  // };

  const HomeText = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    return (
      <WelcomeTextWrapper>
        <WelcomeHeader>Welcome to Big Database</WelcomeHeader>
        <WelcomeSubtext>{formattedDate}</WelcomeSubtext>
      </WelcomeTextWrapper>
    );
  };

  const AuthenticatedRoutes = [
    {
      index: true,
      element: <HomeText />
    },
    {
      path: routePath.getCategories(),
      element: <Categories />
    },
    {
      path: routePath.getUsers(),
      element: <Users />
    },
    {
      path: routePath.getProducts('*'),
      children: [
        { index: true, Component: () => <Products /> },
        { path: routePath.getDetails(), element: <ProductDetails /> },
        { path: routePath.getDetails(':id'), element: <ProductDetails /> }
      ]
    },
    {
      path: routePath.getCbn(),
      element: <Cbm />
    },
    {
      path: routePath.getAttributes('*'),
      children: [
        { index: true, Component: () => <Attributes /> },
        { path: routePath.getDetails(), element: <AttributesDetails /> },
        { path: routePath.getDetails(':id'), element: <AttributesDetails /> }
      ]
    },
    {
      path: routePath.getVendors('*'),
      children: [
        { index: true, Component: () => <Vendors /> },
        { path: routePath.getDetails(), element: <VendorDetails /> },
        { path: routePath.getDetails(':id'), element: <VendorDetails /> }
      ]
    },
    {
      path: routePath.getAccessories('*'),
      children: [
        { index: true, Component: () => <Accessories /> },
        { path: routePath.getDetails(), element: <AccessoriesDetails /> },
        { path: routePath.getDetails(':id'), element: <AccessoriesDetails /> }
      ]
    },
    {
      path: routePath.getBusinesses('*'),
      children: [
        { index: true, Component: () => <Businesses /> },
        { path: routePath.getDetails(), element: <BusinessDetails /> },
        { path: routePath.getDetails(':id'), element: <BusinessDetails /> }
      ]
    }
  ];

  const UnauthenticatedRoutes = [
    { path: routePath.getMain(), element: <Login /> }
  ];

  const routes = (isLoggedIn: boolean) => [
    {
      path: routePath.getMain(),
      element: isLoggedIn ? <PageLayout /> : <Login />,
      children: isLoggedIn ? AuthenticatedRoutes : UnauthenticatedRoutes
    }
  ];

  const router = createBrowserRouter(routes(isLoggedIn));

  return <RouterProvider router={router} />;
};

export default App;
