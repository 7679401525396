import {
  CheckOutlined,
  EditOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  FloatButton,
  Form,
  List,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  message
} from 'antd';
import { selectProductLoadingById } from 'app/features/products/selectors';
import {
  selectCountryData,
  selectCountryLoading,
  selectCountryPage
  // selectCountrySearch
} from 'app/features/country/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  countryApi,
  createCbm,
  deleteCbm,
  editCbm,
  setPage as setCountryPage,
  setSearch as setCountrySearch,
  setSearch
} from 'app/features/country/slice';
import { useEffect, useState } from 'react';
import { IProductCountry } from 'app/features/country/types';
import { DefaultOptionType } from 'antd/es/select';
import {
  selectCbmCountryCount,
  selectCbmCountryData,
  selectCbmCountryLimit,
  selectCbmCountryLoading,
  selectCbmCountryPage
} from 'app/features/cbmCountries/selectors';
import {
  getCbmCountries,
  setLimit,
  setPage as setCBMCountryPage
} from 'app/features/cbmCountries/slice';
import { _debounce } from 'helpers/utils';
import NumericInput, {
  cleanAndParse,
  parseInputValue
} from 'helpers/NumericInput';
import { isNotZeroRule } from 'helpers/validators';
import { useNavigate } from 'react-router-dom';

import { FormContainer } from './styled';
import { ICbmPost } from './types';

const validateMessages = {
  required: "'${label}' is Required!"
};

const delay = _debounce(500);

const Cbm = () => {
  const countryData = useAppSelector(selectCountryData());
  const cbmCountryData = useAppSelector(selectCbmCountryData());
  const cbmCountryLoading = useAppSelector(selectCbmCountryLoading());
  const page = useAppSelector(selectCbmCountryPage());
  const limit = useAppSelector(selectCbmCountryLimit());
  const count = useAppSelector(selectCbmCountryCount());
  const currentCountryPage = useAppSelector(selectCountryPage());
  const loading = useAppSelector(selectCountryLoading());
  const productByIdLoading = useAppSelector(selectProductLoadingById());

  const [selectedCountry, setSelectedCountry] = useState<IProductCountry>();
  const [editMode, setEditMode] = useState(false);
  const [loadedCountryData, setLoadedCountryData] = useState<IProductCountry[]>(
    []
  );

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(countryApi());
    dispatch(getCbmCountries({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, page]);

  useEffect(() => {
    if (countryData.length) {
      setLoadedCountryData(countryData);
    }
  }, [countryData]);

  useEffect(() => {
    return () => {
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const handleCountrySearch = (newValue: string) => {
    delay(() => {
      dispatch(setCountrySearch(newValue));
      dispatch(countryApi());
    });
  };

  const handleCountrySelect = (option: DefaultOptionType) => {
    const selected = option.country;
    setSelectedCountry(selected);
    setEditMode(!!selected?.cbm_country);

    form.setFieldsValue({
      price: parseInputValue(selected?.cbm_country?.price) || ''
    });
  };

  const loadMoreData = async () => {
    if (loading) {
      return;
    }

    try {
      const nextPage = currentCountryPage + 1;
      dispatch(setCountryPage(nextPage));
      const newData = await dispatch(countryApi());
      setLoadedCountryData([...loadedCountryData, ...newData.payload.rows]);
    } catch (error) {
      message.error(`Error fetching more data: ${error}`);
    }
    // finally {
    // }
  };

  const handlePopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.currentTarget;

    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      loadMoreData();
    }
  };

  const onFinish = async (values: ICbmPost) => {
    const { country_id, price } = values;

    const dataProduct: ICbmPost = {
      country_id,
      price: cleanAndParse(price)
    };

    const cbmId = editMode && selectedCountry?.cbm_country.id;

    try {
      if (editMode && cbmId) {
        const { country_id, ...sanitisedDataProduct } = dataProduct;
        await dispatch(editCbm({ cbmId, sanitisedDataProduct }));
      } else {
        await dispatch(createCbm(dataProduct));
      }
    } catch (error) {
      message.error(`An error occurred: ${error}`, 2);
    }
  };

  return (
    <Form
      validateMessages={validateMessages}
      size="large"
      form={form}
      name="formLogistics"
      layout="vertical"
      // initialValues={{
      //   countryPrice: [{}]
      // }}
      onFinish={onFinish}
      scrollToFirstError={{
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      }}
    >
      <Row>
        <FormContainer>
          <Space.Compact>
            <Col span={13}>
              <Form.Item
                label="Country"
                name="country_id"
                rules={[{ required: false }]}
              >
                <Select
                  showSearch
                  onSearch={handleCountrySearch}
                  loading={loading}
                  onSelect={(_, option) => handleCountrySelect(option)}
                  placeholder="Select Country"
                  optionLabelProp="label"
                  optionFilterProp="label"
                  onPopupScroll={handlePopupScroll}
                >
                  {/* {countryData.map(country => { */}
                  {loadedCountryData.map(country => {
                    return (
                      <Select.Option
                        key={country.id}
                        value={country.id}
                        label={country.name}
                        country={country}
                      >
                        {country.name}{' '}
                        {country.cbm_country && (
                          <Tag style={{ float: 'right' }} color="green">
                            CBM: {parseInputValue(country.cbm_country.price)}
                          </Tag>
                        )}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Price"
                name="price"
                rules={[isNotZeroRule, { required: true }]}
              >
                <NumericInput min={0.1} name="price" />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label=" ">
                <Button
                  type="primary"
                  loading={productByIdLoading}
                  onClick={() => {
                    if (editMode) {
                      Modal.confirm({
                        title: 'Confirm Country Price Change',
                        content:
                          'Are you sure you want to change the country price? This action will recalculate prices in all associated products.',
                        onOk: () => {
                          form.submit();
                        }
                      });
                    } else {
                      form.submit();
                    }
                  }}
                  block
                  style={{ width: '55%' }}
                  icon={editMode ? <EditOutlined /> : <CheckOutlined />}
                >
                  {editMode ? 'Update Price' : 'Submit Price'}
                </Button>

                {Boolean(editMode) && (
                  <MinusCircleOutlined
                    style={{
                      marginLeft: 20,
                      color: 'red',
                      fontSize: '19px'
                    }}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Confirm',
                        content: `Are you sure you want to remove Country Price? `,
                        onOk: async () => {
                          await dispatch(
                            deleteCbm(Number(selectedCountry?.cbm_country.id))
                          );

                          form.setFieldsValue({
                            price: ''
                          });
                        }
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Space.Compact>
        </FormContainer>
      </Row>
      <div
        style={{
          height: '90%',
          overflow: 'auto',
          padding: '0 16px',
          border: '1px solid rgba(140, 140, 140, 0.35)'
        }}
      >
        <Col span={12} offset={6}>
          <List
            loading={cbmCountryLoading}
            dataSource={cbmCountryData}
            pagination={{
              align: 'center',
              style: { margin: 8 },
              current: page,
              onChange: (newPage, newPageSize) => {
                dispatch(setCBMCountryPage(newPage));
                dispatch(setLimit(newPageSize));
              },
              pageSize: limit,
              total: count
              // showSizeChanger: true
            }}
            renderItem={item =>
              item.cbm_country && (
                <List.Item key={item.id}>
                  <List.Item.Meta title={item.name} />
                  <div>{parseInputValue(item.cbm_country.price)}$</div>
                  <MinusCircleOutlined
                    style={{
                      marginLeft: 20,
                      color: 'red',
                      fontSize: '19px'
                    }}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Confirm',
                        content: `Are you sure you want to remove "${item?.name}" Price? `,
                        onOk: async () => {
                          await dispatch(
                            deleteCbm(Number(item?.cbm_country.id))
                          );

                          form.setFieldsValue({
                            price: ''
                          });
                        }
                      });
                    }}
                  />
                </List.Item>
              )
            }
          />
        </Col>
      </div>
      <FloatButton.BackTop />
    </Form>
  );
};

export default Cbm;
