import {
  Col,
  Form,
  List,
  Row,
  // Select,
  Spin,
  Switch,
  Tooltip,
  Typography,
  message
} from 'antd';
import { selectInvoicesByProductId } from 'app/features/products/selectors';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { productEdit } from 'app/features/products/slice';
import { handleBackendValidationError } from 'helpers/errors';
import {
  selectBusinessAvailabilityList,
  selectBusinessCount,
  selectBusinessData,
  selectBusinessLimit,
  selectBusinessLoading,
  selectBusinessPage
} from 'app/features/businesses/selectors';
import {
  businessApi,
  getAvailabilityListByProdId,
  setLimit,
  setPage
} from 'app/features/businesses/slice';
import { getPricesByProductId } from 'app/features/productCountries/slice';

import { IBusinessAccess } from './types';
import { EditStates } from '../Logistics/types';

const validateMessages = {
  required: "'${label}' is Required!"
};

const BusinessAccess: FC<IBusinessAccess> = ({
  productById,
  form,
  attributes
}) => {
  const [isChecked, setIsChecked] = useState<EditStates>({});

  const busineses = useAppSelector(selectBusinessData());
  const availabilityData = useAppSelector(selectBusinessAvailabilityList());
  const page = useAppSelector(selectBusinessPage());
  const count = useAppSelector(selectBusinessCount());
  const limit = useAppSelector(selectBusinessLimit());
  const productContractFiles = useAppSelector(selectInvoicesByProductId());
  const loading = useAppSelector(selectBusinessLoading());

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const { Text } = Typography;
  const productId = Number(id);
  const availabilityList = availabilityData || [];

  // const onClose = () => {
  //   form.resetFields();
  //   navigate('/products');
  // };

  useEffect(() => {
    dispatch(businessApi());

    if (productId) {
      dispatch(getPricesByProductId(productId));
      dispatch(getAvailabilityListByProdId(productId));
    }
  }, [dispatch, page, limit, productId]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  useEffect(() => {
    if (id && productById && busineses) {
      const businessAccesses = productById.business_access;
      busineses.map(item => {
        if (item.access_id) {
          setIsChecked(prevStates => ({
            ...prevStates,
            [item.access_id]: businessAccesses.includes(item.access_id)
          }));
        }
      });
    }
  }, [busineses, form, id, productById]);

  const onFinish = async () => {
    const businessAccess = Object.keys(isChecked).filter(
      accessId => isChecked[accessId] === true
    );

    // const images = productById?.files;
    const files = productContractFiles;

    const combinedFiles = [
      // ...(images || []).filter(Boolean),
      ...(files || []).filter(Boolean)
    ];

    const attributesPost = attributes.map(attribute => ({
      attribute_id: attribute.attributeId,
      type: attribute.value?.type,
      ...(attribute.value?.type === 'enum'
        ? { enum_value: attribute.value?.enumValue }
        : {
            min: Number(attribute.value?.numberRange?.min),
            max: Number(attribute.value?.numberRange?.max)
          })
    }));

    const dataProduct = {
      categories: productById?.categories?.map(item => Number(item.id)),
      files: combinedFiles.map(file => file.id) || [],
      attributes: attributesPost,
      business_access: businessAccess
    };

    if (productById) {
      const res = await dispatch(
        productEdit({
          productId,
          dataProduct: {
            ...dataProduct
          }
        })
      );

      if (res.meta.requestStatus !== 'fulfilled') {
        handleBackendValidationError(
          res?.payload?.response?.data?.message,
          res
        );
      }
    }
  };

  return (
    <Form
      validateMessages={validateMessages}
      size="large"
      form={form}
      name="formBusinessAccess"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError={{
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      }}
    >
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <Row
            style={{
              height: '90%',
              overflow: 'auto',
              padding: '0 16px',
              border: '1px solid rgba(140, 140, 140, 0.35)'
            }}
          >
            {/* {!productCountriesData?.length && (
              <Col span={12} offset={7}>
                <Text strong type="danger">
                  There is no any price filled in for the Partner’s operation
                  country!
                </Text>
              </Col>
            )} */}
            <Col span={12} offset={6}>
              <List
                // loading={cbmCountryLoading}
                dataSource={busineses}
                pagination={{
                  align: 'center',
                  style: { margin: 8 },
                  current: page,
                  onChange: (newPage, newPageSize) => {
                    dispatch(setPage(newPage));
                    dispatch(setLimit(newPageSize));
                  },
                  pageSize: limit,
                  total: count
                }}
                renderItem={item =>
                  item && (
                    <Tooltip title={`Status: ${item.status}`}>
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          title={
                            <Text
                              style={{
                                color:
                                  item.status === 'SUSPENDED'
                                    ? 'grey'
                                    : 'inherit'
                              }}
                              strong
                            >
                              {item.name}
                            </Text>
                          }
                        />
                        <Tooltip
                          title={
                            !availabilityList[item.id] &&
                            `There is no any price filled in for the partner’s operation country!`
                          }
                        >
                          <Form.Item
                            name={['accessSelect', item.id]}
                            style={{ margin: 0 }}
                          >
                            <Switch
                              checked={isChecked[item.access_id]}
                              disabled={
                                item.status === 'SUSPENDED' ||
                                !availabilityList[item.id]
                              }
                              style={{
                                borderColor: !availabilityList[item.id]
                                  ? 'red'
                                  : 'inherit',
                                boxShadow: !availabilityList[item.id]
                                  ? '0 0 0 2px red'
                                  : 'inherit'
                              }}
                              onChange={() => {
                                if (item.access_id) {
                                  setIsChecked(prevStates => ({
                                    ...prevStates,
                                    [item.access_id]:
                                      !prevStates[item.access_id]
                                  }));
                                } else {
                                  message.error('No access id provided');
                                  setIsChecked(prevStates => ({
                                    ...prevStates,
                                    ['null']: false
                                  }));
                                }
                              }}
                            />
                          </Form.Item>
                        </Tooltip>
                      </List.Item>
                    </Tooltip>
                  )
                }
              />
              {/* </Form.Item> */}
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default BusinessAccess;
