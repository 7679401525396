import { Button, Table } from 'antd';
import {
  setLimit,
  setPage,
  setSearch,
  accessoriesCategoriesApi,
  deleteAccessoriesCategory,
  getAccessoriesCategoryById
} from 'app/features/accessoriesCategories/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, DiffTwoTone, EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { AccessoryCategory } from 'app/features/accessories/types';
import {
  selectAccessoriesCategoriesCount,
  selectAccessoriesCategoriesData,
  selectAccessoriesCategoriesLoading,
  selectAccessoriesCategoriesLoadingAction,
  selectAccessoryCategoriesLimit,
  selectAccessoryCategoriesPage,
  selectAccessoryCategoriesSearch
} from 'app/features/accessoriesCategories/selectors';

import ContentTop from 'components/ContentTop';
import { ButtonsWrapper } from './styled';
import DeleteModal from 'components/DeleteModal';
import AccessoryCategoryModal from 'pages/Accessories/components/AccessoryCategoryModal';

const debounce = _debounce();

const Accessories = () => {
  //state
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [accessoryCategoryId, setAccessoryCategoryId] = useState<number>(0);
  const [showAccessoryCategoryModal, setShowAccessoryCategoryModal] =
    useState(false);

  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const accessoriesCategoryData = useAppSelector(
    selectAccessoriesCategoriesData()
  );

  const loading = useAppSelector(selectAccessoriesCategoriesLoading());
  const page = useAppSelector(selectAccessoryCategoriesPage());
  const limit = useAppSelector(selectAccessoryCategoriesLimit());
  const count = useAppSelector(selectAccessoriesCategoriesCount());
  const search = useAppSelector(selectAccessoryCategoriesSearch());
  const loadingAction = useAppSelector(
    selectAccessoriesCategoriesLoadingAction()
  );

  useEffect(() => {
    debounce(() => dispatch(accessoriesCategoriesApi()));
  }, [dispatch, search, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const onEditAccessoryCategory = (id: number) => {
    dispatch(getAccessoriesCategoryById(id));
    setAccessoryCategoryId(id);
    setShowAccessoryCategoryModal(true);
    setEditMode(true);
  };

  const onAddAccessoryCategory = () => {
    setShowAccessoryCategoryModal(true);
    setEditMode(false);
  };

  const handleDeleteAccessoriesCategory = async () => {
    await dispatch(deleteAccessoriesCategory(accessoryCategoryId));
    setOpenDeleteModal(false);
    setAccessoryCategoryId(0);
  };

  const onClose = () => {
    setEditMode(false);
    setAccessoryCategoryId(0);
    setShowAccessoryCategoryModal(false);
  };

  const columns: ColumnsType<AccessoryCategory> = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      onCell: record => ({
        onClick: () => {
          navigate(`details/${record.id}`);
        },
        style: { cursor: 'pointer' }
      })
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Button
              onClick={() => {
                navigate(`details/${record.id}`);
              }}
              icon={<DiffTwoTone />}
            >
              Edit Accessories
            </Button>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                onEditAccessoryCategory(record.id);
              }}
            >
              Rename Category
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setAccessoryCategoryId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
            {/* <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}>Edit</Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setAccessoryCategoryId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button> */}
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Accessories Categories"
        placeholder="Search Accessories"
        buttonText="Add Accessories Category"
        onClickAdd={onAddAccessoryCategory}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={accessoriesCategoryData}
        loading={loading}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
      <AccessoryCategoryModal
        isOpen={showAccessoryCategoryModal}
        onClose={onClose}
        params={{
          accessoryCategoryId,
          editMode
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={handleDeleteAccessoriesCategory}
        onCancel={() => {
          setOpenDeleteModal(false);
          setAccessoryCategoryId(0);
        }}
      />
    </>
  );
};

export default Accessories;
