import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { Modal, message } from 'antd';

import {
  CalculatePrices,
  ProductCountryPrices,
  ProductCountryState
} from './types';
import { getAvailabilityListByProdId } from '../businesses/slice';

const initialState: ProductCountryState = {
  productCountryPrices: null,
  calculatedPrices: null,
  loadingAction: false,
  loadingById: false,
  loading: false
};

export const getPricesByProductId = createAsyncThunk(
  'product/pricesByProductId',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`product/${productId}/country`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const createProductCountry = createAsyncThunk(
  'productCountries/createProductCountries',
  async (
    {
      productId,
      productCountry,
      transportType
    }: {
      productId: number;
      productCountry: ProductCountryPrices;
      transportType: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await client.post(
        `product/${productId}/country/${transportType}`,
        {
          ...productCountry
        }
      );

      if (response.status === 200) {
        message.success({
          content: 'Product Country created Successfully!'
        });

        await dispatch(getPricesByProductId(productId));
        await dispatch(getAvailabilityListByProdId(productId));
      }

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const updateProductCountry = createAsyncThunk(
  'productCountries/updateProductCountries',
  async (
    {
      itemId,
      productId,
      productCountry,
      transportType
    }: {
      itemId: number;
      productId: number;
      productCountry: ProductCountryPrices;
      transportType: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, country_id, ...updateData } = productCountry;

    try {
      const response = await client.put(
        `/product/country/${itemId}/${transportType}`,
        {
          ...updateData
        }
      );

      if (response.status === 200) {
        message.success({
          content: 'Product Country Updated Successfully!'
        });

        await dispatch(getPricesByProductId(productId));
        await dispatch(getAvailabilityListByProdId(productId));

        return response.data.payload;
      }
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteProductCountry = createAsyncThunk(
  'productCountries/deleteProductCountry',
  async (
    {
      itemId,
      productId,
      type = ''
    }: { itemId: number; productId: number; type?: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await client.delete(`/product/country/${itemId}${type}`);
      await dispatch(getPricesByProductId(productId));
      Modal.success({
        content: 'Country Price Removed Successfully!'
      });

      return res.data.payload;
    } catch (error) {
      rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const calculatePrices = createAsyncThunk(
  'productCountries/calculatePrices',
  async (
    {
      productId,
      priceToCalculate
    }: {
      productId: number;
      priceToCalculate: CalculatePrices;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post(`/product/${productId}/country/calc`, {
        ...priceToCalculate
      });

      if (response.status === 200) {
        const payload = response.data.payload;

        if (typeof payload === 'number') {
          message.success({
            content: `Prices calculated Successfully! Result: ${payload}`
          });
        } else if (typeof payload === 'object' && payload !== null) {
          message.success({
            content: `Prices calculated Successfully! Result: Express: ${payload?.express}, Subtotal: ${payload?.subtotal}`
          });
        } else {
          message.warning({
            content: 'Unexpected payload format received.'
          });
        }
      }

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const productCountryPricesSlice = createSlice({
  name: 'productCountryPrices',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPricesByProductId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getPricesByProductId.fulfilled, (state, action) => {
        state.productCountryPrices = action.payload;
        state.loadingById = false;
      })
      .addCase(getPricesByProductId.rejected, state => {
        state.loadingById = false;
      })
      .addCase(createProductCountry.pending, state => {
        state.loadingAction = true;
      })
      .addCase(createProductCountry.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(createProductCountry.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updateProductCountry.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateProductCountry.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateProductCountry.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteProductCountry.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteProductCountry.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteProductCountry.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(calculatePrices.pending, state => {
        state.loadingAction = true;
      })
      .addCase(calculatePrices.fulfilled, (state, action) => {
        state.calculatedPrices = action.payload;
        state.loadingAction = false;
      })
      .addCase(calculatePrices.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export default productCountryPricesSlice.reducer;
