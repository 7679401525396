export const routePath = {
  getMain: () => '/',
  getCategories: () => '/categories',
  getUsers: () => '/users',
  getCbn: () => '/cbm',
  getProducts: (path?: string) => `/products${path ? `/${path}` : ''}`,
  getDetails: (path?: string) => `details${path ? `/${path}` : ''}`,
  getAttributes: (path?: string) => `/attributes${path ? `/${path}` : ''}`,
  getVendors: (path?: string) => `/vendors${path ? `/${path}` : ''}`,
  getAccessories: (path?: string) => `/accessories${path ? `/${path}` : ''}`,
  getBusinesses: (path?: string) => `/businesses${path ? `/${path}` : ''}`
};
