import { IAttributeByCategoryId } from 'app/features/category/types';

import { AttributeState, AttributeStateItem } from 'pages/ProductDetails/types';
import { IAttributesSelect } from './types';

export const DefaultValue = (selected: AttributeStateItem | undefined) => {
  if (selected && selected.value?.type === 'enum') {
    return [selected.value.enumValue || ''];
  } else if (selected && selected.value?.type === 'number') {
    return [
      selected?.value.numberRange?.min || '',
      selected?.value.numberRange?.max || ''
    ];
  }

  return [''];
};

export const attributeDisabled = (
  arr: AttributeState,
  id: number | undefined
) => {
  if (id === undefined) {
    return false;
  }

  const itemIdArr = arr.map(item => {
    return item.attributeId;
  });

  if (itemIdArr) {
    return itemIdArr.includes(id);
  }
};

export const attrData = (attributesData: IAttributeByCategoryId[]) => {
  const attrItem: (IAttributesSelect | undefined)[] = attributesData;

  const attrReduce: IAttributesSelect[] = attrItem?.reduce((arr, item) => {
    if (item && !arr.some(elem => elem.id === item.id)) {
      arr.push(item);
    }

    return arr;
  }, [] as IAttributesSelect[]);

  return attrReduce;
};

export const separateTextAndNumber = (str: string) => {
  const lastIndex = str.lastIndexOf('-');
  const text = str.substring(0, lastIndex + 1);
  const number = str.substring(lastIndex + 1);

  return { text, number };
};
