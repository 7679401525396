import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';

import {
  selectVendorsLimit,
  selectVendorsPage,
  selectVendorsSearch
} from './selectors';
import { IVendor, IVendorPost, VendorState } from './types';

const initialState: VendorState = {
  loading: false,
  loadingAction: false,
  loadingById: false,
  search: '',
  page: 1,
  limit: 10,
  count: 0,
  vendor: [],
  optowire_price: '',
  vendorById: null
};

export const vendorApi = createAsyncThunk(
  'vendor/vendorApi',
  async (payload: { limit?: number } = {}, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const search = selectVendorsSearch()(state);
    const page = selectVendorsPage()(state);
    const limit = selectVendorsLimit()(state);
    const body = '';
    // const body = { is_visible: true };
    const params = {
      page,
      search,
      limit: payload.limit || limit
    };

    try {
      const res = await client.post(`/vendor/explore`, body, { params });

      return res.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getVendorById = createAsyncThunk(
  'vendor/getVendorById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await client.get(`/vendor/get/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteVendor = createAsyncThunk(
  'vendor/deleteVendor',
  async (
    { id, name }: { id: number | string; name: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.delete(`/vendor/delete/${id}`);
      message.success({
        content: ` Vendor ${name} deleted successfully`
      });

      await dispatch(vendorApi({}));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postVendor = createAsyncThunk(
  'vendor/postVendor',
  async ({ ...data }: IVendorPost, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.post('/vendor/create', {
        ...data
      });

      await dispatch(vendorApi({}));

      return response.data;
    } catch (err: any) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));

      return err.response;
    }
  }
);

export const vendorEdit = createAsyncThunk(
  'vendor/vendorEdit',
  async ({ id, ...data }: IVendor, { dispatch, rejectWithValue }) => {
    try {
      const res = await client.put(`/vendor/update/${id}`, {
        ...data
      });

      await dispatch(vendorApi({}));

      return res.data;
    } catch (err: any) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));

      return err?.response;
    }
  }
);

export const getOptowirePriceByProductId = createAsyncThunk(
  'product/optowirePriceByProductId',
  async (productId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`product/${productId}/optowire-price`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);
const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    clearVendorData: state => {
      state.vendorById = null;
      state.loadingById = false;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(vendorApi.pending, state => {
        state.loading = true;
      })
      .addCase(vendorApi.fulfilled, (state, action) => {
        state.vendor = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(vendorApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getVendorById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getVendorById.fulfilled, (state, action) => {
        state.vendorById = action.payload;
        state.loadingById = false;
      })
      .addCase(getVendorById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(postVendor.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postVendor.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postVendor.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(vendorEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(vendorEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(vendorEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteVendor.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteVendor.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteVendor.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getOptowirePriceByProductId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getOptowirePriceByProductId.fulfilled, (state, action) => {
        state.optowire_price = action.payload.optowire_price;
        state.loadingById = false;
      })
      .addCase(getOptowirePriceByProductId.rejected, state => {
        state.loadingById = false;
      });
  }
});

export const { setPage, clearVendorData, setSearch, setLimit } =
  vendorSlice.actions;
export default vendorSlice.reducer;
