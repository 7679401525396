import { Modal, message } from 'antd';
import { nextStep, prevStep, prevStepBulk } from 'app/features/products/slice';
import { useAppDispatch } from 'app/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { StepsProps } from './types';

export const useSteps = ({
  isPresales,
  isAdmin,
  isLogistics,
  productById
}: StepsProps) => {
  const [currentStep, setCurrentStep] = useState(-1);

  const { id } = useParams();
  const dispatch = useAppDispatch();

  const productId = Number(id);

  const memoizedFilteredSteps = useMemo(() => {
    const steps = [
      { title: 'Rejected', key: 'rejected' },
      { title: 'Presales', key: 'presales' },
      { title: 'Content Writer', key: 'admin' },
      { title: 'Logistics', key: 'logistics' },
      { title: 'Publish/Sales', key: 'sales' },
      { title: 'Discontinued', key: 'discontinued' }
    ];

    const filteredStepsCopy = [...steps];

    return filteredStepsCopy;
  }, [isPresales, isAdmin, isLogistics]);

  useEffect(() => {
    if (productById) {
      const productProcess = productById.process
        .toLowerCase()
        .replaceAll(/_/g, '')
        .replaceAll('process', '')
        .trim();

      const stepIndex = memoizedFilteredSteps.findIndex(
        step => step.key === productProcess
      );

      setCurrentStep(stepIndex);
    } else {
      setCurrentStep(1);
    }
  }, [memoizedFilteredSteps, productById]);

  const navigateToStep = async (value: number) => {
    if (value !== currentStep) {
      try {
        let res;

        const stepDifference = value - currentStep;

        if (stepDifference > 0) {
          res = await dispatch(nextStep(Number(id)));
        } else if (stepDifference < 0) {
          res = await dispatch(prevStepBulk({ productId, stepDifference }));

          if (res?.meta?.requestStatus === 'fulfilled' && res?.payload) {
            setCurrentStep(value);
          } else {
            message.error({
              content: `Failed to update step.`
            });
          }
        }
      } catch (error) {
        console.error('An error occurred while updating the step:', error);
        throw error;
      }
    }
  };

  const onStepChange = (value: number) => {
    if (value === 0) {
      Modal.confirm({
        title: 'Confirm',
        content: `Are you sure you want to set step to ${memoizedFilteredSteps[value].title} step?`,
        onOk: async () => {
          try {
            if (id) {
              await navigateToStep(value);
            } else {
              Modal.error({
                content: "You haven't created the product yet!"
              });
            }
          } catch (error) {
            Modal.error({
              title: 'Error',
              content:
                'An error occurred while updating the step. Please try again later.'
            });
          }
        }
      });
    } else {
      const allowedStepIndices = [currentStep - 1, currentStep + 1];

      if (allowedStepIndices.includes(value)) {
        Modal.confirm({
          title: 'Confirm',
          content: `Are you sure you want to set step to ${memoizedFilteredSteps[value].title} step?`,
          onOk: async () => {
            try {
              if (id) {
                await navigateToStep(value);
              } else {
                Modal.error({
                  content: "You haven't created the product yet!"
                });
              }
            } catch (error) {
              Modal.error({
                title: 'Error',
                content:
                  'An error occurred while updating the step. Please try again later.'
              });
            }
          }
        });
      } else {
        Modal.error({
          content: 'Sorry, you can go one step at a time!'
        });
      }
    }
  };

  const handleReset = async () => {
    try {
      await dispatch(prevStep({ productId: Number(id), reset: false }));
      await dispatch(nextStep(Number(id)));
    } catch (error) {
      message.error('An error occurred while resetting.');
      console.error('An error occurred while resetting:', error);
    }
  };

  return {
    onStepChange,
    currentStep,
    handleReset,
    memoizedFilteredSteps
  };
};
