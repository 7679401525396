import {
  Button,
  Form,
  Modal,
  Space,
  Steps,
  Tabs,
  Tag,
  Tooltip,
  UploadProps,
  message
} from 'antd';
import {
  selectProductById,
  selectProductLoadingAction,
  selectProductLoadingById
} from 'app/features/products/selectors';
import {
  getInvoicesByProductId,
  getProductById
} from 'app/features/products/slice';
import {
  selectPicturesFiles,
  selectInvoiceFiles
} from 'app/features/files/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  categoryApi,
  getAttributesByCategoryIds
} from 'app/features/category/slice';
import { UploadFile } from 'antd/es/upload';
import { vendorApi } from 'app/features/vendor/slice';
import {
  fetchInvoiceFilesApi,
  fetchPicturesFilesApi
} from 'app/features/files/slice';
import { selectUserRole } from 'app/features/users/selectors';
import { getFileUrl, getFilename } from 'helpers/utils';
import {
  attributeApi,
  getAttributesByProductId
} from 'app/features/attributes/slice';
import { handleValidationError } from 'helpers/errors';
import { selectAttributesByProductId } from 'app/features/attributes/selectors';
import { InfoCircleOutlined } from '@ant-design/icons';

import { AttributeState, FormDictionary } from './types';
import Loading from 'components/Loading';
import {
  HeaderDivider,
  LeftPage,
  PageHeader,
  PageTitle,
  ProductTitle
} from 'components/DetailsHead/styled';
import Presales from './components/Presales';
import Admin from './components/Admin';
import VendorForm from './components/VendorForm';
import FilesAndPhotos from './components/FilesAndPhotos';
import Logistics from './components/Logistics';
import ProductAccessories from './components/ProductAccessories';
import BusinessAccess from './components/BusinessAccess';
import { FalseIcon, IconWrapper, TrueIcon } from 'pages/Products/styled';
import { useSteps } from './steps';
import { confirmationMessages } from './constants';
import './steps/style.css';

const ProductDetails = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [bodyDescription, setBodyDescription] = useState('');
  const [bodyFeatures, setBodyFeatures] = useState('');
  const [contractFileList, setContractFileList] = useState<UploadFile[]>([]);
  const [isTabDisabled, setIsTabDisabled] = useState<boolean>(true);
  const [color, setColor] = useState('');
  const [attributes, setAttributes] = useState<AttributeState>([]);
  const [article, setArticle] = useState('');
  const [activeTabKey, setActiveTabKey] = useState<string>();
  const [isBlockerOpen, setIsBlockerOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { id } = useParams();
  const productId = Number(id);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const productById = useAppSelector(selectProductById());
  const loadingById = useAppSelector(selectProductLoadingById());
  const loadingAction = useAppSelector(selectProductLoadingAction());
  const imagesData = useAppSelector(selectPicturesFiles);
  const filesData = useAppSelector(selectInvoiceFiles);
  const userRole = useAppSelector(selectUserRole);
  const productAttributes = useAppSelector(selectAttributesByProductId());

  const isSuper = userRole?.role === 'SUPER_ADMIN';
  const isPresales = userRole?.role === 'PRE_SALES';
  const isAdmin = userRole?.role === 'ADMIN';
  const isLogistics = userRole?.role === 'LOGISTICS';
  const isMega = userRole?.role === 'MEGA_ADMIN';

  const [formPresales] = Form.useForm();
  const [formVendor] = Form.useForm();
  const [formFilesAndImages] = Form.useForm();
  const [formAdmin] = Form.useForm();
  const [formLogistics] = Form.useForm();
  const [formAccessories] = Form.useForm();
  const [formBusinessAccess] = Form.useForm();

  const formDictionary: FormDictionary = {
    presales: formPresales,
    vendorForm: formVendor,
    filesAndPhotos: formFilesAndImages,
    admin: formAdmin,
    logistics: formLogistics,
    businessAccess: formBusinessAccess
  };

  const activeForm =
    formDictionary[activeTabKey as keyof typeof formDictionary];

  const categoryIds = productById?.categories?.map(item => item?.id) || [];

  const { onStepChange, currentStep, handleReset, memoizedFilteredSteps } =
    useSteps({
      isPresales,
      isAdmin,
      isLogistics,
      productById
    });

  const onChangeImageUpload: UploadProps['onChange'] = ({ fileList }) => {
    setFileList(fileList);
  };

  const onChangeFileUpload: UploadProps['onChange'] = ({ fileList }) => {
    fileList.map(file => {
      if (file.response) {
        file.url = getFileUrl(
          file.response.data.payload.map((item: { path: string }) => item.path)
        );
      }

      return file;
    });
  };

  const handleBlockerOk = () => {
    setIsBlockerOpen(false);
    activeForm.resetFields();
    navigate('/products');
  };

  const handleBlockerCancel = () => {
    setIsBlockerOpen(false);
  };

  useEffect(() => {
    Promise.all([
      dispatch(fetchPicturesFilesApi({})),
      dispatch(fetchInvoiceFilesApi()),
      dispatch(categoryApi()),
      dispatch(attributeApi(false))
    ]);

    if (productId) {
      dispatch(getProductById(productId));
      dispatch(getInvoicesByProductId(productId));
      dispatch(getAttributesByProductId(productId));
    }

    dispatch(vendorApi({ limit: 50 }));

    const initialVisibleTab = visibleTabs[0]?.key;

    if (initialVisibleTab) {
      setActiveTabKey(initialVisibleTab);
    }
  }, [dispatch, productId, isMega, isPresales]);

  const onClose = () => {
    const isSubmitted = formLogistics.getFieldsValue().submitted;
    const isFormTouched = formLogistics.getFieldsValue().isFormTouched;
    const touchedNotSubmitted = isFormTouched && !isSubmitted;

    if (touchedNotSubmitted) setIsBlockerOpen(true);
  };

  const OnBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (productById && categoryIds.length) {
      dispatch(getAttributesByCategoryIds(categoryIds));
    }
  }, [productById]);

  useEffect(() => {
    setContractFileList(
      filesData?.map(item => {
        const url = getFileUrl(item.path);

        return {
          uid: item.id,
          name: `${item.name}.${item.extension}`,
          status: 'done',
          url
        };
      }) || []
    );

    if (id && productById) {
      setFileList(
        productById.files.map(item => {
          const url = getFileUrl(item.path);

          return {
            uid: item.id,
            name: getFilename(item.path),
            status: 'done',
            url
          };
        }) || []
      );
      setAttributes(
        productAttributes?.map(item => ({
          attribute: item,
          attributeId: item.id,
          value:
            item.type === 'enum'
              ? {
                  type: 'enum',
                  enumValue:
                    item.products[0].product_attributes.enum_value || null || ''
                }
              : {
                  type: 'number',
                  numberRange: {
                    min: item.products[0].product_attributes.min || null,
                    max: item.products[0].product_attributes.max || null
                  }
                }
        }))
      );
      setBodyDescription(String(productById.description1_en ?? ''));
      setBodyFeatures(String(productById.html ?? ''));
    }
  }, [dispatch, productById, id, imagesData, filesData, productAttributes]);

  const submitHandler = async () => {
    const isFormChanged = activeForm.isFieldsTouched();

    if (!activeForm && !isFormChanged)
      return message.error('nothing to submit');

    try {
      const isFilesOrSuperPresales =
        activeForm === formFilesAndImages ||
        (activeForm === formPresales && isSuper);

      const confirmModalContent = isFilesOrSuperPresales
        ? confirmationMessages.SUBMIT_CLOSE_TAB
        : confirmationMessages.SUBMIT_NEXT_TAB;

      if (productId) {
        activeForm.submit();
      } else {
        if (activeTabKey === 'presales' || activeTabKey === 'vendorForm') {
          activeForm?.submit();
        } else
          Modal.confirm({
            title: 'Confirm',
            content: confirmModalContent,

            onOk: () => {
              activeForm?.submit();
            }
          });
      }
    } catch (error) {
      handleValidationError(error);
    }
  };

  const tabsData = [
    {
      key: 'presales',
      label: 'Presales',
      visible: true,
      content: (
        <Presales
          onClose={onClose}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          isTabDisabled={isTabDisabled}
          setIsTabDisabled={setIsTabDisabled}
          setActiveTabKey={setActiveTabKey}
          productById={productById}
          productId={productId}
          attributes={attributes}
          color={color}
          setColor={setColor}
          setArticle={setArticle}
          article={article}
          form={formPresales}
          bodyFeatures={bodyFeatures}
          setBodyFeatures={setBodyFeatures}
          bodyDescription={bodyDescription}
          setBodyDescription={setBodyDescription}
        />
      )
    },
    {
      key: 'vendorForm',
      label: 'Vendor Form',
      disabled: !id && isTabDisabled,
      visible: isPresales || isMega,
      content: (
        <VendorForm
          onClose={onClose}
          productById={productById}
          productId={productId}
          contractFileList={contractFileList}
          setContractFileList={setContractFileList}
          onChangeFileUpload={onChangeFileUpload}
          form={formVendor}
          setActiveTabKey={setActiveTabKey}
        />
      )
    },
    {
      key: 'filesAndPhotos',
      label: 'Contracts and Photos',
      disabled: !id && isTabDisabled,
      visible: true,
      content: (
        <FilesAndPhotos
          onClose={onClose}
          productById={productById}
          productId={productId}
          contractFileList={contractFileList}
          setContractFileList={setContractFileList}
          form={formFilesAndImages}
          onChangeImageUpload={onChangeImageUpload}
          onChangeFileUpload={onChangeFileUpload}
          fileList={fileList}
          setFileList={setFileList}
          attributes={attributes}
        />
      )
    },
    {
      key: 'admin',
      label: 'Content Writer',
      disabled: !id,
      visible: true,
      content: (
        <Admin
          form={formAdmin}
          setIsChecked={setIsChecked}
          productById={productById}
          contractFileList={contractFileList}
          onChangeImageUpload={onChangeImageUpload}
          onChangeFileUpload={onChangeFileUpload}
          fileList={fileList}
          setAttributes={setAttributes}
          attributes={attributes}
          bodyFeatures={bodyFeatures}
          setBodyFeatures={setBodyFeatures}
          bodyDescription={bodyDescription}
          setBodyDescription={setBodyDescription}
          setArticle={setArticle}
          article={article}
          setFileList={setFileList}
        />
      )
    },
    {
      key: 'logistics',
      label: 'Logistics',
      disabled: !id,
      visible: true,
      content: (
        <Logistics
          form={formLogistics}
          productById={productById}
          contractFileList={contractFileList}
          fileList={fileList}
          setAttributes={setAttributes}
          attributes={attributes}
          bodyFeatures={bodyFeatures}
          setBodyFeatures={setBodyFeatures}
          bodyDescription={bodyDescription}
          setBodyDescription={setBodyDescription}
          setArticle={setArticle}
          article={article}
          productId={productId}
        />
      )
    },
    {
      key: 'accessories',
      label: 'Accessories',
      visible: true,
      disabled: !id && isTabDisabled,
      content: <ProductAccessories form={formAccessories} />
    },
    {
      key: 'businessAccess',
      label: (
        <>
          Business Access{' '}
          <Tooltip title="At least one price should be available for the partner's country.">
            <InfoCircleOutlined
              style={{ cursor: 'pointer', color: '#ff4218' }}
            />
          </Tooltip>
        </>
      ),
      visible: true,
      content: (
        <BusinessAccess
          form={formBusinessAccess}
          productById={productById}
          attributes={attributes}
        />
      )
    }
  ];

  const visibleTabs = tabsData.filter(tab => tab.visible);

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <PageHeader
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Space>
              <LeftPage onClick={OnBack} />
              <PageTitle level={3}>
                {id ? `Edit Product` : `Add Product`}
              </PageTitle>
              {id && (
                <>
                  <Tag color="green">
                    <ProductTitle strong>{productById?.title_en}</ProductTitle>
                  </Tag>
                  <Tooltip
                    title={
                      isChecked
                        ? 'Product is available'
                        : 'Product is not available'
                    }
                  >
                    {isChecked ? (
                      <IconWrapper
                        icon={
                          <TrueIcon
                            style={{
                              color: isChecked ? 'green' : 'red'
                            }}
                          />
                        }
                        color={isChecked ? 'green' : 'red'}
                      />
                    ) : (
                      <IconWrapper
                        icon={
                          <FalseIcon
                            style={{
                              color: isChecked ? 'green' : 'red'
                            }}
                          />
                        }
                        color={isChecked ? 'green' : 'red'}
                      />
                    )}
                  </Tooltip>
                </>
              )}
            </Space>
            <Space direction="vertical" align="center" size="middle">
              <div className="my-custom-steps">
                <Steps
                  progressDot
                  size="small"
                  current={currentStep}
                  onChange={onStepChange}
                  direction="horizontal"
                  items={memoizedFilteredSteps}
                />
              </div>
            </Space>

            <Button
              style={{ width: '150px' }}
              size="large"
              loading={loadingAction}
              disabled={activeTabKey === 'accessories'}
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (currentStep >= 4) {
                  handleReset();
                  submitHandler();
                } else submitHandler();
              }}
            >
              {productId
                ? 'Submit'
                : activeTabKey === 'presales' || activeTabKey === 'vendorForm'
                ? 'Next'
                : 'Save'}
            </Button>
          </PageHeader>
          <HeaderDivider />

          <Tabs
            activeKey={activeTabKey}
            onChange={key => setActiveTabKey(key)}
            defaultActiveKey={visibleTabs[0]?.key}
            items={visibleTabs.map(tab => ({
              key: tab.key,
              label: tab.label,
              disabled: tab.disabled,
              children: tab.content
            }))}
          />
          <Modal
            title="You have unsaved changes. The changes will be discarded if you leave without saving. Do you want to save them ?"
            open={isBlockerOpen}
            onOk={handleBlockerOk}
            onCancel={handleBlockerCancel}
            okText={'Leave and discard changes'}
            closable={false}
            cancelText={'Stay to save changes'}
            okButtonProps={{ type: 'default' }}
            cancelButtonProps={{ type: 'primary' }}
          ></Modal>
        </>
      )}
    </>
  );
};

export default ProductDetails;
