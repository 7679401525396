import { Modal, Button, Input, Form, Row, Col, Select } from 'antd';
import { userEdit, postUser } from 'app/features/users/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectUserLoadingAction,
  selectUserById
} from 'app/features/users/selectors';
import { FC, memo, useEffect } from 'react';
import { IUsersPost } from 'app/features/users/types';

import { IUserModalProps } from './types';

const UserModal: FC<IUserModalProps> = ({ isOpen, params, onClose }) => {
  // form
  const [form] = Form.useForm();
  const { Option } = Select;
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const loadingAction = useAppSelector(selectUserLoadingAction());
  const userById = useAppSelector(selectUserById());

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const modalTitle = () => {
    if (!params.userId) {
      return 'Add User';
    }

    return 'Edit User';
  };

  useEffect(() => {
    if (userById) {
      const originalDate = userById.birth_year;
      const formattedDate = originalDate.split('T')[0];

      form.setFieldsValue({
        first_name: userById.first_name,
        last_name: userById.last_name,
        username: userById.username,
        password: userById.password,
        role: userById.role,
        phone: userById.phone,
        address: userById.address,
        birth_year: formattedDate,
        gender: userById.gender,
        email: userById.email
      });
    }
  }, [userById, form]);

  const onSave = async (values: IUsersPost) => {
    const {
      first_name,
      last_name,
      username,
      password,
      role,
      phone,
      address,
      birth_year,
      gender,
      email
    } = values;

    const data = {
      first_name,
      last_name,
      username,
      password,
      role,
      phone,
      address,
      birth_year,
      gender,
      email
    };

    if (params.userId) {
      await dispatch(
        userEdit({
          ...data,
          id: params.userId
        })
      ).then(() => handleCloseModal());
    } else {
      await dispatch(postUser({ ...data }));
    }
  };

  return (
    <Modal
      title={modalTitle()}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button
          type="primary"
          onClick={form.submit}
          loading={loadingAction}
          key="Save"
        >
          Save
        </Button>
      ]}
      style={{ top: 20 }}
      width="60%"
    >
      <Form
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        form={form}
        onFinish={onSave}
        layout="vertical"
        size="large"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter Your First Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter Your Last Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter Your Username" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true }]}
            >
              <Input.Password placeholder="Enter Your Password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Role" name="role" rules={[{ required: true }]}>
              <Select placeholder="Select Your Role">
                <Option value="PRE_SALES">Pre Sales</Option>
                <Option value="ADMIN">Admin</Option>
                <Option value="LOGISTICS">Logistics</Option>
                <Option value="SALES">Sales</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
              <Input placeholder="Enter Your Phone" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter Your Address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Birthday"
              name="birth_year"
              rules={[{ required: true }]}
            >
              <Input placeholder="YYYY-MM-DD" type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select Your Gender">
                <Option value="MALE">Male</Option>
                <Option value="FEMALE">Female</Option>
                <Option value="UNKNOWN">Unknown</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
              <Input placeholder="Enter Your Email" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(UserModal);
