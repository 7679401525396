import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authSlice from './features/auth/slice';
import usersSlice from './features/users/slice';
import categorySlice from './features/category/slice';
import productsSlice from './features/products/slice';
import vendorFormSlice from './features/vendorForm/slice';
import countrySlice from './features/country/slice';
import vendorSlice from './features/vendor/slice';
import attributeSlice from './features/attributes/slice';
import accessoriesSlice from './features/accessories/slice';
import accessoriesCategorySlice from './features/accessoriesCategories/slice';
import sectionSlice from './features/sections/slice';
import filesSlice from './features/files/slice';
import cbmCountriesSlice from './features/cbmCountries/slice';
import businessesSlice from './features/businesses/slice';
import productCountryPricesSlice from './features/productCountries/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    users: usersSlice,
    category: categorySlice,
    products: productsSlice,
    country: countrySlice,
    vendor: vendorSlice,
    attribute: attributeSlice,
    accessories: accessoriesSlice,
    accessoriesCategories: accessoriesCategorySlice,
    files: filesSlice,
    contractFiles: filesSlice,
    section: sectionSlice,
    vendorForm: vendorFormSlice,
    cbmCountries: cbmCountriesSlice,
    businesses: businessesSlice,
    productCountryPrices: productCountryPricesSlice
  }
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
