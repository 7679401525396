import React, { useEffect } from 'react';
import { Avatar, Form, Input, List, Modal, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getMessagesByProductId } from 'app/features/products/slice';
import { selectMessagesByProductId } from 'app/features/products/selectors';
import { SendOutlined } from '@ant-design/icons';

import { IMessages, IProductMessage } from './types';

interface IProductMessageProps {
  message: string;
  userRole: {
    role?: string | undefined;
    first_name: string;
    last_name: string;
    email: string;
  };
}

const Messages: React.FC<IMessages> = ({
  userRole,
  isVisible,
  onClose,
  productId
}) => {
  const dispatch = useAppDispatch();
  const messagesByProductId = useAppSelector(selectMessagesByProductId());

  const [form] = Form.useForm();
  const ContainerHeight = 400;

  useEffect(() => {
    if (productId && productId !== 0 && isVisible) {
      dispatch(getMessagesByProductId(productId));
    }
  }, [dispatch, isVisible, productId]);

  useEffect(() => {
    if (productId && messagesByProductId.length) {
      // Set initial values for the form from productById.messages
      const initialValues = {
        newMessage: '',
        messages: messagesByProductId.map(message => ({
          message: message.message,
          userRole: {
            role: userRole?.role,
            first_name: message.to_user.first_name,
            last_name: message.to_user.last_name,
            email: message.to_user.email
          }
        }))
      };

      form.setFieldsValue(initialValues);
    }
  }, [form, messagesByProductId, productId, userRole?.role]);

  const handleSendMessage = () => {
    const newMessage = form.getFieldValue('newMessage');

    if (newMessage && newMessage.trim() !== '') {
      const messageObject: IProductMessageProps = {
        message: newMessage,
        userRole: {
          role: userRole?.role,
          first_name: userRole?.first_name ?? '',
          last_name: userRole?.last_name ?? '',
          email: userRole?.email ?? ''
        }
      };

      const currentMessages = form.getFieldValue('messages') || [];
      form.setFieldsValue({
        newMessage: '',
        messages: [...currentMessages, messageObject]
      });
    }
  };

  return (
    <Modal
      title="Messages"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <div>
        <Form form={form}>
          <List
            dataSource={messagesByProductId}
            renderItem={(message: IProductMessage) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar />}
                  title={
                    <Tag>
                      {/* {message.userRole?.role}, {message.userRole?.first_name}{' '} */}
                      From {message.from_user.first_name}{' '}
                      {message.from_user.last_name} to{' '}
                      {message.to_user.first_name} {message.to_user.last_name}
                    </Tag>
                  }
                  description={message.message}
                />
              </List.Item>
            )}
            style={{ height: ContainerHeight }}
          />
          <Form.Item name="newMessage">
            <Input
              type="text"
              placeholder="Enter your message"
              onPressEnter={handleSendMessage}
              suffix={<SendOutlined onClick={handleSendMessage} />}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default Messages;
