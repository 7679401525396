/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UndoOutlined
  // UploadOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  // FloatButton,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Tooltip,
  TreeSelect,
  Upload,
  UploadFile
} from 'antd';
import {
  selectProductLoadingAction,
  selectInvoicesByProductId,
  selectValidatorLoading,
  selectNewProduct,
  selectProductLoading
} from 'app/features/products/selectors';
import { FC, useEffect, useState } from 'react';
import { useCustomRequest } from 'Hook/useCustomRequest';
// import { useParams } from 'react-router-dom';
import {
  filterCategoryDataByValue,
  // flattenCategories,
  hasValue,
  Option
} from 'helpers/product';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectAttributesByCategoryIds,
  selectCategoryData
} from 'app/features/category/selectors';
import { useParams } from 'react-router-dom';
import {
  decimalPrecisionRule,
  isNotZeroRule,
  isValidUrl,
  validateYoutubeUrls
} from 'helpers/validators';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { useValidationLogic } from 'helpers/articleAndCodeValidator';
import { getProductById, productEdit } from 'app/features/products/slice';
import { IProductAdminEditValues } from 'app/features/products/types';
import {
  RemoveDuplicates,
  _debounce,
  getFilename,
  trimExcessZeros
} from 'helpers/utils';
import { fetchInvoiceFilesApi, setSearch } from 'app/features/files/slice';
import { getAttributesByCategoryIds } from 'app/features/category/slice';
import NumericInput, {
  cleanAndParse,
  parseInputValue
} from 'helpers/NumericInput';
import { IAttributeByCategoryId } from 'app/features/attributes/types';
import { selectAttributesByProductId } from 'app/features/attributes/selectors';
import { handleBackendValidationError } from 'helpers/errors';
import { selectPricesByProductId } from 'app/features/productCountries/selectors';
import { getPricesByProductId } from 'app/features/productCountries/slice';
import Uploader from 'Hook/uploadrer';

import { AttributeStateItem } from 'pages/ProductDetails/types';
import { IAdmin } from './types';
import { AttributeSelect } from '..';
import Editor from 'components/Editor';
import { TagRender } from '../TagRender';
import { OtherFormat } from '../AttributeSelect/styled';
import { separateTextAndNumber } from '../AttributeSelect/constant';
import useCategoryData from 'pages/categoryData';

const validateMessages = {
  required: "'${label}' is Required!"
};

const { Dragger } = Upload;

const Admin: FC<IAdmin> = ({
  // showAdd,
  setAttributes,
  onChangeImageUpload,
  onChangeFileUpload,
  setBodyFeatures,
  bodyFeatures,
  setBodyDescription,
  bodyDescription,
  productById,
  contractFileList,
  form,
  fileList,
  attributes,
  setArticle,
  article,
  setIsChecked,
  setFileList
}) => {
  const { validateArticleNumber } = useValidationLogic();
  const categoryData = useAppSelector(selectCategoryData());
  const productContractFiles = useAppSelector(selectInvoicesByProductId());
  const loadingAction = useAppSelector(selectProductLoadingAction());
  const loading = useAppSelector(selectProductLoading());
  const productAttributes = useAppSelector(selectAttributesByProductId());
  const productCountriesData = useAppSelector(selectPricesByProductId());
  const validationLoading = useAppSelector(selectValidatorLoading());

  const newProduct = useAppSelector(selectNewProduct());
  const attributesByCategoryIds = useAppSelector(
    selectAttributesByCategoryIds()
  );

  // states

  const [progress, setProgress] = useState(false);
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);

  const [cbmInputDisabled, setCbmInputDisabled] = useState<boolean>(true);
  const [isOnlyCbm, setIsOnlyCbm] = useState<boolean>(true);
  const [initialArticleNumber, setinitialArticleNumber] = useState('');
  const [initialArticle, setinitialArticle] = useState('');
  const [normalisedCategoryAttributes, setNormalisedCategoryAttributes] =
    useState<IAttributeByCategoryId[] | []>(
      RemoveDuplicates(attributesByCategoryIds || [])
    );

  const [categoriesFilled, setCategoriesFilled] = useState<boolean>(
    !!attributesByCategoryIds?.length
  );

  const [articleValidateStatus, setArticleValidateStatus] =
    useState<ValidateStatus>('');

  const delay = _debounce(500);
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const productId = Number(id);
  const { getTreeData } = useCategoryData(form, categoryData);

  const treeSelectData: Option[] = Form.useWatch('categories', form);
  const attributesForm = Form.useWatch('attributes', form);
  const filteredCategories = filterCategoryDataByValue(
    categoryData,
    treeSelectData
  );

  const articleValue = filteredCategories.reduce((initial: string, item) => {
    if (item.key) {
      initial += `${item.key}-`;
    }

    return initial;
  }, '');

  const isAvailable = !!productCountriesData?.length;

  const onClose = () => {
    form.resetFields();
  };

  useEffect(() => {
    const attrLeft = normalisedCategoryAttributes.filter(
      categoryAttr =>
        !attributes.some(attr => attr.attributeId === categoryAttr.id)
    );

    attributes.push(...changeStructure(attrLeft));
  }, []);

  useEffect(() => {
    const attrLeft = normalisedCategoryAttributes.filter(
      categoryAttr =>
        !attributes.some(attr => attr.attributeId === categoryAttr.id)
    );

    attributes.push(...changeStructure(attrLeft));

    if (productId) {
      dispatch(getPricesByProductId(productId));
    }
  }, [productId, dispatch]);

  useEffect(() => {
    if (attributesByCategoryIds?.length) {
      setNormalisedCategoryAttributes(
        RemoveDuplicates(attributesByCategoryIds)
      );
      setCategoriesFilled(true);
    } else {
      setCategoriesFilled(false);
      setNormalisedCategoryAttributes([]);
    }
  }, [attributesByCategoryIds]);

  useEffect(() => {
    if (
      categoriesFilled &&
      (!attributesForm?.length || attributesForm === 0 || !attributesForm)
    ) {
      form.setFieldValue('attributes', [
        {
          description: '',
          value: ''
        }
      ]);
    }
  }, [attributesForm, treeSelectData, categoriesFilled]);

  useEffect(() => {
    if (loading) return;

    const number = String(form.getFieldValue('articul_number'));
    const combinedValue = articleValue + number;
    const isInItial = initialArticle + initialArticleNumber === combinedValue;
    const shouldValidate = !productId && !newProduct && !productById && number;

    const validateArticle = async () => {
      if (isInItial) {
        setProgress(false);

        return Promise.resolve();
      } else if (!isInItial && combinedValue !== 'undefined') {
        const isValid = await validateArticleNumber(combinedValue);
        setArticleValidateStatus(isValid ? 'success' : 'error');
        setProgress(false);
      }
    };

    if (shouldValidate || (productById && productId)) {
      setProgress(true);
      validateArticle();
    }
  }, [articleValue]);

  useEffect(() => {
    setArticle(articleValue);
  }, [treeSelectData, articleValue, article]);

  const onChangeAttributes = (attribute: AttributeStateItem, index: number) => {
    setAttributes(prev => {
      if (index > -1) {
        prev[index] = { ...attribute };
      }

      return [...prev];
    });
  };

  const handleArticleChange = async (value: string) => {
    if (
      initialArticleNumber &&
      article + value === articleValue + initialArticleNumber
    )
      return Promise.resolve();
    // setArticle(value);
    const isValid = await validateArticleNumber(article + value);
    setArticleValidateStatus(isValid ? 'success' : 'error');
  };

  const onCategoryChange = async (selectedCategories: Option[]) => {
    const categoryIds =
      selectedCategories.map(category => category.value) || [];

    const sortedCategories = selectedCategories
      .map(selectedCategory => {
        const matchingCategory = filterCategoryDataByValue(
          categoryData,
          selectedCategories
        ).find(category => category.id === selectedCategory.value);

        return {
          ...selectedCategory,
          index: matchingCategory ? matchingCategory.index : 0
        };
      })
      .sort((a, b) => a.index - b.index);

    delay(() => {
      form.setFieldsValue({
        categories: sortedCategories
      });
    });

    if (categoryIds.length) {
      dispatch(getAttributesByCategoryIds(categoryIds));
      setCategoriesFilled(true);
    } else {
      setCategoriesFilled(false);
      setNormalisedCategoryAttributes([]);
      setAttributes([]);
      form.setFieldValue('attributes', []);
    }
  };

  useEffect(() => {
    const productAttributes = attributes.map(item => item.attribute);

    const categoryAttributeIds = normalisedCategoryAttributes.map(
      item => item.id
    );

    const nonMatchingAttributes = productAttributes.filter(
      item => !categoryAttributeIds.includes(Number(item?.id))
    );

    const uniqueEntries = new Set<number>();
    const idsToRemove = attributes
      .map(item => ({
        id: item.attributeId,
        shouldRemove: !categoryAttributeIds.includes(item.attributeId)
      }))
      .filter(({ id, shouldRemove }) => shouldRemove && !uniqueEntries.has(id))
      .map(({ id }) => {
        uniqueEntries.add(id);

        return id;
      });

    if (idsToRemove.length && categoryAttributeIds.length) {
      const attributesToRemove = nonMatchingAttributes.map(
        item => `'${item?.description_en}'`
      );

      let fields = form.getFieldValue('attributes').filter(Boolean);

      fields = fields?.filter(
        (field: { id: number }) => !idsToRemove.includes(field?.id)
      );
      form.setFieldValue('attributes', fields);

      setAttributes(prev => {
        const filteredAttributes = prev.filter(
          item => !idsToRemove.includes(item.attributeId)
        );

        return [...filteredAttributes];
      });

      message.open({
        type: 'success',
        content: `Successfully removed ${attributesToRemove}`,
        duration: 2
      });

      return;
    }
  }, [treeSelectData]);

  const onRemoveAttribute = (index: number) => {
    setAttributes(prev => {
      prev.splice(index, 1);

      return [...prev];
    });
  };

  useEffect(() => {
    if (id && productById) {
      const result = separateTextAndNumber(productById.articul_number);
      const images = productById.files;
      const files = productContractFiles;

      const initialAttributes = normalisedCategoryAttributes?.map(item => ({
        description: item.description_en,
        value: item.values_en,
        id: item.id
      }));

      form.setFieldsValue({
        title_en: productById.title_en,
        articul_number: result.number,
        cbm:
          productById.cbm > 0
            ? trimExcessZeros(parseInputValue(String(productById.cbm)) || '')
            : '',
        // html: productById.html,
        categories: productById.categories
          ?.map(item => ({
            label: `${item.title_en} (${item.key})`,
            value: item.id,
            index: item.index,
            keys: item.key
          }))
          .sort((a, b) => a.index - b.index) as Option[],
        attributes: initialAttributes?.length && initialAttributes,
        urls: productById.urls,
        youtube_urls: productById.youtube_urls,
        // files: files.map(file => file.id),
        filesData: files.map(file => ({
          value: file.id,
          label: file.path
        })),
        images
      });
      if (productById.cbm <= 0) setCbmInputDisabled(false);
      setArticle(result.text);
      setinitialArticleNumber(result.number);
      setinitialArticle(result.text);
    } else {
      form.setFieldsValue({
        categories: [],
        attributes: []
      });
      setCategoriesFilled(false);
      setNormalisedCategoryAttributes([]);
      setAttributes([]);
    }
  }, [productById, id, productContractFiles, productAttributes]);
  const folderName = productById?.folder_name || '';
  // const newFolderName = newProduct?.folder_name || '';

  const pictureUpload = useCustomRequest({
    url: 'file/create',
    file_type: 'pictures',
    folder: `products/${folderName}`
    //  || newFolderName
  });

  const handleReset = () => {
    setAttributes([]);
    setNormalisedCategoryAttributes([]);
    setCategoriesFilled(false);
    form.setFieldsValue({
      categories: [],
      attributes: []
    });
  };

  const onImageRemove = async (file: UploadFile) => {
    setRemovedFiles(prev => [...prev, file.uid]);

    const updatedFileList = fileList.filter(item => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const onFileRemove = async (id: string) => {
    setRemovedFiles(prev => [...prev, id]);
  };

  const fileUpload = useCustomRequest({
    url: 'file/createInvoiceFile',
    productId
  });

  function changeStructure(array: any[]) {
    return array.map(obj => {
      return {
        attribute: { ...obj },
        attributeId: obj.id,
        value: { type: obj.type }
      };
    });
  }

  const onFinish = async (values: IProductAdminEditValues) => {
    const {
      title_en,
      articul_number,
      categories,
      youtube_urls,
      urls,
      cbm,
      uploadedFiles,
      filesData
    } = values;

    const contractFiles = filesData?.flatMap(item => item.value) ?? [];
    const updatedContractFiles =
      filesData?.filter(item => typeof item !== 'object') ?? [];

    // const imageFiles = images?.flatMap(item => item) ?? [];
    const uploadedContractFiles = uploadedFiles?.flatMap(item => item) ?? [];

    const newContractFiles: string[] = uploadedContractFiles
      ?.map(file =>
        file?.response?.data?.payload?.map((item: { id: string }) => item.id)
      )
      .filter(Boolean)
      .flat();

    const combinedFiles: string[] = [
      // ...(newFiles ?? ''),
      ...(updatedContractFiles ?? ''),
      // ...(existingFiles ?? ''),
      ...(contractFiles ?? ''),
      ...(newContractFiles ?? '')
    ].filter(id => id && id !== '') as string[];

    //const filtered = attributes.filter(item => Boolean(item));
    const attributesPost = attributes
      .filter(
        attribute =>
          (attribute.value?.type === 'enum' &&
            Boolean(attribute.value.enumValue)) ||
          (attribute.value?.type === 'number' &&
            Boolean(attribute.value.numberRange))
      )
      .map(attribute => ({
        attribute_id: attribute.attributeId,
        type: attribute.value?.type,
        ...(attribute.value?.type === 'enum'
          ? { enum_value: attribute.value?.enumValue }
          : {
              min: Number(attribute.value?.numberRange?.min),
              max: Number(attribute.value?.numberRange?.max)
            })
      }));

    const dataProduct = {
      title_en: title_en || '',
      description1_en: bodyDescription || '',
      html: bodyFeatures || '',
      // ...(bodyDescription && { description1_en: bodyDescription ?? '' }),
      // ...(bodyFeatures && { html: bodyFeatures ?? '' }),
      cbm: cleanAndParse(cbm),
      categories: categories?.map(item => Number(item.value)) || [],
      urls: urls || [],
      youtube_urls: youtube_urls || [],
      files: combinedFiles,
      attributes: attributesPost,
      ...(removedFiles.length && { deletedFiles: removedFiles })
    };

    if (id && productId) {
      const res = await dispatch(
        productEdit({
          productId,
          dataProduct: {
            ...dataProduct,
            articul_number: articleValue + articul_number,
            ...(!isOnlyCbm && { availability: false })
          },
          isAdmin: true
        })
      );

      if (res.meta.requestStatus === 'fulfilled' && res.payload) {
        isOnlyCbm && onClose();
        setIsOnlyCbm(!isOnlyCbm);
        setIsChecked(false);
        await dispatch(getProductById(productId));
      } else {
        handleBackendValidationError(
          res?.payload?.response?.data?.message,
          res
        );
      }
    }
  };

  const contractOptions = contractFileList.map(option => ({
    value: option.uid,
    label: getFilename(option.name)
  }));

  return (
    <Form
      validateMessages={validateMessages}
      size="large"
      form={form}
      name="formAdmin"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError={{
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      }}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <Form.Item
                label="English Title"
                name="title_en"
                rules={[{ required: true }]}
              >
                <Input placeholder="Enter title in English" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    validator: async (_, value) => {
                      if (
                        initialArticleNumber &&
                        article + value === articleValue + initialArticleNumber
                      )
                        return Promise.resolve();
                      const isValid = await validateArticleNumber(
                        articleValue + value
                      );

                      setArticleValidateStatus(isValid ? 'success' : 'error');
                    }
                  }
                ]}
                // hasFeedback
                validateStatus={articleValidateStatus}
                label="Artcle Number"
                name="articul_number"
              >
                <OtherFormat
                  // disabled={!!id}
                  placeholder="Example: 0015"
                  addonBefore={article}
                  type="text"
                  suffix={
                    <Tooltip title="Reset">
                      {progress ? (
                        <Spin />
                      ) : (
                        <UndoOutlined
                          onClick={() => {
                            setArticle(initialArticle);
                            form.setFieldsValue({
                              articul_number: initialArticleNumber
                            });
                            setArticleValidateStatus('success');
                          }}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: 'transparent'
                          }}
                        />
                      )}
                    </Tooltip>
                  }
                  onChange={e => handleArticleChange(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                // label="Category"
                label={
                  <span>
                    Category
                    <Button
                      size="small"
                      danger
                      onClick={handleReset}
                      style={{ marginLeft: 25 }}
                    >
                      Clear All Categories and Attributes
                    </Button>
                  </span>
                }
                name="categories"
                rules={[{ required: true }]}
              >
                <TreeSelect
                  loading={validationLoading}
                  onChange={onCategoryChange}
                  // treeData={flattenCategories(categoryData) as Option[]}
                  treeData={getTreeData()}
                  placeholder="Please select Category"
                  multiple
                  treeCheckable
                  treeCheckStrictly
                  filterTreeNode={(input, treeNode) =>
                    (treeNode.label?.toString().toLowerCase() ?? '').includes(
                      input.toLowerCase()
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col span={9}>
              <Form.Item
                label="Youtube Embed Video Urls"
                name="youtube_urls"
                // rules={[{ required: false }]}
                rules={[
                  { required: false },
                  {
                    validator: (_, value) => validateYoutubeUrls(value)
                  }
                ]}
              >
                <Select
                  tokenSeparators={[',']}
                  tagRender={TagRender}
                  mode="tags"
                  open={false}
                  placeholder="Enter Youtube Video Url"
                  showSearch={false}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                label="Url"
                name="urls"
                rules={[
                  { required: false },
                  {
                    validator: (_, value) => isValidUrl(value)
                  }
                ]}
              >
                <Select
                  tokenSeparators={[',']}
                  tagRender={TagRender}
                  mode="tags"
                  open={false}
                  placeholder="Enter file Url"
                  showSearch={false}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                rules={[
                  { required: true },
                  isNotZeroRule,
                  decimalPrecisionRule
                ]}
                label="Cb㎥"
                name="cbm"
              >
                <NumericInput
                  name="cbm"
                  disabled={cbmInputDisabled}
                  placeholder="Size ㎥"
                  suffix={
                    <>
                      {isAvailable ? (
                        <Tooltip
                          title="The product is published with logistic records tied to this field.
                         Changing this field requires recalculating the logistic information.
                       Please navigate to the Logistics section to make any updates."
                        >
                          <InfoCircleOutlined
                            style={{ cursor: 'pointer', color: '#ff4218' }}
                          />
                        </Tooltip>
                      ) : (
                        <>
                          <Button
                            style={{ marginRight: 1 }}
                            size="small"
                            type="primary"
                            onClick={() => {
                              if (cbmInputDisabled) {
                                setIsOnlyCbm(false);
                                setCbmInputDisabled(!cbmInputDisabled);
                              } else {
                                form.submit();
                                setCbmInputDisabled(!cbmInputDisabled);
                              }
                            }}
                          >
                            {cbmInputDisabled ? (
                              <EditOutlined />
                            ) : (
                              <CheckOutlined />
                            )}
                          </Button>
                          {!cbmInputDisabled && (
                            <Button
                              danger
                              size="small"
                              onClick={() => {
                                productById &&
                                  form.setFieldsValue({
                                    cbm: trimExcessZeros(
                                      parseInputValue(
                                        String(productById.cbm)
                                      ) || ''
                                    )
                                  });
                                setCbmInputDisabled(!cbmInputDisabled);
                              }}
                            >
                              <CloseOutlined />
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  }
                />
              </Form.Item>
            </Col>
            <Form.List name="attributes">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index, arr) => {
                    const attrLeft = normalisedCategoryAttributes.filter(
                      categoryAttr =>
                        !attributes.some(
                          attr => attr.attributeId === categoryAttr.id
                        )
                    );

                    return (
                      <Col span={8} key={key}>
                        <AttributeSelect
                          onChange={attribute => {
                            onChangeAttributes(attribute, index);
                          }}
                          name={name}
                          selected={attributes[index]}
                          attributes={attributes}
                          form={form}
                          {...restField}
                        />
                        <Row style={{ marginTop: '8px' }} gutter={[12, 0]}>
                          <Col span={12}>
                            {arr.length && (
                              <Button
                                size="small"
                                danger
                                block
                                onClick={() => {
                                  // removeFormItem(index);
                                  remove(name);
                                  onRemoveAttribute(index);
                                }}
                              >
                                <MinusCircleOutlined />
                              </Button>
                            )}
                          </Col>
                          <Col span={12}>
                            {index === arr.length - 1 &&
                              hasValue(attributes[index]) &&
                              !!attrLeft.length && (
                                <Button
                                  size="small"
                                  block
                                  onClick={() => {
                                    add();
                                  }}
                                >
                                  <PlusOutlined />
                                </Button>
                              )}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </>
              )}
            </Form.List>
          </Row>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Product Datasheet"
            name={'filesData'}
            rules={[{ required: false }]}
          >
            <Select
              mode="multiple"
              loading={loadingAction}
              placeholder="Select Contract"
              // defaultValue={id && contractFileList}
              optionLabelProp="label"
              optionFilterProp="label"
              tokenSeparators={[',']}
              showSearch
              // tagRender={TagRender}
              tagRender={props => (
                <TagRender {...props} customOnClose={onFileRemove} />
              )}
              onSearch={value => {
                delay(() => {
                  dispatch(setSearch(value));
                  dispatch(fetchInvoiceFilesApi());
                });
              }}
              onSelect={() => dispatch(setSearch(''))}
              options={contractOptions}
            ></Select>
          </Form.Item>
          <Form.Item
            // label="Product Datasheet"
            name={'uploadedFiles'}
            rules={[{ required: false }]}
            getValueFromEvent={event => event?.fileList || []}
          >
            <Dragger
              style={{ marginTop: 10 }}
              customRequest={fileUpload}
              accept=".pdf"
              onChange={onChangeFileUpload}
            >
              <p className="ant-upload-text">
                Click or drag (Contacts, Contracts, Invoice) to this area to
                upload
              </p>
            </Dragger>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="images"
            label="Product Images"
            getValueFromEvent={event => event?.fileList || []}
          >
            <Uploader
              accept="image/*"
              onChange={onChangeImageUpload}
              fileList={fileList}
              customRequest={pictureUpload}
              onRemove={onImageRemove}
            />
            {/* <Dragger
              style={{ marginBottom: 20 }}
              onChange={onChangeImageUpload}
              fileList={fileList}
              accept="image/*"
              customRequest={pictureUpload}
              listType="picture-card"
              multiple
              onRemove={onImageRemove}
            >
              <p className="ant-upload-text">
                Click or drag Photos to this area to upload
              </p>
            </Dragger> */}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Description" name="description1_en">
            <Editor setContent={setBodyDescription} content={bodyDescription} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Features" name="html">
            <Editor
              height={700}
              setContent={setBodyFeatures}
              content={bodyFeatures}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <FloatButton.BackTop /> */}
    </Form>
  );
};

export default Admin;
